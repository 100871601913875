import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from './app';
import { areaReducer } from './area/areaSlice';
import { documentReducer } from './document';
import { issueReducer } from './issue';
import { linkedDocumentReducer } from './linkedDocument';
import { loadingReducer } from './loading';
import { modalReducer } from './modal';
import { persistAppReducer } from './persistApp';
import { projectReducer } from './project';
import { shiftReducer } from './shift';
import { structureBaseSetReducer } from './structureBaseSet';
import { timekeepingReducer } from './timekeeping';
import { userReducer } from './user';
import { workPackageReducer } from './workPackage';
import { workpackageProgressReducer } from './workpackageProgress';

const mainReducer = combineReducers({
  app: appReducer,
  loading: loadingReducer,
  modal: modalReducer,
  project: projectReducer,
  user: userReducer,
  timekeeping: timekeepingReducer,
  shift: shiftReducer,
  persistApp: persistAppReducer,
  issue: issueReducer,
  area: areaReducer,
  workPackage: workPackageReducer,
  document: documentReducer,
  linkedDocument: linkedDocumentReducer,
  workpackageProgress: workpackageProgressReducer,
  structureBaseSet: structureBaseSetReducer,
});

const rootReducers = (state: any, action: any) => {
  // reset store if logout
  if (action.type === 'app/logout') {
    state = {
      persistApp: state.persistApp,
      app: {
        language: state.app.language,
      },
    };
  }

  return mainReducer(state, action);
};

export default rootReducers;
