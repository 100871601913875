import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthRouteObject } from './AuthRoute';
import { ComingSoon } from '@/components/ComingSoon';
import {
  AreaList,
  IssueCivil,
  Bidding,
  Documents,
  LinkedDocuments,
  IssueEquipment,
  IssueEandI,
  IssuePiping,
  ShiftTemplates,
  IssueStructure,
  PipingWorkload,
  // IssueTestPackageD,
  IssueTestPackageS,
  WorkPackages,
  StructureBaseSet,
} from '@/pages';
import PlanTotalVolume from '@/pages/PlanTotalVolume';
import ProjectSettingPage from '@/pages/ProjectSettingPage';
import { TimelineSection as Timekeeping } from '@/pages/Timekeeping';
import WorkpackageProgressPage from '@/pages/WorkpackageProgress';

const MainLayout = lazy(() => import('@/components/Layout/MainLayout'));
const ProjectLayout = lazy(() => import('@/components/Layout/ProjectLayout'));
const Login = lazy(() => import('@/pages/LoginPage'));
const NotFound = lazy(() => import('@/pages/404'));
const ProjectsPage = lazy(() => import('@/pages/ProjectsPage'));
const CreateProjectPage = lazy(() => import('@/pages/CreateProjectPage'));
const TeamManagePage = lazy(() => import('@/pages/TeamManagePage'));

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const projectRouters: MetaMenuAuthRouteObject[] = [
  {
    element: <ProjectsPage />,
    name: 'Project List',
    index: true,
  },
  {
    element: <ProjectLayout />,
    children: [
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/dashboard',
      // },
      {
        element: <Bidding />,
        path: '/projects/tasks',
      },
      {
        element: <IssuePiping />,
        path: '/projects/tasks/piping',
      },
      {
        element: <IssueEquipment />,
        path: '/projects/tasks/equipment',
      },
      {
        element: <IssueEandI />,
        path: '/projects/tasks/e-and-i',
      },
      {
        element: <IssueStructure />,
        path: '/projects/tasks/structure',
      },
      {
        element: <IssueCivil />,
        path: '/projects/tasks/civil',
      },
      {
        element: <PipingWorkload />,
        path: '/projects/tasks/remain-piping-workload',
      },
      // {
      //   element: <IssueTestPackageS />,
      //   path: '/projects/tasks/test-package-s'
      // },
      // {
      //   element: <IssueTestPackageD />,
      //   path: '/projects/tasks/test-package-d'
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/information/general'
      // },
      {
        element: <ComingSoon />,
        path: '/projects/information/members',
      },
      {
        element: <IssueTestPackageS />,
        path: '/projects/tasks/test-package-s',
      },
      // {
      //   element: <IssueTestPackageD />,
      //   path: '/projects/tasks/test-package-d',
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/information/general'
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/information/members'
      // },
      {
        element: <PlanTotalVolume />,
        path: '/projects/based-data/volume-total-plan',
      },
      {
        element: <AreaList />,
        path: '/projects/based-data/area-list',
      },
      {
        element: <WorkPackages />,
        path: '/projects/based-data/work-packages',
      },
      {
        element: <Documents />,
        path: '/projects/based-data/documents',
      },
      {
        element: <LinkedDocuments />,
        path: '/projects/based-data/linked-documents',
      },
      {
        element: <StructureBaseSet />,
        path: '/projects/based-data/structure-base-set',
      },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/based-data/equipment-list',
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/based-data/machines',
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/based-data/materials',
      // },
      // {
      //   element: <ComingSoon />,
      //   path: '/projects/based-data/contractors',
      // },

      {
        element: <Bidding />,
        path: '/projects/bidding',
      },
      {
        element: <ComingSoon />,
        path: '/projects/contract-bid-kpis',
      },
      {
        element: <ComingSoon />,
        path: '/projects/prepare/cost-estimate',
      },
      {
        element: <ComingSoon />,
        path: '/projects/prepare/construction',
      },
      // {
      //   element: <PublicPage />,
      //   path: '/projects/construction/init',
      // },
      {
        element: <ComingSoon />,
        path: '/projects/construction/weekly-assignment',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/labor-safety-diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/enviromental-hygiene-diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/materials-log',
      },
      {
        element: <ComingSoon />,
        path: '/projects/machinery-log',
      },
      {
        element: <ComingSoon />,
        name: 'Main material',
        path: '/projects/main-material',
      },
      {
        element: <ComingSoon />,
        name: 'Auxiliary material',
        path: '/projects/auxiliary-material',
      },
      {
        element: <ComingSoon />,
        name: 'Machinery',
        path: '/projects/machinery',
      },
      {
        element: <ComingSoon />,
        name: 'Transfer-material',
        path: '/projects/transfer-material',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/depot',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/subcontract',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-12',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-17',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/aggregate-costs',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/overtime',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/transfer',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/statistic-bonus',
      },
      {
        element: <TeamManagePage />,
        name: 'Team manage',
        path: '/projects/employees/team-manage',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/fee',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/expenses',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/bonus',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/recurring-travel-expenses',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/make-an-advance-plan-12th',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/20th-payment-plan',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/make-an-advance-plan-27th',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/5th-payment-plan',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/aggregate-materials',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/track-cash-flow',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/bookkeeping',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/input-invoices',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/output-invoices',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/invoices-difference',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/invoice-x',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/input-invoices-and-x',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/compare-norms',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/supplier-investor-debt',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/profit-and-loss-statement',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/develoment-chart',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/Other-cost-charts',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/profit-chart',
      },
      {
        element: <Outlet />,
        name: 'Project management',
        path: '/projects/project-management',
        children: [
          {
            element: <ComingSoon />,
            path: '/projects/project-management/documents',
          },
          {
            element: <ComingSoon />,
            path: '/projects/project-management/contruction-expenses',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'KPI - Salary',
        path: '/projects/kpi-salary',
        children: [
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/kpis-of-departments',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/salary-of-departments',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/1st-salary-advance',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/1st-salary-payment',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/2nd-salary-advance',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/2nd-salary-payment',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/year-end-bonus',
          },
        ],
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/project-management-suppliers',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/supervision-consultants-suppliers',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/investors-project-management',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/investors-supervision-consultant',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/Other',
      },
      {
        element: <ProjectSettingPage />,
        path: '/projects/settings',
      },
      {
        element: <Outlet />,
        name: 'Employees',
        path: '/projects/employees',
        children: [
          {
            element: <Timekeeping />,
            name: 'Check-in time',
            path: '/projects/employees/check-in-time',
          },
        ],
      },
    ],
  },
];

export const routers: MetaMenuAuthRouteObject[] = [
  {
    element: <MainLayout />,
    path: '/',
    name: 'Main',
    children: [
      {
        element: <ProjectsPage />,
        name: 'Home',
        index: true,
      },
      {
        element: <CreateProjectPage />,
        name: 'Create Project',
        path: '/create-project',
      },
      {
        element: <>Choose Organization</>,
        name: 'Organization',
        path: '/organization',
      },
      {
        element: <Outlet />,
        name: 'Projects',
        path: '/projects',
        children: projectRouters,
      },
      {
        element: <Outlet />,
        name: 'Configuration',
        path: '/configuration',
        children: [
          {
            element: <ShiftTemplates />,
            name: 'Shift Templates',
            path: '/configuration/shift-templates',
          },
        ],
      },
    ],
  },
  {
    element: <WorkpackageProgressPage />,
    name: 'Work Package Progress',
    path: '/workpackage-progress',
  },
  {
    element: <Login />,
    name: 'Login',
    path: '/login',
  },
  { path: '*', element: <NotFound /> },
];
