import { createSelector } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { RootState } from '@/store/types';

export const getLinkedDocumentState = (state: RootState) => state.linkedDocument;

export function getSelectedLinkedDocuments() {
  return createSelector([getLinkedDocumentState], state => state.selectedLinkedDocuments);
}

export function getLinkedDocuments() {
  return createSelector([getLinkedDocumentState], state => state.linkedDocuments);
}
export function getFolders() {
  return createSelector([getLinkedDocumentState], state => state.folders);
}
export function getLinkedeDocumentQueryParams() {
  return createSelector([getLinkedDocumentState], state => state.linkedDocumentQueryParams || defaultPagingParams);
}

export function getSSNameDocuments() {
  return createSelector([getLinkedDocumentState], state => state.ssNameDocuments);
}
export function getSelectedSSNameDocuments() {
  return createSelector([getLinkedDocumentState], state => state.selectedSSNameDocument);
}
export function getSSNameDocumentQueryParams() {
  return createSelector([getLinkedDocumentState], state => state.queryParams || defaultPagingParams);
}
export function getSelectedSSNameDocumentRows() {
  return createSelector([getLinkedDocumentState], state => state.selectedSSNameDocumentRows);
}
