import React, { useEffect, useState } from 'react';

import { ImportOutlined, ExportOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Pagination,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  Upload,
  message,
} from 'antd';
import type { TableColumnsType, CheckboxProps, UploadFile, UploadProps, PaginationProps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BidHeader } from './BidHeader';
import { CreateUpdateIssue } from './CreateUpdateIssue';
import { formatDateDisplay } from '@/common/define';
import { MoreOptions } from '@/components';
import { useWindowSize } from '@/hooks';
import { IssueResponse } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueQueryParams, getIssues, getIssuesView, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const Bidding = () => {
  const { t } = useTranslation('bidding');
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const view = useAppSelector(getIssuesView());
  const issues = useAppSelector(getIssues());
  const issueModal = useAppSelector(getModalVisible('CreateUpdateIssueModal'));
  const windowSize = useWindowSize();
  const [openColumnsDisplayed, setOpenColumnsDisplayed] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isImporting = useAppSelector(getLoading('ImportingIssue'));
  const isFetching = useAppSelector(getLoading('GetIssues'));
  const [tasks, setTasks] = useState<IssueResponse[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const queryParams = useAppSelector(getIssueQueryParams());
  const [searchStr, setSearchStr] = useState(queryParams?.search);

  useEffect(() => {
    setSearchStr(queryParams?.search);
  }, [queryParams]);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    dispatch(issueActions.getIssuesRequest({ projectId: selectedProject.id, queryParams }));
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    const issueList = issues?.results || [];
    setTasks(issueList);
  }, [issues]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // eslint-disable-next-line
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const moreActions = [
    {
      key: 'edit',
      label: t('Edit'),
    },
    {
      key: 'remove',
      label: t('Remove'),
    },
  ];

  const handleMoreActionClick = ({ key }: any, issue: any) => {
    switch (key) {
      case 'edit':
        dispatch(issueActions.setSelectedIssue(issue));
        dispatch(showModal({ key: 'CreateUpdateIssueModal' }));
        break;
      default:
        message.info(`Click on item ${key}`);
        break;
    }
  };

  const issuesColumns: TableColumnsType<any> = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 70,
    },
    {
      title: t('Name'),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: t('Plan start date'),
      dataIndex: 'plannedStartDate',
      key: 'plannedStartDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    {
      title: t('Plan end date'),
      dataIndex: 'plannedEndDate',
      key: 'plannedEndDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    {
      title: t('Actual start date'),
      dataIndex: 'actualStartDate',
      key: 'actualStartDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    {
      title: t('Actual end date'),
      dataIndex: 'actualEndDate',
      key: 'actualEndDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    // {
    //   title: t('Area'),
    //   dataIndex: 'areaId',
    //   key: 'areaId',
    //   width: 100,
    //   align: 'center',
    //   render: (value: string, record: IssueResponse) => record.area?.name,
    // },
    // {
    //   title: t('Work package'),
    //   dataIndex: 'workPackageId',
    //   key: 'workPackageId',
    //   width: 150,
    //   align: 'center',
    //   render: (value: string, record: IssueResponse) => record.workPackage?.name,
    // },
    // {
    //   title: t('Discipline'),
    //   dataIndex: 'pipeLineId',
    //   key: 'pipeLineId',
    //   width: 130,
    //   align: 'center',
    //   render: (value: string, record: IssueResponse) => record.workPackage?.disciplineName,
    // },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
      width: 150,
      align: 'center',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 60,
      render: (_: any, record: any) => {
        return <MoreOptions menuOptions={moreActions} onMenuClick={evt => handleMoreActionClick(evt, record)} />;
      },
    },
  ];

  const defaultCheckedList = issuesColumns.map((item: any) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [newColumns, setNewColumns] = useState<any[]>(issuesColumns);
  const checkAll = issuesColumns.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < issuesColumns.length;

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    setCheckedList(e.target.checked ? defaultCheckedList : []);
  };

  const options = issuesColumns.map(({ key, title }: any) => ({
    label: title || t('Action'),
    value: key,
  }));

  const changeColumnsDisplayed = (value: any) => {
    setCheckedList(value as string[]);
  };

  const handleOpenColumnsChange = (newOpen: boolean) => {
    const columnsDisplayed = newColumns.filter(x => !x.hidden).map((item: any) => item.key as string);
    setCheckedList(columnsDisplayed);
    setOpenColumnsDisplayed(newOpen);
  };

  const applyColumnsDisplayed = () => {
    const columnsDisplayed = issuesColumns.map((item: any) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
    setNewColumns(columnsDisplayed);
    setOpenColumnsDisplayed(false);
  };

  const onPagingChange = (page: number, pageSize: number) => {
    dispatch(
      issueActions.getIssuesRequest({
        projectId: selectedProject?.id,
        queryParams: { ...queryParams, page, pageSize },
      }),
    );
  };

  const checkboxRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            All
          </Checkbox>
        </Row>
        <Checkbox.Group value={checkedList} onChange={changeColumnsDisplayed}>
          <Row>
            {options.map(item => (
              <Col span={24} key={item.value}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => handleOpenColumnsChange(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Button type="primary" onClick={applyColumnsDisplayed}>
            Apply
          </Button>
        </Row>
      </div>
    );
  };

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      dispatch(issueActions.importFileRequest({ projectId: selectedProject?.id, file }));
      return false;
    },
    fileList,
  };

  const [timer, setTimer] = useState<any>(null);
  const onSearchChange = (evt: any) => {
    clearTimeout(timer);
    const search = evt.target.value;
    setSearchStr(search);
    const timeoutId = setTimeout(() => {
      dispatch(
        issueActions.getIssuesRequest({
          projectId: selectedProject?.id,
          queryParams: { ...queryParams, page: 1, search },
        }),
      );
    }, 500);
    setTimer(timeoutId);
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) => `${range[0]}-${range[1]} of ${total} items`;

  return (
    <Spin spinning={isFetching}>
      {issueModal && <CreateUpdateIssue />}
      <BidHeader />
      <Row style={{ padding: 10 }}>
        <Space style={{ flex: 1 }}>
          <Input
            value={searchStr}
            placeholder={t('Search')}
            style={{ width: 300 }}
            onChange={onSearchChange}
            allowClear
            suffix={searchStr ? null : <SearchOutlined />}
          />
        </Space>
        <Space>
          <Popover
            content={checkboxRender()}
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            open={openColumnsDisplayed}
            onOpenChange={handleOpenColumnsChange}
          >
            <Button>
              {checkedList.length} columns selected <DownOutlined />
            </Button>
          </Popover>
          <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
            <Button loading={isImporting} icon={<ImportOutlined />}>
              {t('Import')}
            </Button>
          </Upload>
          <Button icon={<ExportOutlined />}>{t('Export')}</Button>
        </Space>
      </Row>
      <div>
        {view === 'List' && (
          <div style={{ margin: '0 10px' }}>
            <Table
              rowKey={record => record.id}
              virtual
              dataSource={tasks}
              rowSelection={rowSelection}
              expandable={{
                defaultExpandAllRows: true,
              }}
              columns={newColumns.filter(x => !x.hidden)}
              size="small"
              scroll={{ x: 1330, y: windowSize[1] - 260 }}
              pagination={false}
              sticky={{ offsetHeader: 39 }}
            />
            <div style={{ marginTop: 10, textAlign: 'right' }}>
              <Pagination
                total={issues?.queryCount || 0}
                current={queryParams.page}
                pageSize={queryParams.pageSize}
                onChange={onPagingChange}
                showSizeChanger
                responsive
                showTotal={showTotal}
              />
            </div>
          </div>
        )}
        {view === 'Gantt' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Typography.Title level={2}>{t('The feature is developing')}</Typography.Title>
          </div>
        )}
      </div>
    </Spin>
  );
};
