import { createSlice } from "@reduxjs/toolkit";

interface ShiftState {
  shifts: any[];
  selectedShift?: any;
}

const initialState: ShiftState = {
  shifts: []
};

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    setShifts: (state, action) => {
      state.shifts = action.payload;
    },
    setSelectedShift: (state, action) => {
      state.selectedShift = action.payload;
    }
  }
});

export const shiftActions = shiftSlice.actions;
export const shiftReducer = shiftSlice.reducer;