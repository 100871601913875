import { useEffect, useState } from 'react';

import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import { Row, Space, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { IssueService } from '@/services/IssueService';
import { getDocumentQueryParams, documentActions, getFolderRootId, getDocumentPath } from '@/store/document';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

export const DocumentsToolbar = () => {
  const { t } = useTranslation('BasedData');
  const [searchStr, setSearchStr] = useState();
  const [timer, setTimer] = useState<any>(null);
  const dispatch = useAppDispatch();
  const params = useAppSelector(getDocumentQueryParams());
  const selectedProject = useAppSelector(getSelectedProject());

  const folderRootId = useAppSelector(getFolderRootId());
  const path = useAppSelector(getDocumentPath());

  useEffect(() => {
    setSearchStr(params?.search);
  }, [params]);

  const onSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const newParams = { ...params, page: 1, search };
      if (!path?.length && folderRootId) {
        dispatch(dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: newParams })));
      } else {
        const lastPath = path[(path?.length || 1) - 1];
        if (lastPath) {
          dispatch(documentActions.getLabelRequest({ documentId: lastPath.id, params: newParams }));
        }
      }
    }, 500);
    setTimer(timeoutId);
  };
  const onExportFileHandle = () => {
    if (selectedProject) {
      IssueService.Get.exportSSLinkedDocuments(selectedProject?.id, {
        search: { },
      }).subscribe((issueFile: any) => {
        if (issueFile != null) {
          const url = window.URL.createObjectURL(new Blob([issueFile]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'linkedDocument.xlsx');
          document.body.appendChild(link);
          link.click();
        }
      });
    }
  };
  return (
    <Row style={{ padding: 10 }} gutter={[10, 10]}>
      <Space style={{ flex: 1 }}>
        <Input
          value={searchStr}
          onChange={onSearchChange}
          allowClear
          placeholder={t('Search')}
          suffix={searchStr ? null : <SearchOutlined />}
          style={{ width: 300 }}
        />
      </Space>
      <Space>
        <Button onClick={onExportFileHandle}>
          <ExportOutlined />
          {t('Export')}
        </Button>
      </Space>
    </Row>
  );
};
