import React, { useEffect } from 'react';

import { CreateUpdatePlanTotalVolume } from './CreateUpdatePlanTotalVolume';
import { PlanTotalVolumeToolbar } from './PlanTotalVolumeToolbar';
import { largePagingParams } from '@/common/define';
import { getAuthenticated } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getSelectedProject, projectActions } from '@/store/project';

export const PlanTotalVolume = () => {
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const tracker = useAppSelector(getTracker());
  const auth = useAppSelector(getAuthenticated());

  useEffect(() => {
    // eslint-disable-next-line
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  }, []);
  useEffect(() => {
    if (selectedProject && tracker?.results?.length) {
      const trackers = tracker?.results;
      dispatch(
        projectActions.getProjectTargetsRequest({
          projectId: selectedProject.id,
          body: ['PipingTask', 'EquipmentTask', 'E&ITask', 'StructureTask', 'CivilTask'],
        }),
      );
    }
  }, [selectedProject, tracker]);

  return (
    <div>
      <PlanTotalVolumeToolbar />
      <CreateUpdatePlanTotalVolume />
    </div>
  );
};
