import { useEffect } from 'react';

import { Modal, Form, Row, Col, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateWorkPackageModalName,
  DisciplineOption,
  SavingWorkPackage,
  largePagingParams,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { workPackageActions, getSelectedWorkPackage, getAreas } from '@/store/workPackage';


export const CreateUpdateWorkPackageModal = () => {
  const { t } = useTranslation('workPackage');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateWorkPackageModalName));
  const areas = useAppSelector(getAreas());
  const selectedWorkPackage = useAppSelector(getSelectedWorkPackage());
  const selectedProject = useAppSelector(getSelectedProject());
  const isSaving = useAppSelector(getLoading(SavingWorkPackage));
  const [form] = Form.useForm();

  const areaOptions = (areas?.results || []).map(area => ({
    label: area.name,
    value: area.id,
  }));

  const disciplineOptions = [
    {
      label: t('Piping'),
      value: DisciplineOption.Piping,
    },
    {
      label: t('Equipment'),
      value: DisciplineOption.Equipment,
    },
    {
      label: t('E&I'),
      value: DisciplineOption.E_AND_I,
    },
    {
      label: t('Structure'),
      value: DisciplineOption.Structure,
    },
    {
      label: t('Civil'),
      value: DisciplineOption.Civil,
    },
  ];

  useEffect(() => {
    dispatch(
      workPackageActions.getAreasRequest({
        params: {
          ...largePagingParams,
        },
        projectId: selectedProject?.id,
      }),
    );
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    dispatch(workPackageActions.setSelectedWorkPackage(undefined));
    dispatch(hideModal({ key: CreateUpdateWorkPackageModalName }));
  };

  const handleOk = () => form.submit();

  const handleSaveWorkPackage = (values: any) => {
    if (selectedProject) {
      const inputData = {
        projectId: selectedProject.id,
        ...values,
      };
      if (selectedWorkPackage) {
        dispatch(
          workPackageActions.updateWorkPackageRequest({
            workPackageId: selectedWorkPackage.id,
            workPackage: { ...selectedWorkPackage, ...inputData },
          }),
        );
        return;
      }
      dispatch(workPackageActions.createWorkPackageRequest({ workPackage: inputData }));
    }
  };

  return (
    <DraggableModal
      title={selectedWorkPackage ? t('updateWorkPackage') : t('addNewWorkPackage')}
      open={isModalOpen}
      okText={t('okText')}
      onOk={handleOk}
      cancelText={t('cancelText')}
      onCancel={handleCancel}
      confirmLoading={isSaving}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedWorkPackage,
        }}
        onFinish={handleSaveWorkPackage}
      >
        <Row>
          <Col span={24} md={24}>
            <Form.Item label={t('name')} name="name" rules={[{ required: true, message: t('requireName') }]}>
              <Input />
            </Form.Item>
            <Form.Item label={t('code')} name="code" rules={[{ required: true, message: t('requireCode') }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t('discipline')}
              name="discipline"
              rules={[{ required: true, message: t('requireDiscipline') }]}
            >
              <Select options={disciplineOptions} />
            </Form.Item>
            <Form.Item label={t('area')} name="areaId" rules={[{ required: true, message: t('requireArea') }]}>
              <Select options={areaOptions} />
            </Form.Item>
            <Form.Item label={t('description')} name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DraggableModal>
  );
};
