import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import {
  CreateUpdateStructureBaseSetPayload,
  StructureBaseSetPagingResponse,
  StructureBaseSetResponse,
} from '@/services/StructureBaseSetService';

interface StructureBaseSetState {
  structureBaseSets?: StructureBaseSetPagingResponse;
  selectedStructureBaseSet?: StructureBaseSetResponse;
  queryParams: any;
  selectedRowKeys?: any[];
  columns?: string[];
  selectedColumns?: string[];
  columnAggregateValue?: any;
}

const initialState: StructureBaseSetState = {
  queryParams: defaultPagingParams,
};

const structureBaseSetSlice = createSlice({
  name: ' structureBaseSet',
  initialState,
  reducers: {
    setStructureBaseSets: (state, action) => {
      state.structureBaseSets = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setSelectedStructureBaseSet: (state, action) => {
      state.selectedStructureBaseSet = action.payload;
    },
    getStructureBaseSetsRequest: (state, action) => {},
    createStructureBaseSetRequest: (state, action) => {},
    updateStructureBaseSetRequest: (state, action) => {},
    importStructureBaseFileRequest: (state, action) => {},
    importEditStructureBaseFileRequest: (state, action) => {},
    exportStructureBaseFileRequest: (state, action) => {},
    deleteStructureBaseSetRequest: (state, action) => {},
    deleteMultipleStructureBaseSetRequest: (state, action) => {},
    resetStructureBaseSetRequest: (state, action: PayloadAction<{ projectId: number }>) => {},
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    getStructureBaseSetColumnRequest: (state, action: PayloadAction<{ projectId: number }>) => {},
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload;
    },
    getStructureBaseSetColumnsAggregateValueRequest: (
      state,
      action: PayloadAction<{ columns: string[]; projectId: number }>,
    ) => {},
    setColumnsAggregateValue: (state, action) => {
      state.columnAggregateValue = action.payload;
    },
    updateStructuresRequest: (
      state,
      action: PayloadAction<{ columns: string[]; input: CreateUpdateStructureBaseSetPayload; projectId: number }>,
    ) => {},
  },
});

export const structureBaseSetActions = structureBaseSetSlice.actions;
export const structureBaseSetReducer = structureBaseSetSlice.reducer;
