import { useEffect, useState } from 'react';

import { MinusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Radio, Row, Space, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import {
  AddDocumentModalName,
  CreateUpdateLinkedDocumentModalName,
  SavingLinkedDocument,
  defaultPagingParams,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { InfiniteScrollTable, InfiniteScrollTableProps } from '@/components/Table/InfiniteScrollTable';
import { DocumentService } from '@/services/DocumentService';
import { IssueService, LinkedDocument, LinkedDocumentPagingResponse } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  linkedDocumentActions,
  getSelectedSSNameDocuments,
  getSelectedLinkedDocuments,
  getSSNameDocumentQueryParams,
  getFolders,
} from '@/store/linkedDocument';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal, showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';
const { Search } = Input;
// var timer: any = null;
// var searchStr: string = '';
// var filterValue = '';
export const CreateUpdateLinkedDocumentModal = () => {
  const { t } = useTranslation('linkedDocument');
  const dispatch = useAppDispatch();
  const [availableDocuments, setAvailableDocuments] = useState<LinkedDocumentPagingResponse>();
  const [availableDocumentsParams, setAvailableDocumentsParams] = useState<any>();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [filter, seTFilter] = useState<{ text: string; value: string }[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');
  const [timer, setTimer] = useState<any>();
  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedLinkedDocumentData, setselectedLinkedDocumentData] = useState<LinkedDocument[]>([]);

  const params = useAppSelector(getSSNameDocumentQueryParams());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateLinkedDocumentModalName));
  const isAddDocumentModalOpen = useAppSelector(getModalVisible(AddDocumentModalName));
  const selectedSSNameDocument = useAppSelector(getSelectedSSNameDocuments());
  const selectedLinkedDocuments = useAppSelector(getSelectedLinkedDocuments());
  const selectedProject = useAppSelector(getSelectedProject());
  const isSaving = useAppSelector(getLoading(SavingLinkedDocument));
  const folders = useAppSelector(getFolders());

  useEffect(() => {
    setselectedLinkedDocumentData(selectedLinkedDocuments || []);
  }, [selectedLinkedDocuments]);
  // eslint-disable-next-line
  const avaialbleDocumentRowSelection: TableRowSelection<LinkedDocument> = {
    selectedRowKeys: (selectedLinkedDocumentData || []).map(lDocument => lDocument.id),
    // onChange: onSelectChange,
    onSelectAll(selected, selectedRows, changeRows) {
      if (selected) {
        const notExistRowsYet = changeRows.filter(
          row => !selectedLinkedDocumentData.find(document => document.id === row.id),
        );
        setselectedLinkedDocumentData(prev => notExistRowsYet.concat(prev));
      } else {
        setselectedLinkedDocumentData(prev =>
          prev.filter(document => !changeRows.find(changedRow => changedRow.id === document.id)),
        );
      }
    },
    onSelect: (record, selected) => {
      const isRecordExist = !!(selectedLinkedDocumentData || []).find(document => document.id === record.id);
      if (selected) {
        if (!isRecordExist) {
          setselectedLinkedDocumentData(prev => [record].concat(prev));
        }
      } else {
        if (isRecordExist) {
          setselectedLinkedDocumentData(prev => prev.filter(document => document.id !== record.id));
        }
      }
    },
    columnWidth: 45,
  };

  useEffect(() => {
    dispatch(linkedDocumentActions.getFoldersRequest({ projectId: selectedProject?.id || -1 }));
  }, []);

  useEffect(() => {
    if (selectedProject && isAddDocumentModalOpen) getNewDocuments(1);
    // eslint-disable-next-line
    else {
      setAvailableDocuments(undefined);
    }
  }, [selectedProject, isAddDocumentModalOpen]);

  useEffect(() => {
    seTFilter(
      (folders ?? []).map(x =>
        x === ''
          ? { text: 'Root', value: 'root ' }
          : { text: x.split('/').pop() ?? '', value: x.split('/').pop() ?? '' },
      ),
    );
  }, [folders]);

  const handleRemoveDocument = (document: LinkedDocument) => {
    dispatch(
      linkedDocumentActions.setSelectedLinkedDocuments(
        (selectedLinkedDocuments || []).filter(lDocument => lDocument.id !== document.id),
      ),
    );
  };

  const handleCancel = () => {
    dispatch(linkedDocumentActions.setSelectedSSNameDocuments(undefined));
    dispatch(hideModal({ key: CreateUpdateLinkedDocumentModalName }));
    dispatch(hideModal({ key: AddDocumentModalName }));
  };

  const handleOk = () => handleSaveLinkedDocument();

  const handleSaveLinkedDocument = () => {
    if (selectedProject) {
      if (selectedSSNameDocument) {
        dispatch(
          linkedDocumentActions.addDocumentForSSName({
            projectId: selectedProject.id,
            input: selectedLinkedDocuments || [],
            ssNameDocument: selectedSSNameDocument,
            areaId: params?.areaId || -1,
          }),
        );
      }
    }
  };

  const selectedDocumentColumns: ColumnType<LinkedDocument>[] = [
    {
      title: t('Link'),
      key: 'action',
      align: 'center',
      width: 40,
      render(value, record, index) {
        return (
          <Button
            size="small"
            type="primary"
            icon={<MinusOutlined style={{ fontSize: 12 }} />}
            onClick={() => handleRemoveDocument(record)}
          />
        );
      },
    },
    {
      title: t('Document Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: t('Folder'),
      key: 'location',
      dataIndex: 'location',
      render: (value, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record.location ? record.location.split('/').pop() : 'Root'}
          </div>
        );
      },
      width: 80,
    },
  ];

  const handleSearch = (selectedKeys: any) => {
    seTFilter(
      (folders ?? [])
        .map(x => ({ text: x.split('/').pop() ?? '', value: x.split('/').pop() ?? '' }))
        .filter(x => x.text.toLowerCase().includes(selectedKeys.toLowerCase())),
    );
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    seTFilter((folders ?? []).map(x => ({ text: x.split('/').pop() ?? '', value: x.split('/').pop() ?? '' })));
  };
  const availableDocumentColumns: ColumnType<LinkedDocument>[] = [
    {
      title: t('Document Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: t('Folder'),
      key: 'location',
      dataIndex: 'location',
      width: 80,
      filters: filter,
      render: (value, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record.location ? record.location.split('/').pop() : 'Root'}
          </div>
        );
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
          <Row>
            <Search
              placeholder={`Search `}
              onSearch={value => handleSearch(value)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          </Row>
          <Row style={{ marginBottom: 8, marginTop: 8 }}>
            <Radio.Group
              value={filterValue}
              onChange={value => {
                setFilterValue(value.target.value);
              }}
            >
              <Space direction="vertical">
                <Radio value={''}>{'all'}</Radio>
                {filter.map(x => (
                  <Radio value={x.value}>{x.text}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </Row>

          <Row>
            <Button
              type="primary"
              onClick={() => {
                getNewDocuments(1, true, {
                  search: searchStr,
                  filter: filterValue,
                });
                confirm();
              }}
              size="small"
              style={{ width: '100%', marginRight: 10 }}
            >
              OK
            </Button>
          </Row>
        </div>
      ),
      filterSearch: true,
      filterMultiple: false,
    },
  ];

  const previewSelectedDocumentColumns: ColumnType<LinkedDocument>[] = [
    {
      title: t('Link'),
      key: 'action',
      align: 'center',
      width: 40,
      render(value, record, index) {
        return (
          <Button
            size="small"
            type="primary"
            icon={<MinusOutlined style={{ fontSize: 12 }} />}
            onClick={() => setselectedLinkedDocumentData(prev => prev.filter(document => document.id !== record.id))}
          />
        );
      },
    },
    {
      title: t('Document Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: t('Folder'),
      key: 'location',
      dataIndex: 'location',
      render: (value, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record.location ? record.location.split('/').pop() : 'Root'}
          </div>
        );
      },
      width: 80,
    },
  ];

  const handleAddDocument = () => {
    // const s = (selectedLinkedDocuments || []).filter(lDocument => checkedDocuments.includes(lDocument.id));
    dispatch(linkedDocumentActions.setSelectedLinkedDocuments(selectedLinkedDocumentData));
    dispatch(hideModal({ key: AddDocumentModalName }));
  };

  const getNewDocuments = (nextPage: number, forceReload: boolean = false, filterParams?: any) => {
    setIsLoadingMore(true);
    if (selectedProject) {
      const queryParams = {
        ...defaultPagingParams,
        ...availableDocumentsParams,
        page: nextPage,
        // search: searchStr,
        // filter: filterValue,
      };
      setSearchStr(filterParams?.search || '');
      setFilterValue(filterParams?.filter || '');
      DocumentService.Get.getDocumentDetailByProjectId(selectedProject.id, {
        search: {
          ...queryParams,
          ...filterParams,
        },
      }).subscribe({
        next: linkedDocuments => {
          setAvailableDocuments(prev => {
            const prevDocuments = prev?.results || [];
            const newLinkedDocuments = (linkedDocuments?.results || []).filter(
              (lDocument: LinkedDocument) => !prevDocuments.find(prevDocument => prevDocument.id === lDocument.id),
            );
            if (forceReload) {
              return {
                ...linkedDocuments,
              };
            }
            return {
              ...linkedDocuments,
              results: prevDocuments.concat(newLinkedDocuments),
            };
          });
        },
        error: error => {
          console.error(error);
          Utils.errorHandling(error);

          setIsLoadingMore(false);
          setHasError(false);
        },
        complete: () => {
          setIsLoadingMore(false);
        },
      });
    }
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;

    clearTimeout(timer);
    // if (timer == null) {
    const timeoutId = setTimeout(() => {
      getNewDocuments(1, true, {
        filter: filterValue,
        search,
      });
      setSearchStr(search);
      // clearTimeout(timer);
      // timer = null;
    }, 500);
    setTimer(timeoutId);
  };

  const infiniteScrollTableProps: InfiniteScrollTableProps = {
    columns: availableDocumentColumns,
    dataSource: availableDocuments?.results || [],
    scroll: {
      y: 300,
      x: 400,
    },
    hasMore: (availableDocuments?.page || 1) < (availableDocuments?.pageCount || 1),
    loadMore: (nextPage: number) => {
      clearTimeout(timer);
      setTimeout(() => {
        getNewDocuments(nextPage, false, {
          filter: filterValue,
          search: searchStr,
        });
      }, 400);
    },
    currentPage: availableDocuments?.page || 1,
    rowKey: 'id',
    disabledLoad: hasError,
    loading: isLoadingMore,
    rowSelection: avaialbleDocumentRowSelection,
  };

  return (
    <>
      {isAddDocumentModalOpen && (
        <DraggableModal
          centered
          destroyOnClose
          title={t('Document lists')}
          open={isAddDocumentModalOpen}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          onCancel={() => {
            dispatch(hideModal({ key: AddDocumentModalName }));
          }}
          onOk={handleAddDocument}
          onClose={() => {
            setSearchStr('');
            setFilterValue('');
            seTFilter([]);
          }}
        >
          <Search placeholder={t('Search')} onChange={onSearch} allowClear></Search>
          <div>
            <InfiniteScrollTable size="small" rowKey={record => record.id} {...infiniteScrollTableProps} />
            <Table
              columns={previewSelectedDocumentColumns}
              dataSource={selectedLinkedDocumentData}
              size="small"
              scroll={{ x: 400, y: 200 }}
              pagination={false}
              bordered
            />
          </div>
        </DraggableModal>
      )}
      <DraggableModal
        title={selectedSSNameDocument ? t('Edit Doc Link') : t('Add new')}
        open={isModalOpen}
        okText={t('Save')}
        onOk={handleOk}
        cancelText={t('Cancel')}
        onCancel={handleCancel}
        confirmLoading={isSaving}
        centered
        destroyOnClose
        onClose={() => {
          setSearchStr('');
          setFilterValue('');
          seTFilter([]);
        }}
      >
        <div>
          {selectedSSNameDocument && (
            <>
              <div>
                <div>
                  <Typography.Text strong>{t('Selection Set No:')}</Typography.Text>
                  <span>{` ${selectedSSNameDocument.selectionSetName}`}</span>
                </div>
              </div>
              <Divider />
            </>
          )}
          <div>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography.Text strong>{t('Selected document')}</Typography.Text>
              <Button
                size="small"
                style={{ fontSize: 12 }}
                onClick={() => dispatch(showModal({ key: AddDocumentModalName }))}
              >
                {t('Add document')}
              </Button>
            </div>
            <Table
              columns={selectedDocumentColumns}
              dataSource={selectedLinkedDocuments}
              size="small"
              scroll={{ x: 400, y: 300 }}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </DraggableModal>
    </>
  );
};
