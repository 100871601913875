import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface ProjectTargetPagingResponse extends PagingResponse {
  results: ProjectTargetResponse[];
}
export interface ProjectTargetResponse {
  projectId: number;
  targetId: number;
  targetCode: string;
  trackerId: number;
  planValue: string;
  actualValue: string;
  unitVolume: string;
  id: number;
}
export interface UpdateProjectTarget {
  companyId: number;
  name: string;
  startDate: string;
  endDate: string;
  address: string;
  description: string;
  Avatar: string;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
}

class ProjectTargetController {
  public Get = {};
  public Post = {
    getProjectTargetByProjectId: (projectId: number, inputValues: string[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/ProjectTarget/projectTarget/${projectId}`, inputValues, options);
    },
  };
  public Put = {
    updateProjectTargets: (inputValues: ProjectTargetResponse[], options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/ProjectTarget`, inputValues, options);
    },
  };
}

export const ProjectTargetService = new ProjectTargetController();
