import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface UpdateTargetDimPayload {
  name: string;
  description: string;
  price: number;
}
export interface TargetDimPagingResponse extends PagingResponse {
  results: TargetDimResponse[];
}
export interface CreateUpdateIssueTarget {
  issueId: number;
  targetId: number;
  targetDim?: TargetDimResponse;
  planValue: string;
  actualValue?: string;
  costPerValue?: number;
}

export interface LogIssueTargetDTO {
  targetId: number;
  actualValue: string;
}

export interface TargetDimResponse {
  description: string | null;
  name: string;
  actualDisplayName: string;
  code: string;
  status: number;
  tracker: number;
  categoryId: number;
  projectId: number;
  id: number;
}
export interface TargetDimPagingResponse extends PagingResponse {
  results: TargetDimResponse[];
}

class TargetDimController {
  public Get = {
    getTargetDimByCondition: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/TargetDim/condition`, options);
    },
  };

  public Post = {};

  public Put = {
    addTargetToIssue: (input: CreateUpdateIssueTarget[], issueId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/TargetDim/addTargetToIssue/${issueId}`, input, options);
    },
    logTargetToIssue: (targetDimActualValue: string, issueId: number, targetId: number, options?: RequestOptions) => {
      return HttpClient.put(
        `${apiUrl}/TargetDim/logTargetToIssue/${issueId}/${targetId}`,
        targetDimActualValue,
        options,
      );
    },
    logTargetsToIssue: (
      input: { logIssueTargetDTOs: LogIssueTargetDTO[] },
      issueId: number,
      options?: RequestOptions,
    ) => {
      return HttpClient.put(`${apiUrl}/TargetDim/logTargetsToIssue/${issueId}`, input, options);
    },
  };

  public Delete = {};
}

export const TargetDimService = new TargetDimController();
