import dayjs from 'dayjs';
import { catchError, concat, filter, map, switchMap, withLatestFrom, mergeMap, of } from 'rxjs';

import { projectActions } from './projectSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { CreateLabelData, LabelService } from '@/services/LabelService';
import { ProjectService } from '@/services/ProjectService';
import { ProjectTargetService } from '@/services/ProjectTargetService';
import Utils from '@/utils';

const getProjectsRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsRequest.match),
    switchMap(action => {
      // eslint-disable-next-line
      const { companyId, search } = action.payload;
      const today = dayjs();
      return concat(
        [startLoading({ key: 'GetProjects' })],
        ProjectService.Get.getCurrentProjects({ search }).pipe(
          switchMap(projects => {
            return [
              projectActions.setProjectList(projects),
              projectActions.getProjectsProgressRequest({
                body: (projects?.results || []).map((project: any) => project?.id),
                search: { dateTime: today.format('YYYY-MM-DD') },
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectList(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetProjects' })],
      );
    }),
  );
};

const getProjectsProgressRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsProgressRequest.match),
    switchMap(action => {
      // eslint-disable-next-line
      const { body, search } = action.payload;
      return concat(
        [startLoading({ key: 'GetProjects' })],
        ProjectService.Put.getProjectProgress(body, { search }).pipe(
          switchMap(progresses => {
            return [projectActions.setProjectsProgress(progresses)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectsProgress(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetProjects' })],
      );
    }),
  );
};
const deleteProjectRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.deleteProjectRequest.match),
    switchMap(action => {
      // eslint-disable-next-line
      const { projectId, search } = action.payload;
      return concat(
        [startLoading({ key: 'GetProjects' })],
        ProjectService.Delete.deleteProject(projectId).pipe(
          map(projects => {
            return projectActions.getProjectsRequest({ search });
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.getProjectsRequest({ search })];
          }),
        ),
        [stopLoading({ key: 'GetProjects' })],
      );
    }),
  );
};
const createProject$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.createProjectRequest.match),
    switchMap(action => {
      const data = action.payload;
      return concat(
        [startLoading({ key: 'createProject' })],
        ProjectService.Post.createProject(data).pipe(
          switchMap(result => {
            Utils.successNotification('Create project successfully');
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(3),
              projectActions.setCreateProjectResponse(result),
              projectActions.createRootFolderRequest({ projectId: result.id, folder: { name: '' } }),
              projectActions.createProjectDefaultForPcvm({ projectId: result.id }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(0),
              projectActions.setCreateProjectResponse(null),
            ];
          }),
        ),
        [stopLoading({ key: 'createProject' })],
      );
    }),
  );
};
const saveProjectRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.saveProjectRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, input } = action.payload;
      return concat(
        [startLoading({ key: 'saveProject' })],
        ProjectService.Put.updateProject(projectId, input).pipe(
          switchMap(result => {
            Utils.successNotification('Save project successfully');
            if (!result.id) return [];
            return [projectActions.setSelectedProject(result), projectActions.setCreateProjectResponse(result)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'saveProject' })],
      );
    }),
  );
};
const createRootFolder$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createRootFolderRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { projectId, folder } = action.payload;
      if (!projectId) return [];
      return concat(
        [startLoading({ key: 'createProject' })],
        LabelService.Post.createRootFolder(folder, { search: { projectId: projectId.toString() } }).pipe(
          mergeMap(createdFolder => {
            const setupFolders: CreateLabelData[] = [
              {
                labelCode: 'PipingDrawings',
                name: 'Piping drawings',
                type: 'folder',
                projectId: projectId,
                children: [
                  {
                    labelCode: 'IsoPipingDrawings',
                    name: 'Iso drawings',
                    type: 'folder',
                    projectId: projectId,
                  },
                  {
                    labelCode: 'PidPipingDrawings',
                    name: 'P&ID drawings',
                    type: 'folder',
                    projectId: projectId,
                  },
                ],
              },
              { labelCode: 'EquipmentDrawings', name: 'Equipment drawings', type: 'folder', projectId: projectId },

              {
                labelCode: 'ReferenceDrawings',
                name: 'Reference drawings',
                type: 'folder',
                projectId: projectId,
              },
            ];
            return [
              ...setupFolders.map(folder =>
                projectActions.createLabelRequest({
                  projectId,
                  label: folder,
                  parentId: createdFolder.id,
                }),
              ),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createProject' })],
      );
    }),
  );
};

//document
const createLabel$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createLabelRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { label, parentId } = action.payload;
      return concat(
        [startLoading({ key: 'createProject' })],
        LabelService.Post.createLabel(label.projectId ?? -1, {
          ...label,
          parentId,
          children: undefined,
        }).pipe(
          mergeMap(createdLabel => {
            if (!!label.children?.length) {
              return label.children.map(child =>
                projectActions.createLabelRequest({
                  projectId: createdLabel.projectId,
                  label: child,
                  parentId: createdLabel.id,
                }),
              );
            }
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createProject' })],
      );
    }),
  );
};

const createProjectDefaultForPcvm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createProjectDefaultForPcvm.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { projectId } = action.payload;
      return concat(
        ProjectService.Post.createProjectDefaultForPcvm(projectId).pipe(
          mergeMap(() => {
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
      );
    }),
  );
};

const getProjectTargetsRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectTargetsRequest.match),
    switchMap(action => {
      // eslint-disable-next-line
      const { projectId, body } = action.payload;
      return concat(
        [startLoading({ key: 'GetProjectTargets' })],
        ProjectTargetService.Post.getProjectTargetByProjectId(projectId, body).pipe(
          map(projectTargets => {
            return projectActions.setProjectTargets(projectTargets);
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectTargets([])];
          }),
        ),
        [stopLoading({ key: 'GetProjectTargets' })],
      );
    }),
  );
};
const saveProjectTargetsRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.saveProjectTargetsRequest.match),
    switchMap(action => {
      // eslint-disable-next-line
      const { projectTargets, projectId, disciplines } = action.payload;
      return concat(
        [startLoading({ key: 'SaveProjectTargets' })],
        ProjectTargetService.Put.updateProjectTargets(projectTargets).pipe(
          map(() => {
            Utils.successNotification();
            return projectActions.getProjectTargetsRequest({ projectId, body: disciplines });
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'SaveProjectTargets' })],
      );
    }),
  );
};
export const projectEpics = [
  getProjectsRequest$,
  createProject$,
  createRootFolder$,
  createLabel$,
  createProjectDefaultForPcvm$,
  getProjectTargetsRequest$,
  deleteProjectRequest$,
  saveProjectTargetsRequest$,
  saveProjectRequest$,
  getProjectsProgressRequest$,
];
