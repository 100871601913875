import { Modal, Form, Row, Col, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateAreaModalName, SavingArea } from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { areaActions, getSelectedArea } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const CreateUpdateAreaModal = () => {
  const { t } = useTranslation('area');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateAreaModalName));
  const selectedArea = useAppSelector(getSelectedArea());
  const selectedProject = useAppSelector(getSelectedProject());
  const isSaving = useAppSelector(getLoading(SavingArea));
  const [form] = Form.useForm();

  const handleCancel = () => {
    dispatch(areaActions.setSelectedArea(undefined));
    dispatch(hideModal({ key: CreateUpdateAreaModalName }));
  };

  const handleOk = () => form.submit();

  const handleSaveArea = (values: any) => {
    if (selectedProject) {
      const inputData = {
        projectId: selectedProject.id,
        ...values,
      };
      if (selectedArea) {
        // prettier-ignore
        dispatch(areaActions.updateAreaRequest({ areaId: selectedArea.id, area: { ...selectedArea, ...inputData }}));
        return;
      }
      dispatch(areaActions.createAreaRequest({ area: inputData }));
    }
  };

  return (
    <DraggableModal
      title={selectedArea ? t('area.updateArea') : t('area.addNewArea')}
      open={isModalOpen}
      okText={t('area.okText')}
      onOk={handleOk}
      cancelText={t('area.cancelText')}
      onCancel={handleCancel}
      confirmLoading={isSaving}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedArea,
        }}
        onFinish={handleSaveArea}
      >
        <Row>
          <Col span={24} md={24}>
            <Form.Item label={t('area.name')} name="name" rules={[{ required: true, message: t('area.requireName') }]}>
              <Input />
            </Form.Item>
            <Form.Item label={t('area.code')} name="code" rules={[{ required: true, message: t('area.requireCode') }]}>
              <Input />
            </Form.Item>
            <Form.Item label={t('area.description')} name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DraggableModal>
  );
};
