import React from 'react';

import { Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { getActiveMenu } from '@/store/app';
import { useAppSelector } from '@/store/hooks';

export const ComingSoon = () => {
  const { t } = useTranslation();
  const activeMenu = useAppSelector(getActiveMenu());

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 80px)' }}>
      <Row style={{ backgroundColor: 'white', padding: 10 }}>
        <Typography.Title style={{ margin: 0 }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 130px)',
          backgroundColor: 'white',
          margin: 10,
          borderRadius: 6
        }}
      >
        <Typography.Title level={2}>{t('Under development')}</Typography.Title>
      </div>
    </div>
  );
};
