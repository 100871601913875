import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store/types';

export const getProjectState = (state: RootState) => state.project;

export function getProjectList() {
  return createSelector([getProjectState], state => state.projectList || []);
}

export function getSelectedProject() {
  return createSelector([getProjectState], state => state.selectedProject);
}

export function getCreateProjectCurrentStep() {
  return createSelector([getProjectState], state => state.createProjectCurrentStep || 0);
}

export function getCreateProjectInformationValue() {
  return createSelector([getProjectState], state => state.createProjectInformationValue);
}

export function getProjectMemberList() {
  return createSelector([getProjectState], state => state.projectMemberList || []);
}
export function getProjectAvatar() {
  return createSelector([getProjectState], state => state.projectAvatar || '');
}
export function getCreateProjectResponse() {
  return createSelector([getProjectState], state => state.createProjectResponse);
}
export function getProjectTargets() {
  return createSelector([getProjectState], state => state.projectTargets);
}
export function getProjectListView() {
  return createSelector([getProjectState], state => state.projectListView);
}
export function getProjectsProgress() {
  return createSelector([getProjectState], state => state.projectsProgress);
}
