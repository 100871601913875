import React from 'react';

import { Select, DatePicker, Button } from 'antd';

export const WorkpackageProgressToolbar = () => {
  return (
    <div>
      <Select placeholder={'Area'} />
      <DatePicker placeholder="Base Date" />
      <Button>Refresh</Button>
    </div>
  );
};
