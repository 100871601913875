import React, { useEffect } from 'react';

import { Button, Row, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CreateUpdateShift } from './CreateUpdateShift';
import { shiftList } from '@/datafaker/shift';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible, showModal } from '@/store/modal';
import { getShifts, shiftActions } from '@/store/shift';

export const ShiftTemplates = () => {
  const { t } = useTranslation('shift');
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const shifts = useAppSelector(getShifts());
  const shiftModal = useAppSelector(getModalVisible('CreateUpdateShiftModal'));
  const dateOnly = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    // todo: call API to get the shift list
    dispatch(shiftActions.setShifts(shiftList));
    // eslint-disable-next-line
  }, []);

  const shiftColumns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Hours'),
      dataIndex: 'hours',
      key: 'hours',
      render: (value: any, record: any) => {
        const goToWork = dayjs(`${dateOnly}T${record.startTime}`);
        const getOffWork = dayjs(`${dateOnly}T${record.endTime}`);
        const timeSuffix = goToWork.isAfter(getOffWork) ? ` ${t('Hôm sau')}` : '';
        return `${goToWork.format('HH:mm')} - ${getOffWork.format('HH:mm')}${timeSuffix}`;
      }
    },
    {
      title: t('Team'),
      dataIndex: 'team',
      key: 'team',
    },
    {
      title: t('Project'),
      dataIndex: 'project',
      key: 'project',
    },
  ];

  const createShift = () => {
    dispatch(showModal({key: 'CreateUpdateShiftModal'}));
  }

  const editShift = (shift: any) => {
    dispatch(shiftActions.setSelectedShift(shift));
    dispatch(showModal({key: 'CreateUpdateShiftModal'}));
  }

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 80px)' }}>
      {shiftModal && (<CreateUpdateShift />)}
      <Row align="stretch" style={{ backgroundColor: 'white', padding: 10 }}>
        <Typography.Title style={{ flex: 1, marginTop: 5 }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
        <div style={{ marginTop: 5 }}>
          <Button type="primary" onClick={createShift}>{t('New')}</Button>
        </div>
      </Row>
      <div
        style={{
          height: 'calc(100vh - 130px)',
        }}
      >
        <Table
          size="small"
          rowKey={(record) => record.id}
          dataSource={shifts}
          columns={shiftColumns}
          rowClassName='rowSelection'
          onRow={(record, rowIndex) => {
            return { onClick: event => {
              editShift(record);
            } };
          }}
        />
        ;
      </div>
    </div>
  );
};
