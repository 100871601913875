import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { defaultPagingParams } from '@/common/define';

const getState = (state: RootState) => state.issue;

export function getIssuesView() {
  return createSelector([getState], state => state.view || []);
}

export function getIssues() {
  return createSelector([getState], state => state.issues);
}

export function getAttributes() {
  return createSelector([getState], state => state.attributes);
}
export function getTargets() {
  return createSelector([getState], state => state.targets);
}

export function getTracker() {
  return createSelector([getState], state => state.tracker);
}
export function getTrackerId() {
  return createSelector([getState], state => state.trackerId);
}

export function getIssueQueryParams() {
  return createSelector([getState], state => state.queryParams || defaultPagingParams);
}

export function getSelectedIssue() {
  return createSelector([getState], state => state.selectedIssue);
}
export function getNewColumns() {
  return createSelector([getState], state => state.newColumns);
}
