import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateMultipleStructureBaseSetModalName,
  CreateUpdateStructureBaseSetModalName,
  RemovingStructureBaseSet,
  RemovingStructureBaseSetByProject,
} from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { structureBaseSetActions } from '@/store/structureBaseSet';

export const StructureBaseSetHeader = () => {
  const { t } = useTranslation('structureBaseSet');
  const dispatch = useAppDispatch();

  const isResetting = useAppSelector(getLoading(RemovingStructureBaseSetByProject));
  const activeMenu = useAppSelector(getActiveMenu());
  const selectedProject = useAppSelector(getSelectedProject());
  const isStructureBaseSetRemoving = useAppSelector(getLoading(RemovingStructureBaseSet));

  const createStructureBaseSet = () => {
    dispatch(structureBaseSetActions.setSelectedStructureBaseSet(null));
    dispatch(showModal({ key: CreateUpdateStructureBaseSetModalName }));
  };

  const handleResetStructureBaseSet = () => {
    if (selectedProject) {
      dispatch(
        structureBaseSetActions.resetStructureBaseSetRequest({
          projectId: selectedProject.id,
        }),
      );
    }
  };

  const editMultipleStructureBaseSetColumn = () => {
    dispatch(showModal({ key: CreateUpdateMultipleStructureBaseSetModalName }));
  };

  return (
    <Row style={{ backgroundColor: 'white', padding: 10 }}>
      <Spin fullscreen spinning={isStructureBaseSetRemoving} />
      <Typography.Title style={{ flex: 1, margin: 0, wordBreak: 'keep-all' }} level={4}>
        {activeMenu?.label}
      </Typography.Title>
      <Space wrap>
        <Button type="default" onClick={editMultipleStructureBaseSetColumn}>
          {t('Batch input')}
        </Button>
        <Button type="primary" icon={<PlusOutlined />} onClick={createStructureBaseSet}>
          {t('newStructureBaseSet')}
        </Button>
        <Popconfirm title={t('Are you sure to reset all structure base set?')} onConfirm={handleResetStructureBaseSet}>
          <Button danger icon={<DeleteOutlined style={{ color: 'red' }} />} loading={isResetting}>
            {t('Reset')}
          </Button>
        </Popconfirm>
      </Space>
    </Row>
  );
};
