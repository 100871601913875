import React from 'react';

import { CloudUploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Modal, Form, Input, DatePicker, Select, Row, Typography, message, Upload, Button, Space, notification } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { formatDateDisplay } from '@/common/define';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedIssue, issueActions } from '@/store/issue';
import { getModalVisible, hideModal } from '@/store/modal';

const props: UploadProps = {
  name: 'file',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const CreateUpdateIssue = () => {
  const { t } = useTranslation('bidding');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible('CreateUpdateIssueModal'));
  const selectedIssue = useAppSelector(getSelectedIssue());
  const [form] = Form.useForm();

  const handleSaveIssue = (values: any) => {
    console.log(values);
    notification.info({
      message: 'Thông báo',
      description: 'Tính năng đang được phát triển.',
    });
    dispatch(hideModal({ key: 'CreateUpdateIssueModal' }));
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedIssue(undefined));
    dispatch(hideModal({ key: 'CreateUpdateIssueModal' }));
  };

  const handleOk = () => form.submit();

  return (
    <Modal
      title={selectedIssue ? t('Edit') : t('New')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('Save')}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedIssue ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button key="remove" type="primary" danger>
                {t('Remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedIssue,
          startDate: selectedIssue?.plannedStartDate ? dayjs(selectedIssue.plannedStartDate) : null,
          endDate: selectedIssue?.plannedEndDate ? dayjs(selectedIssue.plannedEndDate) : null,
        }}
        onFinish={handleSaveIssue}
      >
        <Form.Item label={t('Name')} name="subject" rules={[{ required: true, message: t('Please input name!') }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Description')}
          name="description"
          rules={[{ required: true, message: t('Please input description!') }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('Assignee')}
            name="assignee"
            rules={[{ required: true, message: t('Please input assignee!') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Select options={[{ value: 'nhung', label: 'Nhung' }]} />
          </Form.Item>
          <Form.Item
            label={t('Parent')}
            name="parentId"
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 5 }}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('Phase')}
            name="phase"
            rules={[{ required: true, message: t('Please input phase!') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Select options={[{ value: 'bidding', label: 'Dự thầu' }]} />
          </Form.Item>
          <Form.Item
            label={t('Nature of work')}
            name="natureOfWork"
            rules={[{ required: true, message: t('Please input Nature of work!') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 5 }}
          >
            <Select options={[{ value: 'private', label: 'Việc làm riêng lẻ' }]} />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('Start date')}
            name="startDate"
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
          </Form.Item>
          <Form.Item
            label={t('End date')}
            name="endDate"
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 5 }}
          >
            <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('Status')}
            name="status"
            rules={[{ required: true, message: t('Please input status!') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Select options={[{ value: 'new', label: 'Mới' }]} />
          </Form.Item>
          <Form.Item
            label={t('Progress')}
            name="progress"
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 5 }}
          >
            <Select
              options={[
                { value: '0', label: '0%' },
                { value: '10', label: '10%' },
              ]}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label={t('Note')} name="note">
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row style={{ margin: 0 }}>
            <Typography.Text style={{ flex: 1 }} className='ant-form-item-label'>{t('Attachments')}</Typography.Text>
            <Upload {...props} showUploadList={false}>
              <Button type="link" icon={<CloudUploadOutlined />} style={{ padding: 0 }}>
                {t('Click to Upload')}
              </Button>
            </Upload>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
