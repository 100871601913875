import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { AreaResponse, AreasPagingResponse } from '@/services/AreaService';

interface AreaState {
  areas?: AreasPagingResponse;
  selectedArea?: AreaResponse;
  queryParams: any;
}

const initialState: AreaState = {
  queryParams: defaultPagingParams,
};

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getAreasRequest: (state, action) => {},
    createAreaRequest: (state, action) => {},
    updateAreaRequest: (state, action) => {},
    removeAreaRequest: (state, action) => {},
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    removeAllAreasRequest: (state, action: PayloadAction<{ projectId: number }>) => {},
    importAreasRequest: (state, action: PayloadAction<{ projectId: number; body: FormData }>) => {},
    importEditAreasRequest: (state, action: PayloadAction<{ projectId: number; body: FormData }>) => {},
  },
});

export const areaActions = areaSlice.actions;
export const areaReducer = areaSlice.reducer;
