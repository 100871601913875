import { useCallback, useEffect, useState } from 'react';

import {
  // EditOutlined,
  DeleteOutlined,
  FolderOutlined,
  HomeOutlined,
  DashOutlined,
  DownloadOutlined,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  FolderAddOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Modal,
  notification,
  PaginationProps,
  Progress,
  Space,
  Spin,
  Table,
  TableProps,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';

import { CreateUpdateFolderModal } from './CreateUpdateFolderModal';
import { UploadedFilesPopup } from './UploadedFilesPopup';
import {
  CreateUpdateFolderModalName,
  DownloadingDocument,
  FileStatus,
  FileStatusConstant,
  GettingDocumentList,
  RemovingDocument,
  RemovingDocuments,
  UploadingDocument,
  defaultPagingParams,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { DocumentResponse } from '@/services/DocumentService';
import { uploadFiles } from '@/services/UploadFilesService';
import { getAuthenticated } from '@/store/app';
import {
  getDocumentQueryParams,
  getDocuments,
  documentActions,
  getDocumentPath,
  getFolderRootId,
  getSelectedRowKeys,
  ConstantStatic,
} from '@/store/document';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

export const DocumentsTable = () => {
  const [notiApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation(['document', 'common']);
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const params = useAppSelector(getDocumentQueryParams());
  const documents = useAppSelector(getDocuments());
  const selectedProject = useAppSelector(getSelectedProject());
  const isLoading = useAppSelector(getLoading(GettingDocumentList));
  const isRemoving = useAppSelector(getLoading(RemovingDocument));
  const isUploadingDocument = useAppSelector(getLoading(UploadingDocument));
  const isDownloadingDocument = useAppSelector(getLoading(DownloadingDocument));
  const documentsDeleting = useAppSelector(getLoading(RemovingDocuments));
  const auth = useAppSelector(getAuthenticated());
  const [showUploadingPopup, setShowUploadingPopup] = useState(false);
  const createUpdateFolderOpen = useAppSelector(getModalVisible(CreateUpdateFolderModalName));
  const [paginationManager, setPaginationManager] = useState<PaginationProps>();
  const folderRootId = useAppSelector(getFolderRootId());
  const path = useAppSelector(getDocumentPath());
  const selectedRowKeys = useAppSelector(getSelectedRowKeys());
  const setPath = (path: DocumentResponse[]) => {
    dispatch(documentActions.setDocumentPath(path));
  };

  const currentPathId = !path?.length ? folderRootId : path[path.length - 1].id;

  // const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[]>([]);

  const uploadProps: UploadProps = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, fileList) => {
      
      handleUploadFiles(fileList);
      return false;
    },
  };

  const handleUploadFiles = (fileList: UploadFile[]) => {
    const params = {
      isPublish: true,
      companyId: auth?.companyId,
      labelid: !path.length && folderRootId ? folderRootId : path[path.length - 1]?.id,
    };
    let ids = ConstantStatic.FileDatas.filter(x => x.status != FileStatusConstant.success && x.status != FileStatusConstant.error).map(x => x.fileId) ?? [];
    let filesList = fileList.filter(x => !ids.includes(`${params.labelid}_${x.name}`));
    if (filesList.length === 0) {
      return;
    }
    
    // const divivedFileList = Utils.divideArray(fileList, 2);

    // divivedFileList.forEach((files: UploadFile[]) => {
    // });
    
    var files: FileStatus[] = filesList.map(file =>{
      const formData = new FormData();
      formData.append('iFiles', file as RcFile);
      let key = `${params.labelid}_${file.name}`;
      return {
        file: formData,
        percent: 0,
        status: FileStatusConstant.repairing,
        fileId: key,
        name: file.name,
      }
    });
    setShowUploadingPopup(true);
    console.log('start: ', files);
    dispatch(
      documentActions.uploadFiles({
        body: files,
        params,
      }),
    );
    let dividedFiles = Utils.divideArray(files, Math.floor(files.length / 10) + 1);
    dividedFiles.forEach(divFile => {
      uploadFiles( params,divFile, (file) => {
        dispatch(documentActions.setUploadProgress(file))
      }, () => {
        if (paginationManager){
          const { current, pageSize } = paginationManager;
          const search = { ...params, page: current, pageSize };
          handleRequestDocument(search);
        }
        else
        {
          handleRequestDocument(params);
        }
      } );
    })
  };

  const handleRequestDocument = (newParams?: any) => {
    const search = {
      ...params,
      ...newParams,
    };
    if (!path?.length && folderRootId) {
      dispatch(dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: search })));
    } else {
      const lastPath = path[(path?.length || 1) - 1];
      if (lastPath) {
        dispatch(documentActions.getLabelRequest({ documentId: lastPath.id, params: search }));
      }
    }
  };

  useEffect(() => {
    onSelectChange([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbItems: BreadcrumbItemType[] = [
      {
        title: <HomeOutlined style={{ cursor: 'pointer' }} />,
        onClick: () => {
          if (path.length > 0 && folderRootId) {
            dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params }));
            setPath([]);
          }
        },
      },
    ];
    if (path.length > 0) {
      path.forEach((p, index) => {
        breadcrumbItems.push({
          title: <span style={{ cursor: 'pointer' }}>{`${p.name}`}</span>,
          onClick: () => {
            if (index < path.length - 1)
              dispatch(documentActions.getLabelRequest({ documentId: p.id, params: defaultPagingParams }));
            setPath(path.slice(0, index + 1));
          },
        });
      });
    }
    setBreadcrumbs(breadcrumbItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, selectedProject]);

  useEffect(() => {
    if (isDownloadingDocument) {
      notiApi.open({
        message: t('Uploading item'),
        description: (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text>{t('Starting upload')}</Typography.Text>
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ),
        duration: 0,
        placement: 'top',
      });
    } else {
      notiApi.destroy();
    }
  }, [isDownloadingDocument]);

  const getMoreActions = useCallback((record: DocumentResponse) => {
    let moreActions: any[] = [];
    const fileActions = [
      {
        key: 'download',
        label: t('Download'),
        icon: <DownloadOutlined style={{ color: '#48b691' }} />,
        onClick: () => downloadDocument(record),
      },
      {
        key: 'delete',
        label: t('Delete'),
        icon: <DeleteOutlined style={{ color: 'red' }} />,
        onClick: () => handleRemoveDocument(record),
      },
    ];
    const folderActions = [
      {
        key: 'delete',
        label: t('Delete'),
        icon: <DeleteOutlined style={{ color: 'red' }} />,
        onClick: () => handleRemoveLabel(record),
      },
    ];

    if (record.type !== 'folder') {
      moreActions = moreActions.concat(fileActions);
    } else {
      moreActions = moreActions.concat(folderActions);
    }

    return moreActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const editDocument = (document: DocumentResponse) => {
  //   dispatch(documentActions.setSelectedDocument(document));
  //   dispatch(showModal({ key: CreateUpdateDocumentModalName }));
  // };

  const downloadDocument = (document: DocumentResponse) => {
    dispatch(documentActions.downloadFile({ document, search: { companyId: auth?.companyId } }));
  };

  // const confirmRemoveDocument = (document: DocumentResponse) => {
  //   Modal.confirm({
  //     title: t('Notification'),
  //     content: (
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: t('confirmRemove', {
  //             name: `<strong>"${document.name}"</strong>`,
  //           }),
  //         }}
  //       />
  //     ),
  //     closable: true,
  //     onOk: close => {
  //       handleRemoveDocument(document.id);
  //       close();
  //     },
  //   });
  // };

  const handleRemoveLabel = (label: DocumentResponse) => {
    dispatch(documentActions.removeLabelRequest({ labelId: label.id, parentId: label.parentId || undefined }));
  };

  const handleRemoveDocument = (document: DocumentResponse) => {
    dispatch(
      documentActions.removeDocumentRequest({ documentId: document.id, parentId: document.parentId || undefined }),
    );
  };

  const handleTableChange: TableProps<DocumentResponse>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    handleRequestDocument(search);
    setPaginationManager(pagination);
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('pagingTotal', { range1: range[0], range2: range[1], total, ns: 'common' });

  const requestLabel = (document: DocumentResponse) => {
    dispatch(documentActions.getLabelRequest({ documentId: document.id, params: defaultPagingParams }));
    setPath([...path, document]);
  };

  const createFolder = () => {
    dispatch(showModal({ key: CreateUpdateFolderModalName }));
  };

  const uploadActions = [
    {
      key: 'createFolder',
      label: t('New folder'),
      icon: <FolderAddOutlined style={{ fontSize: 16 }} />,
      onClick: createFolder,
    },
    {
      key: 'uploadLabel',
      label: (
        <Upload {...uploadProps}>
          <UploadOutlined style={{ marginRight: 6, fontSize: 16, color: '#cf6eff' }} /> {t('Upload')}
        </Upload>
      ),
    },
  ];

  const columns: TableProps<DocumentResponse>['columns'] = [
    {
      dataIndex: 'type',
      key: 'type',
      width: 40,
      align: 'center',
      render: (value, record) => {
        return value !== 'folder' ? (
          <></>
        ) : (
          <FolderOutlined
            onDoubleClick={() => requestLabel(record)}
            style={{ marginRight: 10, fontSize: 20, cursor: 'pointer' }}
          />
        );
      },
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (value, record) => {
        return record.type !== 'folder' ? (
          <>{value}</>
        ) : (
          <Typography.Text style={{ cursor: 'pointer' }} onDoubleClick={() => requestLabel(record)}>
            {value}
          </Typography.Text>
        );
      },
    },
    {
      title: t('Size'),
      dataIndex: 'size',
      key: 'size',
      render: size => <>{size ? Utils.readableFileSize(size) : ''}</>,
    },
    {
      fixed: 'right',
      align: 'center',
      width: '80px',
      key: 'action',
      render: (_, record) => {
        return (
          !!getMoreActions(record).length && (
            <Dropdown menu={{ items: getMoreActions(record) }}>
              <Button icon={<DashOutlined style={{ fontSize: 12 }} />} shape="circle" />
            </Dropdown>
          )
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRows?: DocumentResponse[]) => {
    dispatch(documentActions.setSelectedRowKeys(newSelectedRowKeys));
  };

  // eslint-disable-next-line
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const confirmRemoveDocuments = () => {
    if (selectedRowKeys) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: `Are you sure to remove ${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''}`,
            }}
          />
        ),
        closable: true,
        onOk: close => {
          handleRemoveDocuments(selectedRowKeys);
          close();
        },
      });
    }
  };
  const handleRemoveDocuments = (rowKeys: React.Key[]) => {
    const removelabels = (documents?.results || []).filter(
      document => rowKeys.includes(document.id) && document.type === 'folder',
    );
    const removeDocuments = (documents?.results || []).filter(
      document => rowKeys.includes(document.id) && document.type !== 'folder',
    );
    if (removelabels.length > 0) {
      dispatch(
        documentActions.removeLabelsRequest({ labelIds: removelabels.map(label => label.id), parentId: currentPathId }),
      );
    }
    if (removeDocuments.length > 0) {
      dispatch(
        documentActions.removeDocumentsRequest({
          documentIds: removeDocuments.map(document => document.id),
          parentId: currentPathId,
        }),
      );
    }
  };

  return (
    <div>
      {createUpdateFolderOpen && <CreateUpdateFolderModal />}
      <div style={{ padding: 10 }}>
        {contextHolder}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 8,
            alignItems: 'center',
          }}
        >
          {!!path.length ? (
            <Breadcrumb items={breadcrumbs} style={{ padding: '0 18px 12px' }} />
          ) : (
            <div style={{ width: 1 }}></div>
          )}
          {!!folderRootId && (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
              {/* <div
                style={{
                  visibility: isUploadingDocument && uploadProgress !== undefined ? 'visible' : 'hidden',
                  transition: 'all 300ms ease-in-out',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 5,
                  marginRight: 5,
                  alignItems: 'center',
                }}
              > */}
                {/* {isUploadingDocument && <Progress percent={uploadProgress[].percent} size={[200, 10]} showInfo={false} />}
                <Spin indicator={<LoadingOutlined />} spinning={isUploadingDocument && uploadProgress !== 100} /> */}
              {/* </div> */}
              <Dropdown menu={{ items: uploadActions }}>
                <Button icon={<PlusOutlined />}>New</Button>
              </Dropdown>
              {!!selectedRowKeys?.length && (
                <>
                  <Divider type="vertical" style={{ borderInlineStart: '1px solid rgba(0, 0, 0, 0.16)' }} />
                  <Space direction="horizontal">
                    <Typography.Text>
                      {`${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''} selected`}
                    </Typography.Text>
                    <div>
                      <Button danger loading={documentsDeleting} onClick={confirmRemoveDocuments}>
                        Delete
                      </Button>
                    </div>
                  </Space>
                </>
              )}
            </div>
          )}
        </div>
        <Table
          rowKey={record => record.id}
          virtual
          dataSource={(documents?.results || []).map(document => ({ ...document, children: undefined }))}
          columns={columns}
          size="small"
          scroll={{ x: 800, y: windowSize[1] - 310 }}
          pagination={{
            current: documents?.page || defaultPagingParams.page,
            pageSize: documents?.pageSize || defaultPagingParams.pageSize,
            total: documents?.queryCount || 0,
            showSizeChanger: true,
            // responsive: true,
            showTotal,
          }}
          loading={isLoading || isRemoving}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          expandable={{
            showExpandColumn: false,
          }}
        />
      </div>
      {showUploadingPopup && <UploadedFilesPopup closeDialog={() => {
        setShowUploadingPopup(false);
        ConstantStatic.FileDatas = [];
        dispatch(documentActions.setFiles([]));
      }
        }></UploadedFilesPopup> }
    </div>
  );
};
