import { catchError, concat, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { workPackageActions } from './workPackageSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateWorkPackageModalName,
  GettingAreaList,
  GettingWorkPackageList,
  ImportingWorkPackages,
  RemovingAllWorkPackages,
  RemovingWorkPackage,
  SavingWorkPackage,
  defaultPagingParams,
} from '@/common/define';
import { AreaService } from '@/services/AreaService';
import { WorkPackageService } from '@/services/WorkPackageService';
import Utils from '@/utils';

const getWorkPackagesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.getWorkPackagesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.workPackage.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingWorkPackageList })],
        WorkPackageService.Get.getWorkPackagesByProjectId(projectId, { search }).pipe(
          mergeMap(workPackages => {
            return [workPackageActions.setQueryParams(search), workPackageActions.setWorkPackages(workPackages)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [workPackageActions.setWorkPackages(undefined)];
          }),
        ),
        [stopLoading({ key: GettingWorkPackageList })],
      );
    }),
  );
};

const createWorkPackageRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.createWorkPackageRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { workPackage } = action.payload;
      const search = { ...defaultPagingParams, ...state.workPackage.queryParams };
      return concat(
        [startLoading({ key: SavingWorkPackage })],
        WorkPackageService.Post.createWorkPackage(workPackage).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(workPackage.projectId, { search }).pipe(
              mergeMap(workPackagesResult => {
                Utils.successNotification();
                return [
                  workPackageActions.setWorkPackages(workPackagesResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                  hideModal({ key: CreateUpdateWorkPackageModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingWorkPackage })],
      );
    }),
  );
};

const updateWorkPackageRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.updateWorkPackageRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { workPackageId, workPackage } = action.payload;
      const search = { ...defaultPagingParams, ...state.workPackage.queryParams };
      return concat(
        [startLoading({ key: SavingWorkPackage })],
        WorkPackageService.Put.updateWorkPackage(workPackageId, workPackage).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(workPackage.projectId, { search }).pipe(
              mergeMap(workPackagesResult => {
                Utils.successNotification();
                return [
                  workPackageActions.setWorkPackages(workPackagesResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                  hideModal({ key: CreateUpdateWorkPackageModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingWorkPackage })],
      );
    }),
  );
};

const removeWorkPackageRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.removeWorkPackageRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { workPackageId, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.workPackage.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingWorkPackage })],
        WorkPackageService.delete.removeWorkPackage(workPackageId).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(projectId, { search }).pipe(
              mergeMap(workPackagesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  workPackageActions.setWorkPackages(workPackagesResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                  hideModal({ key: CreateUpdateWorkPackageModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingWorkPackage })],
      );
    }),
  );
};

const getAreasRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.getAreasRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingAreaList })],
        AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
          mergeMap(areas => {
            return [workPackageActions.setAreas(areas)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [workPackageActions.setAreas(undefined)];
          }),
        ),
        [stopLoading({ key: GettingAreaList })],
      );
    }),
  );
};
const removeAllWorkPackagesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.removeAllWorkPackagesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      const search = { ...defaultPagingParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingAllWorkPackages })],
        WorkPackageService.delete.removeAllWorkPackages(projectId).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Reset successfully');
                return [
                  workPackageActions.setWorkPackages(empResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingAllWorkPackages })],
      );
    }),
  );
};
const importWorkPackagesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.importWorkPackagesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, body } = action.payload;
      const search = {
        ...defaultPagingParams,
        page: 1,
      };
      return concat(
        [startLoading({ key: ImportingWorkPackages })],
        WorkPackageService.Post.importWorkPackages(projectId, body).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Import successfully');
                return [
                  workPackageActions.setWorkPackages(empResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingWorkPackages })],
      );
    }),
  );
};

const importEditWorkPackagesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workPackageActions.importEditWorkPackagesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, body } = action.payload;
      const search = {
        ...defaultPagingParams,
        page: 1,
      };
      return concat(
        [startLoading({ key: ImportingWorkPackages })],
        WorkPackageService.Put.importEditWorkPackages(projectId, body).pipe(
          switchMap(() => {
            return WorkPackageService.Get.getWorkPackagesByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Import successfully');
                return [
                  workPackageActions.setWorkPackages(empResult),
                  workPackageActions.setSelectedWorkPackage(undefined),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workPackageActions.setWorkPackages(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingWorkPackages })],
      );
    }),
  );
};

export const workPackageEpics = [
  getWorkPackagesRequest$,
  createWorkPackageRequest$,
  updateWorkPackageRequest$,
  removeWorkPackageRequest$,
  getAreasRequest$,
  removeAllWorkPackagesRequest$,
  importWorkPackagesRequest$,
  importEditWorkPackagesRequest$,
];
