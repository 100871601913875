import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateWorkPackageModalName, RemovingAllWorkPackages } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { workPackageActions } from '@/store/workPackage';

export const WorkPackagesHeader = () => {
  const { t } = useTranslation('BasedData');
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const isResetting = useAppSelector(getLoading(RemovingAllWorkPackages));

  const createWorkPackage = () => {
    dispatch(showModal({ key: CreateUpdateWorkPackageModalName }));
  };

  const handleResetWorkpackages = () => {
    if (selectedProject) {
      dispatch(
        workPackageActions.removeAllWorkPackagesRequest({
          projectId: selectedProject.id,
        }),
      );
    }
  };

  return (
    <Row style={{ padding: 10, backgroundColor: 'white' }} gutter={[10, 10]}>
      <Space style={{ flex: 1 }}>
        <Typography.Title style={{ margin: 0, wordBreak: 'keep-all' }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Space>
      <Space wrap>
        <Button type="primary" icon={<PlusOutlined />} onClick={createWorkPackage}>
          {t('New package')}
        </Button>
        <Popconfirm title={t('Are you sure to reset all work packages ?')} onConfirm={handleResetWorkpackages}>
          <Button loading={isResetting} danger icon={<DeleteOutlined style={{ color: 'red' }} />}>
            {t('Reset work packages')}
          </Button>
        </Popconfirm>
      </Space>
    </Row>
  );
};
