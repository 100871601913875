import React from 'react';

import { Button, Form, Input, Modal, Row, Space, TimePicker, notification } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedShift, shiftActions } from '@/store/shift';

export const CreateUpdateShift = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible('CreateUpdateShiftModal'));
  const selectedShift = useAppSelector(getSelectedShift());
  const { t } = useTranslation('shift');
  const [form] = Form.useForm();
  const dateOnly = dayjs().format('YYYY-MM-DD');

  const handleSaveShift = (values: any) => {
    console.log(values);
    notification.info({
      message: 'Thông báo',
      description: 'Tính năng đang được phát triển.',
    });
    dispatch(hideModal({ key: 'CreateUpdateShiftModal' }));
  };

  const handleRemoveShift = () => {
    notification.info({
      message: 'Thông báo',
      description: 'Tính năng đang được phát triển.',
    });
    dispatch(hideModal({ key: 'CreateUpdateShiftModal' }));
  };

  const handleCancel = () => {
    dispatch(shiftActions.setSelectedShift(undefined));
    dispatch(hideModal({ key: 'CreateUpdateShiftModal' }));
  };

  const handleOk = () => form.submit();

  return (
    <Modal
      title={selectedShift ? t('Edit') : t('New')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('Submit')}
      cancelText={t('Cancel')}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedShift ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button key="remove" type="primary" danger onClick={handleRemoveShift}>
                {t('Remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={handleSaveShift}
        initialValues={{
          ...selectedShift,
          startTime: selectedShift?.startTime ? dayjs(`${dateOnly}T${selectedShift.startTime}`) : null,
          endTime: selectedShift?.endTime ? dayjs(`${dateOnly}T${selectedShift.endTime}`) : null,
        }}
      >
        <Form.Item
          labelAlign="left"
          label={t('Name')}
          name="name"
          rules={[{ required: true, message: t('Please input name!') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label={t('Start time')}
          name="startTime"
          rules={[{ required: true, message: t('Please input start time!') }]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label={t('End time')}
          name="endTime"
          rules={[{ required: true, message: t('Please input end time!') }]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item labelAlign="left" label={t('Team')} name="team">
          <Input />
        </Form.Item>
        <Form.Item labelAlign="left" label={t('Project')} name="project">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
