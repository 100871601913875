import { useEffect } from 'react';

import { AreaListHeader } from './AreaListHeader';
import { AreaListTable } from './AreaListTable';
import { AreaListToolbar } from './AreaListToolbar';
import { CreateUpdateAreaModal } from './CreateUpdateAreaModal';
import { CreateUpdateAreaModalName } from '@/common/define';
import { areaActions, getAreaQueryParams } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const AreaList = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(getAreaQueryParams());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateAreaModalName));
  const selectedProject = useAppSelector(getSelectedProject());

  useEffect(() => {
    if (selectedProject) {
      dispatch(areaActions.getAreasRequest({ params, projectId: selectedProject.id }));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AreaListHeader />
      <AreaListToolbar />
      <AreaListTable />
      {isModalOpen && <CreateUpdateAreaModal />}
    </>
  );
};
