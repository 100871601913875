import React from 'react';

import { PlusOutlined, DashOutlined } from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateTrackingIssueModalName } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { issueActions } from '@/store/issue';
import { showModal } from '@/store/modal';

export const PlanTotalVolumeToolbar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const activeMenu = useAppSelector(getActiveMenu());
  
    return (
      <Row style={{ backgroundColor: 'white', padding: 10 }}>
        <Typography.Title style={{ flex: 1, margin: 0 }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Row>
    );
}
