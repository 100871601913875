import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

const ProjectLayout = () => {
  const location = useLocation();
  const selectedProject = useAppSelector(getSelectedProject());
  let {refresh_token, orgId} = Utils.getPrameterToken(location.search);
  if (!selectedProject && (!refresh_token || !orgId)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProjectLayout;
