import { useEffect } from 'react';

import { Modal, Form, Input, DatePicker, Row, Button, Space, Col } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateStructureBaseSetModalName,
  SavingStructureBaseSet,
  formatDateDisplay,
  invalidDateStrings,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getSelectedStructureBaseSet, structureBaseSetActions } from '@/store/structureBaseSet';

dayjs.extend(utc);
export const CreateUpdateStructureBaseSet = () => {
  const { t } = useTranslation('structureBaseSet');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateStructureBaseSetModalName));

  const selectedStructureBaseSet = useAppSelector(getSelectedStructureBaseSet());
  const selectedProject = useAppSelector(getSelectedProject());
  const isSaving = useAppSelector(getLoading(SavingStructureBaseSet));

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedStructureBaseSet) {
      form.setFieldsValue({
        ...selectedStructureBaseSet,
        planStart:
          selectedStructureBaseSet.planStart && !invalidDateStrings.includes(selectedStructureBaseSet.planStart)
            ? dayjs(selectedStructureBaseSet.planStart + 'Z')
            : undefined,
        planEnd:
          selectedStructureBaseSet.planEnd && !invalidDateStrings.includes(selectedStructureBaseSet.planEnd)
            ? dayjs(selectedStructureBaseSet.planEnd + 'Z')
            : undefined,
        actStart:
          selectedStructureBaseSet.actStart && !invalidDateStrings.includes(selectedStructureBaseSet.actStart)
            ? dayjs(selectedStructureBaseSet.actStart + 'Z')
            : undefined,
        actEnd:
          selectedStructureBaseSet.actEnd && !invalidDateStrings.includes(selectedStructureBaseSet.actEnd)
            ? dayjs(selectedStructureBaseSet.actEnd + 'Z')
            : undefined,
      });
    }
  }, [selectedStructureBaseSet]);

  const handleSaveStructureBaseSet = (values: any) => {
    if (selectedProject) {
      const inputData = {
        projectId: selectedProject.id,
        ...values,
      };
      if (selectedStructureBaseSet) {
        dispatch(
          structureBaseSetActions.updateStructureBaseSetRequest({
            structureBaseSetId: selectedStructureBaseSet.id,
            structureBaseSet: { ...selectedStructureBaseSet, ...inputData },
          }),
        );
      } else {
        dispatch(structureBaseSetActions.createStructureBaseSetRequest({ structureBaseSet: inputData }));
      }
    }
  };

  const handleCancel = () => {
    dispatch(structureBaseSetActions.setSelectedStructureBaseSet(undefined));
    dispatch(hideModal({ key: CreateUpdateStructureBaseSetModalName }));
  };

  const handleOk = () => form.submit();

  // const haveAdditional = additionAttributes.length > 0;
  const confirmRemoveStructureBaseSet = () => {
    if (selectedStructureBaseSet) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('confirmRemove', {
                name: `<strong>"${selectedStructureBaseSet.area}"</strong>`,
              }),
            }}
          />
        ),
        closable: true,
        onOk: close => {
          dispatch(
            structureBaseSetActions.deleteStructureBaseSetRequest({
              structureBaseSetId: selectedStructureBaseSet.id,
              projectId: selectedProject?.id,
            }),
          );
          dispatch(structureBaseSetActions.setSelectedStructureBaseSet(undefined));
          close();
        },
      });
    }
  };
  return (
    <DraggableModal
      title={selectedStructureBaseSet ? t('edit') : t('new')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('save')}
      confirmLoading={isSaving}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedStructureBaseSet ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button
                key="remove"
                type="primary"
                danger
                onClick={() => {
                  confirmRemoveStructureBaseSet();
                  dispatch(hideModal({ key: CreateUpdateStructureBaseSetModalName }));
                }}
              >
                {t('remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Form form={form} layout="vertical" onFinish={handleSaveStructureBaseSet}>
        <Form.Item label={t('area')} name="area" rules={[{ required: true, message: t('Please input name!') }]}>
          <Input />
        </Form.Item>
        <Row gutter={[10, 10]}>
          <Col sm={24} md={12}>
            <Form.Item label={t('item')} name="item">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t('column')} name="column">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={t('grade')} name="grade">
          <Input />
        </Form.Item>
        <Row gutter={[10, 10]}>
          <Col sm={24} md={12}>
            <Form.Item label={t('partMark')} name="partMark">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t('member')} name="member">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={t('size')} name="size">
          <Input />
        </Form.Item>
        <Row gutter={[10, 10]}>
          <Col sm={24} md={12}>
            <Form.Item label={t('length(m)')} name="length">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t('weight(kg)')} name="weight">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col sm={24} md={12}>
            <Form.Item label={t('planStart')} name="planStart">
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t('planEnd')} name="planEnd">
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col sm={24} md={12}>
            <Form.Item label={t('actStart')} name="actStart">
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t('actEnd')} name="actEnd">
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DraggableModal>
  );
};
