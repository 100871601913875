import { Avatar, Menu, Popover } from 'antd';

import { useAuth } from '@/hooks';
import Utils from '@/utils';

export const UserLogin = () => {
  const auth = useAuth();

  const handleSignout = () => {
    auth.signout(() => {});
  }

  return (
    auth.user && (
      <>
        <p style={{ color: 'white', marginRight: 10 }}>{`${auth.user.Firstname ?? ''}`.trim()}</p>
        <Popover
          content={
            <Menu style={{ borderInlineEnd: 0 }}>
              <Menu.Item onClick={handleSignout}>Sign out</Menu.Item>
            </Menu>
          }
          title={`${auth.user.Lastname ?? ''} ${auth.user.Firstname ?? ''}`.trim()}
          arrow={false}
        >
          <Avatar size={30} style={{ backgroundColor: Utils.stringToColour(auth.user.Id), cursor: 'pointer' }}>
            {auth.user.Firstname?.charAt(0) ?? ''}
          </Avatar>
        </Popover>
      </>
    )
  );
};
