import React from 'react';

import {
  PlusOutlined,
  DashOutlined,
} from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/modal';

export const BidHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());

  const createIssue = () => {
    dispatch(showModal({ key: 'CreateUpdateIssueModal' }));
  };

  return (
    <Row style={{ backgroundColor: 'white', padding: 10 }}>
      <Typography.Title style={{ flex: 1, margin: 0 }} level={4}>
        {activeMenu?.label}
      </Typography.Title>
      <Space>
        <Button type="primary" icon={<PlusOutlined />} onClick={createIssue}>
          {t('New task')}
        </Button>
        <Button icon={<DashOutlined />} />
      </Space>
    </Row>
  );
};
