import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';

import styles from './AuthLayout.module.less';
import { LayoutHeader } from './Header/LayoutHeader';
import { LeftSider } from './Sider/LeftSider';
import { AuthenticationFlow } from '../AuthenticationFlow';
import { RequireAuth } from '../RequireAuth';
import { MetaMenuAuthRouteObject } from '@/routes';
import Utils from '@/utils';

const { Content } = Layout;

interface LayoutProps {
  // default routers
  routers?: MetaMenuAuthRouteObject[];
  // menu routers
  authRouters?: MetaMenuAuthRouteObject[];
}

const MainLayout = (props: LayoutProps) => {
  const location = useLocation();
  const {refresh_token} = Utils.getPrameterToken(location.search);
  return (
    <RequireAuth>
      <AuthenticationFlow>
        <Layout className={styles.mainContainer}>
          <LayoutHeader />
          <Layout>
            {!refresh_token && <LeftSider />}
            <Layout>
              <Content className={`${styles.contentContainer} custom_scrollbar`}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </AuthenticationFlow>
    </RequireAuth>
  );
};

export default MainLayout;
