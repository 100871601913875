import area from './area.json';
import bidding from './bidding.json';
import common from './common.json';
import issue from './issue.json';
import layout from './layout.json';
import login from './login.json';
import projects from './projects.json';
import shift from './shift.json';
import structureBaseSet from './structureBaseSet.json';
import welcome from './welcome.json';
import workPackage from './workPackage.json';

// eslint-disable-next-line
export default {
  bidding,
  common,
  welcome,
  login,
  layout,
  projects,
  shift,
  area,
  workPackage,
  issue,
  structureBaseSet,
};
