import React, { useEffect, useRef } from 'react';

import { gantt } from 'dhtmlx-gantt';
import moment from 'moment';

import { DisciplineOption } from '@/common/define';
import { IssueResponse } from '@/services/IssueService';
import { getAreas } from '@/store/area';
import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';
import { getIssues } from '@/store/workpackageProgress';

const dateTimeFormat = 'YYYY-MM-DD HH:mm';
const disciplines = {
  [DisciplineOption.Civil]: 'Civil',
  [DisciplineOption.Piping]: 'Piping',
  [DisciplineOption.E_AND_I]: 'E_AND_I',
  [DisciplineOption.Equipment]: 'Equipment',
  [DisciplineOption.Structure]: 'Structure',
}
export const WorkpackageProgressChart = () => {
  const selectedProject = useAppSelector(getSelectedProject());
  const issues = useAppSelector(getIssues());
  const areas = useAppSelector(getAreas());
  const workpackages = useAppSelector(getWorkPackages());
  const ganttContainer = useRef<HTMLDivElement>(null);
  
  const textEditor = { type: 'text', map_to: 'text' };
  const dateEditor = { type: 'date', map_to: 'start_date' };
  const durationEditor = { type: 'number', map_to: 'duration', min: 0, max: 1000 };
  
  const mapIssuesToGanttData = (issues: IssueResponse[]) => {
    let data: any = [];
    issues.forEach(issue => {
      const foundWpk = (workpackages?.results || []).find(workpackage => workpackage.id === issue.workPackageId);
      if (foundWpk) {
        data.push({
          id: issue.id,
          text: issue.subject,
          start_date: issue.plannedStartDate ? moment(issue.plannedStartDate).format(dateTimeFormat) : moment().toDate(),
          end_date: issue.plannedEndDate ? moment(issue.plannedEndDate).format(dateTimeFormat) : moment().toDate(),
          actual_start_date: issue.actualStartDate ? moment(issue.actualStartDate).format(dateTimeFormat) : moment().toDate(),
          actual_end_date: issue.actualEndDate ? moment(issue.actualEndDate).format(dateTimeFormat) : moment().toDate(),
          // duration: 5,
          staffing: 'NC 10',
          progress: 1,
          readonly: true,
          discipline: foundWpk ? disciplines[foundWpk.discipline] : undefined
        })
      }
    })
    return data
  }

  useEffect(() => {
    if (!ganttContainer.current) {
      return;
    }
    gantt.config.date_format = '%Y-%m-%d %H:%i';
    gantt.config.columns = [
      {
        name: 'discipline',
        label: 'Discipline',
        align: 'right',
        width: 150,
        resize: true,
      },
      { 
        name: 'text', 
        label: 'Set Name', 
        align: 'center',
        width: 220,
        resize: true 
      },
    ];
    // specify edit form
    gantt.config.lightbox.sections = [
      { name: 'description', height: 70, map_to: 'text', type: 'textarea', focus: true },
      { name: 'staffing', height: 22, map_to: 'staffing', type: 'textarea' },
      { name: 'time', map_to: 'auto', type: 'duration', year_range: 20 },
    ];
    gantt.locale.labels.section_description = 'Công việc';
    gantt.locale.labels.section_staffing = 'Nhân sự';
    gantt.locale.labels.section_time = 'Thời gian';
    gantt.attachEvent('onGanttReady', function () {
      gantt.locale.labels.gantt_save_btn = 'Lưu';
      gantt.locale.labels.gantt_cancel_btn = 'Hủy bỏ';
      gantt.locale.labels.gantt_delete_btn = 'Xóa';
    });
    
    gantt.plugins({ tooltip: true });
    gantt.templates.tooltip_text = function (start, end, task) {
      return (
        '<b>Task: </b> ' +
        task.text +
        '<br><b>Start: </b>' +
        task.start_date +
        '<br><b>End: </b>' +
        end +
        '<br><b>Discipline: </b>' + 
        task.discipline
        // '<br/><b>Kéo dài:</b> ' +
        // task.duration +
        // (task.progress && '<br><b>Phần trăm:</b>' + task.progress * 100 + '%')
      );
    };
    gantt.init(ganttContainer.current);

    // gantt.parse({
    //   data: [
    //     /* prettier-ignore */
    //     {id: 1, text: "Thi công xây dựng " + selectedProject?.name || 'nhà chị Uyên', start_date: null, end_date: null, duration: null, parent:0, progress: 0, open: true},
    //     {
    //       id: 2,
    //       text: 'Công tác chuẩn bị',
    //       start_date: '2023-08-01 00:00',
    //       end_date: null,
    //       duration: 5,
    //       staffing: 'NC 10',
    //       parent: 1,
    //       progress: 1,
    //     },
    //     {
    //       id: 3,
    //       text: 'Phần móng',
    //       start_date: '2023-08-06 00:00',
    //       end_date: null,
    //       duration: 2,
    //       parent: 1,
    //       progress: 0.5,
    //     },
    //     {
    //       id: 4,
    //       text: 'Phần thân',
    //       start_date: null,
    //       end_date: null,
    //       duration: null,
    //       parent: 1,
    //       progress: 0.8,
    //       open: true,
    //     },
    //     {
    //       id: 5,
    //       text: 'LD CP, sàn tầng 1',
    //       start_date: '2023-08-09 00:00',
    //       end_date: null,
    //       duration: 2,
    //       parent: 4,
    //       progress: 0.2,
    //     },
    //     {id: 6, text: "LD CP, cốt thép sàn tầng 1", start_date: "2023-08-11 00:00", end_date: null, duration:1, parent:4, progress: 0},
    //     /* prettier-ignore */
    //   ],
    //   links: [
    //     { id: 1, source: 2, target: 3, type: '0' },
    //     { id: 2, source: 3, target: 4, type: '0' },
    //   ],
    // });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!ganttContainer.current) {
      return;
    }
    gantt.parse({
      data: mapIssuesToGanttData(issues?.results || [])
    })
  }, [issues])
  return <div ref={ganttContainer} style={{ width: '100%', height: 'calc(100vh - 220px)' }}></div>;
};
