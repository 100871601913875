import { saveAs } from 'file-saver';
import { catchError, concat, concatMap, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';

import { ConstantStatic, documentActions } from './documentSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateDocumentModalName,
  CreateUpdateFolderModalName,
  DownloadingDocument,
  FileStatus,
  FileStatusConstant,
  GettingDocumentList,
  RemovingDocument,
  RemovingDocuments,
  SavingDocument,
  SavingLabel,
  UploadingDocument,
  defaultPagingParams,
} from '@/common/define';
import { DocumentService } from '@/services/DocumentService';
import { LabelService } from '@/services/LabelService';
import Utils from '@/utils';

const getDocumentsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.getDocumentsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingDocumentList })],
        DocumentService.Get.getDocumentsByProjectId(projectId, { search }).pipe(
          mergeMap(documents => {
            return [
              documentActions.setQueryParams(search),
              documentActions.setDocuments(documents),
              documentActions.setDocumentPath([]),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [documentActions.setDocuments(undefined)];
          }),
        ),
        [stopLoading({ key: GettingDocumentList })],
      );
    }),
  );
};

const getLabelRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.getLabelRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { documentId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingDocumentList })],
        LabelService.Get.getLabelByDocumentId(documentId, { search }).pipe(
          mergeMap(documents => {
            return [
              documentActions.setQueryParams(search),
              documentActions.setDocuments(documents),
              documentActions.setSelectedRowKeys([]),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [documentActions.setDocuments(undefined)];
          }),
        ),
        [stopLoading({ key: GettingDocumentList })],
      );
    }),
  );
};

const downloadFile$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.downloadFile.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { document, search } = action.payload;
      return concat(
        [startLoading({ key: DownloadingDocument })],
        DocumentService.Get.downloadFile(document.id, { search }).pipe(
          mergeMap((documentBlob: any) => {
            if (documentBlob) {
              saveAs(documentBlob, document.name);
            }
            Utils.successNotification();
            return [];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: DownloadingDocument })],
      );
    }),
  );
};

const createDocumentRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.createDocumentRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { document, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams };
      return concat(
        [startLoading({ key: SavingDocument })],
        DocumentService.Post.createDocument(document).pipe(
          switchMap(() => {
            return DocumentService.Get.getDocumentsByProjectId(projectId, { search }).pipe(
              mergeMap(documentsResult => {
                Utils.successNotification();
                return [
                  documentActions.setDocuments(documentsResult),
                  documentActions.setSelectedDocument(undefined),
                  hideModal({ key: CreateUpdateDocumentModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [documentActions.setDocuments(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingDocument })],
      );
    }),
  );
};

const updateDocumentRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.updateDocumentRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, document } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams };
      return concat(
        [startLoading({ key: SavingDocument })],
        DocumentService.Put.updateDocument(document).pipe(
          switchMap(() => {
            return DocumentService.Get.getDocumentsByProjectId(projectId, { search }).pipe(
              mergeMap(documentsResult => {
                Utils.successNotification();
                return [
                  documentActions.setDocuments(documentsResult),
                  documentActions.setSelectedDocument(undefined),
                  hideModal({ key: CreateUpdateDocumentModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [documentActions.setDocuments(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingDocument })],
      );
    }),
  );
};

const removeDocumentRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.removeDocumentRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { documentId, parentId } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingDocument })],
        DocumentService.Delete.deleteDocument(documentId).pipe(
          switchMap(() => {
            Utils.successNotification('Removed successfully');
            if (!parentId) return [];
            return concat(
              [startLoading({ key: GettingDocumentList })],
              LabelService.Get.getLabelByDocumentId(parentId, { search }).pipe(
                mergeMap(documents => {
                  return [documentActions.setQueryParams(search), documentActions.setDocuments(documents)];
                }),
                catchError(errors => {
                  Utils.errorHandling(errors);
                  return [documentActions.setDocuments(undefined)];
                }),
              ),
              [stopLoading({ key: GettingDocumentList })],
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingDocument })],
      );
    }),
  );
};

const removeDocumentsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.removeDocumentsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { documentIds, parentId } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingDocuments })],
        DocumentService.Delete.deleteDocuments(documentIds).pipe(
          switchMap(() => {
            Utils.successNotification('Removed successfully');
            if (!parentId) return [];
            return concat(
              [startLoading({ key: GettingDocumentList })],
              LabelService.Get.getLabelByDocumentId(parentId, { search }).pipe(
                mergeMap(documents => {
                  return [
                    documentActions.setQueryParams(search),
                    documentActions.setDocuments(documents),
                    documentActions.setSelectedRowKeys([]),
                  ];
                }),
                catchError(errors => {
                  Utils.errorHandling(errors);
                  return [documentActions.setDocuments(undefined)];
                }),
              ),
              [stopLoading({ key: GettingDocumentList })],
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingDocuments })],
      );
    }),
  );
};

const removeLabelRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.removeLabelRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { labelId, parentId } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingDocument })],
        LabelService.Delete.deleteLabel(labelId).pipe(
          switchMap(() => {
            Utils.successNotification('Removed successfully');
            if (!parentId) return [];
            return concat(
              [startLoading({ key: GettingDocumentList })],
              LabelService.Get.getLabelByDocumentId(parentId, { search }).pipe(
                mergeMap(documents => {
                  return [documentActions.setQueryParams(search), documentActions.setDocuments(documents)];
                }),
                catchError(errors => {
                  Utils.errorHandling(errors);
                  return [documentActions.setDocuments(undefined)];
                }),
              ),
              [stopLoading({ key: GettingDocumentList })],
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingDocument })],
      );
    }),
  );
};

const removeLabelsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.removeLabelsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { labelIds, parentId } = action.payload;
      const search = { ...defaultPagingParams, ...state.document.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingDocuments })],
        LabelService.Delete.deleteLabels(labelIds).pipe(
          switchMap(() => {
            Utils.successNotification('Removed successfully');
            if (!parentId) return [];
            return concat(
              [startLoading({ key: GettingDocumentList })],
              LabelService.Get.getLabelByDocumentId(parentId, { search }).pipe(
                mergeMap(documents => {
                  return [
                    documentActions.setQueryParams(search),
                    documentActions.setDocuments(documents),
                    documentActions.setSelectedRowKeys([]),
                  ];
                }),
                catchError(errors => {
                  Utils.errorHandling(errors);
                  return [documentActions.setDocuments(undefined)];
                }),
              ),
              [stopLoading({ key: GettingDocumentList })],
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingDocuments })],
      );
    }),
  );
};

const createLabel$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.createLabelRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { label, projectId } = action.payload;
      const { documentPath, folderRootId } = state$.value.document;
      const search = { ...defaultPagingParams, ...state.document.queryParams, page: 1 };

      return concat(
        [startLoading({ key: SavingLabel })],
        LabelService.Post.createLabel(projectId, {
          ...label,
          children: undefined,
        }).pipe(
          mergeMap(() => {
            if (!!documentPath?.length) {
              const lastPath = documentPath[(documentPath?.length || 1) - 1];
              if (lastPath) {
                return LabelService.Get.getLabelByDocumentId(lastPath.id, { search }).pipe(
                  mergeMap(documents => {
                    Utils.successNotification('Create successfully');
                    return [
                      documentActions.setQueryParams(search),
                      documentActions.setDocuments(documents),
                      hideModal({ key: CreateUpdateFolderModalName }),
                    ];
                  }),
                  catchError(errors => {
                    Utils.errorHandling(errors);
                    return [documentActions.setDocuments(undefined)];
                  }),
                );
              }
            }
            if (folderRootId) {
              return LabelService.Get.getLabelByDocumentId(folderRootId, { search }).pipe(
                mergeMap(documents => {
                  return [
                    documentActions.setQueryParams(search),
                    documentActions.setDocuments(documents),
                    hideModal({ key: CreateUpdateFolderModalName }),
                  ];
                }),
                catchError(errors => {
                  Utils.errorHandling(errors);
                  return [documentActions.setDocuments(undefined)];
                }),
              );
            }
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingLabel })],
      );
    }),
  );
};

const getFolderRootIdRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.getFolderRootIdRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      return concat(
        DocumentService.Get.getFolderRootId(projectId).pipe(
          mergeMap(id => {
            return [documentActions.setFolderRootId(id)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [documentActions.setFolderRootId(undefined)];
          }),
        ),
      );
    }),
  );
};

const uploadFiles$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(documentActions.uploadFiles.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { body, params } = action.payload ?? {};
      const documentPath = state.document.documentPath;
      const folderRootId = state.document.folderRootId;
      let files: FileStatus[] = ConstantStatic.FileDatas?.map(x => ({
        fileId: x.fileId,
        file: x.file,
        documentId: x.documentId,
        name: x.name,
        status: x.status,
        percent: x.percent,
      })).filter(x => x.file?.get('iFiles'));
      const documentId =
        !documentPath?.length && folderRootId ? folderRootId : documentPath[(documentPath?.length || 1) - 1]?.id;
      const search = { ...defaultPagingParams, ...state.document.queryParams };
      let newFile: FileStatus[] = [];
      for (const file of body ?? []) {
        let tem = files.find(x => x.fileId === file.fileId);
        if (tem != null) {
          tem = file;
        } else {
          newFile.push(file);
        }
      }
      files = newFile.concat(files);
      var fileForm = files.find(x => x.status === FileStatusConstant.repairing);
      if (fileForm) {
        fileForm.status = FileStatusConstant.uploading;
        console.log('uploadingFile new ', fileForm);
      }
      ConstantStatic.FileDatas = files;
      return concat(
        [
          // startLoading({ key: UploadingDocument }),
          documentActions.setFiles(files.map(x => ({ ...x }))),
        ],
        // DocumentService.Post.uploadFiles(fileForm?.file ?? new FormData(), (progress) => {
        //   if(fileForm) {
        //     fileForm = {...fileForm, percent: progress};
        //     documentActions.setUploadProgress(fileForm)
        //   }
        // }, { search: params, limitRequests: 4 }).pipe(
        //   mergeMap(() => {
        //     console.log('uploadingFile success ', fileForm);
        //     if(fileForm) {
        //       return [
        //         documentActions.setUploadProgress({
        //           ...fileForm,
        //           status: FileStatusConstant.success,
        //           percent: 100,
        //         }),
        //         documentActions.getLabelRequest({ documentId, params: search }),
        //         documentActions.uploadFiles({params}),
        //       ];
        //     }
        //     else
        //     {
        //       return [
        //         documentActions.getLabelRequest({ documentId, params: search }),
        //       ];
        //     }
        //   }),
        //   catchError(errors => {
        //     Utils.errorHandling(errors);
        //     return [stopLoading({ key: UploadingDocument }),
        //       documentActions.setUploadProgress({
        //         ...fileForm ,
        //         fileId: fileForm?.fileId ?? '',
        //         status: FileStatusConstant.error,
        //         percent: 0,
        //       }),
        //     ];
        //   }),
        // ),
        // [stopLoading({ key: UploadingDocument })],
      );
    }),
  );
};

export const documentEpics = [
  getDocumentsRequest$,
  createDocumentRequest$,
  updateDocumentRequest$,
  removeDocumentRequest$,
  removeDocumentsRequest$,
  getLabelRequest$,
  downloadFile$,
  createLabel$,
  getFolderRootIdRequest$,
  removeLabelRequest$,
  removeLabelsRequest$,
  uploadFiles$,
];
