import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { AttributePagingResponse, IssuePagingResponse, IssueResponse } from '@/services/IssueService';
import { TargetDimPagingResponse } from '@/services/TargetDimService';

interface WorkpackageProgressState {
  issues?: IssuePagingResponse;
  selectedIssue?: IssueResponse;
  attributes?: AttributePagingResponse;
  targets?: TargetDimPagingResponse;
  queryParams: any;
}

const initialState: WorkpackageProgressState = {
  queryParams: defaultPagingParams,
};

const workpackageProgressSlice = createSlice({
  name: 'workpageProgress',
  initialState,
  reducers: {
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    setAttribute: (state, action) => {
      state.attributes = action.payload;
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setSelectedIssue: (state, action) => {
      state.selectedIssue = action.payload;
    },
    getIssuesRequest: (state, action) => {},
    getIssuesByTrackerRequest: (state, action) => {},
    getAttributesByTrackerRequest: (state, action) => {},
    getTargetsByTrackerRequest: (state, action) => {},
    getTrackerRequest: (state, action) => {},
    createIssueRequest: (state, action) => {},
    updateIssueRequest: (state, action) => {},
    importFileRequest: (state, action) => {},
    exportFileRequest: (state, action) => {},
    deleteIssueRequest: (state, action) => {},
    deleteIssuesRequest: (state, action) => {},
    deleteIssuesByProjectRequest: (
      state,
      action: PayloadAction<{ projectId: number; areaId: number; workPackageId: number; trackerId: number }>,
    ) => {},
  },
});

export const workpackageProgressActions = workpackageProgressSlice.actions;
export const workpackageProgressReducer = workpackageProgressSlice.reducer;
