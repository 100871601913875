import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { AreasPagingResponse } from '@/services/AreaService';
import { WorkPackageResponse, WorkPackagesPagingResponse } from '@/services/WorkPackageService';

interface WorkPackageState {
  workPackages?: WorkPackagesPagingResponse;
  selectedWorkPackage?: WorkPackageResponse;
  areas?: AreasPagingResponse;
  queryParams: any;
}

const initialState: WorkPackageState = {
  queryParams: defaultPagingParams,
};

const workPackageSlice = createSlice({
  name: 'workPackage',
  initialState,
  reducers: {
    setSelectedWorkPackage: (state, action) => {
      state.selectedWorkPackage = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getWorkPackagesRequest: (state, action) => {},
    getAreasRequest: (state, action) => {},
    createWorkPackageRequest: (state, action) => {},
    updateWorkPackageRequest: (state, action) => {},
    removeWorkPackageRequest: (state, action) => {},
    setWorkPackages: (state, action) => {
      state.workPackages = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    removeAllWorkPackagesRequest: (state, action: PayloadAction<{ projectId: number }>) => {},
    importWorkPackagesRequest: (state, action: PayloadAction<{ projectId: number; body: FormData }>) => {},
    importEditWorkPackagesRequest: (state, action: PayloadAction<{ projectId: number; body: FormData }>) => {},
  },
});

export const workPackageActions = workPackageSlice.actions;
export const workPackageReducer = workPackageSlice.reducer;
