import { useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, PaginationProps, Space, Table, TableColumnsType, TableProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateAreaModalName, GettingAreaList, RemovingArea, defaultPagingParams } from '@/common/define';
import { MoreOptions } from '@/components';
import { useWindowSize } from '@/hooks/useWindowSize';
import { AreaResponse } from '@/services/AreaService';
import { areaActions, getAreaQueryParams, getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const AreaListTable = () => {
  const { t } = useTranslation('area');
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const params = useAppSelector(getAreaQueryParams());
  const areas = useAppSelector(getAreas());
  const selectedProject = useAppSelector(getSelectedProject());
  const [areaList, setAreaList] = useState<AreaResponse[]>([]);
  const isLoading = useAppSelector(getLoading(GettingAreaList));
  const isRemoving = useAppSelector(getLoading(RemovingArea));

  useEffect(() => {
    setAreaList(areas?.results || []);
  }, [areas]);

  const moreActions = [
    {
      key: 'edit',
      label: t('edit'),
      icon: <EditOutlined style={{ color: '#1890ff' }} />,
    },
    {
      key: 'remove',
      label: t('remove'),
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
    },
  ];

  const handleMoreActionClick = (key: any, record: any) => {
    switch (key) {
      case 'edit':
        editArea(record);
        break;
      default:
        confirmRemoveArea(record);
        break;
    }
  };

  const editArea = (area: any) => {
    dispatch(areaActions.setSelectedArea(area));
    dispatch(showModal({ key: CreateUpdateAreaModalName }));
  };

  const confirmRemoveArea = (area: AreaResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${area.name}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveArea(area.id);
        close();
      },
    });
  };

  const handleRemoveArea = (areaId: number) => {
    dispatch(areaActions.removeAreaRequest({ areaId, projectId: selectedProject?.id }));
  };

  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    if (selectedProject) {
      dispatch(areaActions.getAreasRequest({ params: search, projectId: selectedProject.id }));
    }
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('area.pagingTotal', { range1: range[0], range2: range[1], total });

  const columns: TableColumnsType<any> = [
    {
      title: t('area.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('area.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('area.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      width: 80,
      key: 'action',
      fixed: 'right',
      render: (_: any, record: any) => {
        // return <MoreOptions menuOptions={moreActions} onMenuClick={evt => handleMoreActionClick(evt, record)} />;
        return (
          <Space>
            {moreActions.map(action => (
              <Tooltip placement="left" title={t(action.key)}>
                <Button
                  type="text"
                  icon={action.icon}
                  key={action.key}
                  onClick={() => handleMoreActionClick(action.key, record)}
                />
              </Tooltip>
            ))}
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 10 }}>
      <Table
        rowKey={record => record.id}
        virtual
        dataSource={areaList}
        columns={columns}
        style={{ width: '100%' }}
        size="small"
        scroll={{ x: 1000, y: windowSize[1] - 310 }}
        pagination={{
          current: params?.page || defaultPagingParams.page,
          pageSize: params?.pageSize || defaultPagingParams.pageSize,
          total: areas?.queryCount || 0,
          responsive: true,
          showTotal,
        }}
        loading={isLoading || isRemoving}
        onChange={handleTableChange}
        rowSelection={{ columnWidth: 50 }}
      />
    </div>
  );
};
