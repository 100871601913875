import configureStore from './configureStore';

export const persistConfigStorage = {
  // do not remove the persistApp key
  whitelist: ['persistApp', 'app', 'user', 'project', 'document'],
};
export const initialStoreCongig = configureStore({});
export const store = initialStoreCongig.store;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
