import { useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, PaginationProps, Space, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateWorkPackageModalName,
  DisciplineOption,
  GettingWorkPackageList,
  RemovingWorkPackage,
  defaultPagingParams,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { WorkPackageResponse } from '@/services/WorkPackageService';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackageQueryParams, getWorkPackages, workPackageActions } from '@/store/workPackage';

export const WorkPackagesTable = () => {
  const { t } = useTranslation('workPackage');
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const params = useAppSelector(getWorkPackageQueryParams());
  const workPackages = useAppSelector(getWorkPackages());
  const selectedProject = useAppSelector(getSelectedProject());
  const isLoading = useAppSelector(getLoading(GettingWorkPackageList));
  const isRemoving = useAppSelector(getLoading(RemovingWorkPackage));
  const areas = useAppSelector(getAreas());
  const [areaOptions, setAreaOptions] = useState<{ label: string; value: number }[]>([]);
  useEffect(() => {
    const options = (areas?.results || []).map(area => ({
      label: area.name,
      value: area.id,
    }));
    setAreaOptions([{ label: 'all', value: -1 }].concat(options));
  }, [areas]);
  const moreActions = [
    {
      key: 'edit',
      label: t('Edit'),
      icon: <EditOutlined style={{ color: '#1890ff' }} />,
    },
    {
      key: 'remove',
      label: t('Remove'),
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
    },
  ];

  const disciplineOptions = [
    {
      label: t('Piping'),
      value: DisciplineOption.Piping,
    },
    {
      label: t('Equipment'),
      value: DisciplineOption.Equipment,
    },
    {
      label: t('E&I'),
      value: DisciplineOption.E_AND_I,
    },
    {
      label: t('Structure'),
      value: DisciplineOption.Structure,
    },
    {
      label: t('Civil'),
      value: DisciplineOption.Civil,
    },
  ];
  const handleMoreActionClick = (key: string, record: WorkPackageResponse) => {
    switch (key) {
      case 'edit':
        editWorkPackage(record);
        break;
      case 'remove':
        confirmRemoveWorkPackage(record);
        break;
      default:
        confirmRemoveWorkPackage(record);
        break;
    }
  };

  const editWorkPackage = (workPackage: WorkPackageResponse) => {
    dispatch(workPackageActions.setSelectedWorkPackage(workPackage));
    dispatch(showModal({ key: CreateUpdateWorkPackageModalName }));
  };

  const confirmRemoveWorkPackage = (workPackage: WorkPackageResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${workPackage.name}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveWorkPackage(workPackage.id);
        close();
      },
    });
  };

  const handleRemoveWorkPackage = (workPackageId: number) => {
    dispatch(workPackageActions.removeWorkPackageRequest({ workPackageId, projectId: selectedProject?.id }));
  };

  const handleTableChange: TableProps<WorkPackageResponse>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    dispatch(workPackageActions.getWorkPackagesRequest({ params: search, projectId: selectedProject?.id }));
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('pagingTotal', { range1: range[0], range2: range[1], total });

  const columns: TableProps<WorkPackageResponse>['columns'] = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('discipline'),
      dataIndex: 'discipline',
      key: 'discipline',
      render: (value, record) => disciplineOptions.find(x => x.value === record.discipline)?.label ?? '',
    },
    {
      title: t('area'),
      dataIndex: ['area', 'name'],
      key: 'areaName',
      render: (value, record) => areaOptions.find(x => x.value === record.areaId)?.label ?? '',
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (
          <Space>
            {moreActions.map(action => (
              <Button
                type="text"
                icon={action.icon}
                key={action.key}
                onClick={() => handleMoreActionClick(action.key, record)}
              />
            ))}
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 10 }}>
      <Table
        rowKey={record => record.id}
        virtual
        dataSource={workPackages?.results || []}
        columns={columns}
        style={{ width: '100%' }}
        size="small"
        scroll={{ x: 1000, y: windowSize[1] - 310 }}
        pagination={{
          current: params?.page || defaultPagingParams.page,
          pageSize: params?.pageSize || defaultPagingParams.pageSize,
          total: workPackages?.queryCount || 0,
          responsive: true,
          showTotal,
        }}
        loading={isLoading || isRemoving}
        onChange={handleTableChange}
        rowSelection={{ columnWidth: 50 }}
      />
    </div>
  );
};
