import { useEffect, useState } from 'react';

import {
  RightOutlined,
  LeftOutlined,
  AppstoreOutlined,
  // DashboardOutlined,
  SettingOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  SyncOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, MenuProps, Modal, SiderProps, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { catchError, forkJoin, map } from 'rxjs';

import styles from './LeftSider.module.less';
import './leftPanel.css';
import { LeftPanelWidth, MenuItem } from '@/common/define';
import { getEnvVars } from '@/environment';
import { IssueService } from '@/services/IssueService';
import { ProjectService } from '@/services/ProjectService';
import { appActions, getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, getTrackerId, issueActions } from '@/store/issue';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

const { Sider } = Layout;
const { apiUrl } = getEnvVars();

const { confirm } = Modal;

export const LeftSider = (props: SiderProps) => {
  const { ...rest } = props;
  const { t } = useTranslation(['layout']);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tracker = useAppSelector(getTracker());
  const [openKeys, setOpenKeys] = useState(['/projects/employees']);
  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const trackerId = useAppSelector(getTrackerId());

  const isSyncingPAMS = useAppSelector(getLoading('syncPams'));
  const isAsyncPiping = useAppSelector(getLoading('asyncPiping'));
  const isAsyncRemain = useAppSelector(getLoading('asyncRemain'));
  const isAsyncTestPackage = useAppSelector(getLoading('asyncTestPackage'));

  const syncPipingTaskStatus = useAppSelector(state => state.issue.syncPipingTaskStatus);
  const syncRemainPipingTaskStatus = useAppSelector(state => state.issue.syncRemainPipingTaskStatus);
  const syncTestPackageTaskStatus = useAppSelector(state => state.issue.syncTestPackageTaskStatus);

  const [showSyncDialog, setShowSyncDialog] = useState(false);
  const [errorFiles, setErrorFiles] = useState<any>();

  useEffect(() => {
    if(!isAsyncPiping && !isAsyncRemain && !isAsyncTestPackage) {
      if(showSyncDialog) {
          setTimeout(() => {
            setShowSyncDialog(false);
            if(errorFiles?.pipingTask?.length > 0 ||
              errorFiles?.testPackageTask?.length > 0 ||
              errorFiles?.remainPipingTask?.length > 0)
              {
                saveJsonToFile(errorFiles);
              }
           
          }, 2000);
      }
     
    }
  }, [isAsyncPiping, isAsyncRemain, isAsyncTestPackage]);
  const mainMenu: MenuItem[] = [
    {
      label: t('Projects'),
      icon: <AppstoreOutlined />,
      key: '/',
    },
  ];

  const projectMenu: MenuItem[] = [
    {
      label: t('Based data'),
      icon: <DatabaseOutlined />,
      key: '/projects/based-data',
      children: [
        {
          label: t('Project volume plan total'),
          key: '/projects/based-data/volume-total-plan',
        },
        {
          label: t('Areas'),
          key: '/projects/based-data/area-list',
        },
        {
          label: t('Work packages'),
          key: '/projects/based-data/work-packages',
        },
        {
          label: t('Documents'),
          key: '/projects/based-data/documents',
        },
        {
          label: t('Linked documents'),
          key: '/projects/based-data/linked-documents',
        },
        {
          label: t('Structure base set'),
          key: '/projects/based-data/structure-base-set',
        },
      ],
    },
    {
      label: t('Tasks'),
      icon: <CalendarOutlined />,
      key: '/projects/tasks',
      children: [
        {
          label: t('Piping'),
          key: '/projects/tasks/piping',
        },
        {
          label: t('Equipment'),
          key: '/projects/tasks/equipment',
        },
        {
          label: t('E&I'),
          key: '/projects/tasks/e-and-i',
        },
        {
          label: t('Structure'),
          key: '/projects/tasks/structure',
        },
        {
          label: t('Civil'),
          key: '/projects/tasks/civil',
        },
        {
          label: t('Remain Piping Workload'),
          key: '/projects/tasks/remain-piping-workload',
        },
        {
          label: t('Test package'),
          key: '/projects/tasks/test-package-s',
        },
      ],
    },
  ];
  const formatFileName = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear()).slice(-2);
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `errors ${day}-${month}-${year} ${hours}:${minutes}.txt`;
  };
  const saveJsonToFile = (jsonObject: any) => {
    const fileName = formatFileName();
    const jsonString = JSON.stringify(jsonObject, null, 2); // Chuyển đổi JSON thành chuỗi có định dạng đẹp

    // Tạo một Blob từ chuỗi JSON
    const blob = new Blob([jsonString], { type: 'text/plain' });

    // Tạo một link tạm thời và kích hoạt tải file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Sau khi tải xong, giải phóng bộ nhớ được cấp phát bởi URL.createObjectURL
    URL.revokeObjectURL(link.href);
  };
  const deleteIssuesByProjectRequest = async (projectId: number, search: any) => {
    try {
      // Make async request here
      let data = await IssueService.Delete.deleteIssuesByProject(projectId, { search }).toPromise();
      console.log(data);
    } catch (error) {}
  };
  const handleResetTasks = async () => {
    if (selectedProject && tracker?.results) {
      const trackers = tracker.results.filter(item =>
        ['PipingTask', 'PipingTestPackage', 'PipingAreaWorkload'].includes(item.code),
      );

      for (const trackerItem of trackers) {
        // Await the dispatch call to ensure it completes before moving to the next
        await deleteIssuesByProjectRequest(selectedProject.id, {
          areaId: -1,
          workPackageId: -1,
          trackerId: trackerItem.id,
        });
      }
    }
  };

  const syncPAMS = async () => {
    if (selectedProject) {
      let projectId = selectedProject.id;
      dispatch(startLoading({ key: 'GetIssuesByTracker' }));

      forkJoin({
        pipingTaskResponse: ProjectService.Put.validatePipingTask(projectId),
        testPackageTaskResponse: ProjectService.Put.validateTeskPackageTaskPAMs(projectId),
        remainPipingTaskResponse: ProjectService.Put.validateRemainPipingTaskPAMs(projectId),
      })
        .pipe(
          map(({ pipingTaskResponse, testPackageTaskResponse, remainPipingTaskResponse }) => {
            return {
              projectId,
              trackerId,
              pipingTaskResponse: pipingTaskResponse,
              remainPipingTaskResponse: remainPipingTaskResponse,
              testPackageTaskResponse: testPackageTaskResponse,
            };
          },
        ),
        )
        .subscribe({
          next: syncPAMSRequestData => {

            const errors = {
              pipingTask: syncPAMSRequestData.pipingTaskResponse?.errors,
              testPackageTask: syncPAMSRequestData.testPackageTaskResponse?.errors,
              remainPipingTask: syncPAMSRequestData.remainPipingTaskResponse?.errors,
            };

            if (
              errors.pipingTask?.length > 0 ||
              errors.testPackageTask?.length > 0 ||
              errors.remainPipingTask?.length > 0
            ) {
              setErrorFiles(errors);
              confirm({
                title: 'Notification',
                content: 'Null or invalid values exist. Do you want to sync this data?',
                onOk: () => {
                  console.log('OK');
                  setShowSyncDialog(true);
                  dispatch(
                    issueActions.syncPAMSRequest({
                      projectId,
                      trackerId,
                      PipingTaskDataSync: syncPAMSRequestData.pipingTaskResponse?.pamsTaskAvaialbles,
                      RemainPipingDataSync: syncPAMSRequestData.remainPipingTaskResponse?.pamsTaskAvaialbles,
                      TestPackageDataSync: syncPAMSRequestData.testPackageTaskResponse?.pamsTaskAvaialbles,
                    }),
                  );
                },
                onCancel: () => {
                  dispatch(stopLoading({ key: 'GetIssuesByTracker' }));
                },
              });
            } else {
              setShowSyncDialog(true);
              dispatch(
                issueActions.syncPAMSRequest({
                  projectId,
                  trackerId,
                  PipingTaskDataSync: syncPAMSRequestData.pipingTaskResponse?.pamsTaskAvaialbles,
                  RemainPipingDataSync: syncPAMSRequestData.remainPipingTaskResponse?.pamsTaskAvaialbles,
                  TestPackageDataSync: syncPAMSRequestData.testPackageTaskResponse?.pamsTaskAvaialbles,
                }),
              );
            }
          },
          error: error => {
            console.error('Error validating tasks:', error);
            dispatch(stopLoading({ key: 'GetIssuesByTracker' }));
            Utils.errorHandling(error);
          },
        });
    }
  };

  const bottomMenu: MenuItem[] = [
    {
      label: t('Project settings'),
      icon: <SettingOutlined />,
      key: '/projects/settings',
    },
  ];

  const [avatar, setAvatar] = useState<string>();
  useEffect(() => {
    if (selectedProject) {
      setAvatar(
        selectedProject.avatar &&
          selectedProject.avatar !== 'string' &&
          selectedProject.avatar !== '' &&
          selectedProject.avatar !== null
          ? selectedProject.avatar.includes('http')
            ? selectedProject.avatar
            : apiUrl + '/Projects' + selectedProject.avatar
          : undefined,
      );
    }
  }, [selectedProject, setAvatar]);

  useEffect(() => {
    const { pathname } = location;
    const menus: any = projectMenu.concat(bottomMenu).concat(mainMenu);
    for (const item of menus) {
      if (item?.key === pathname) {
        const { label, key } = item;
        dispatch(appActions.setActiveMenu({ label, key }));
      }
      if (item?.children) {
        for (const child of item.children) {
          if (child.key === pathname) {
            const { label, key } = child;
            dispatch(appActions.setActiveMenu({ label, key }));
            if (!collapsed) {
              setOpenKeys([item.key]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [location, collapsed]);

  const onClickMenu = (menu: any) => {
    const { key } = menu;
    navigate(key);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleLeftPanelVisibility = () => {
    setCollapsed(prev => !prev);
  };

  // const handleLogout = () => {
  //   auth.signout(() => {
  //     changeStoreConfig({});
  //     navigate('/login');
  //   });
  // };
  const handlebutton = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Sider
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={LeftPanelWidth}
      onCollapse={setCollapsed}
      className={styles.main}
      {...rest}
    >
      <div className={styles.menusWrapper}>
        {selectedProject && (
          <div style={{ display: 'flex', justifyContent: collapsed ? 'center' : 'flex-end', padding: 5 }}>
            {collapsed ? (
              <Tooltip title={`Sync PAMS`} placement="right">
                <Button type="primary" icon={<SyncOutlined />} loading={isSyncingPAMS} onClick={() => syncPAMS()} />
              </Tooltip>
            ) : (
              <Button type="primary" loading={isSyncingPAMS} onClick={() => syncPAMS()}>{`Sync PAMS`}</Button>
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            margin: collapsed ? 0 : 5,
            height: !selectedProject ? 0 : collapsed ? 0 : 65,
          }}
        >
          {selectedProject && (
            <>
              <Avatar shape="square" size={64} src={avatar} style={{ display: collapsed ? 'none' : 'block' }} />
              <div style={{ marginLeft: 5, paddingTop: 5, display: collapsed ? 'none' : 'block' }}>
                <b>{selectedProject?.name}</b>
              </div>
            </>
          )}
          <Button
            shape="circle"
            size="small"
            onClick={handleLeftPanelVisibility}
            className={styles.toggleButton}
            icon={collapsed ? <RightOutlined style={{ fontSize: 11 }} /> : <LeftOutlined style={{ fontSize: 11 }} />}
            style={{
              transform: collapsed ? 'translateX(-110%)' : 'translateX(-10%)',
              top: selectedProject ? 47 : '10px',
            }}
          />
        </div>
        {collapsed && selectedProject && (
          <div style={{ marginTop: 40, marginLeft: 7 }}>
            <Tooltip title={selectedProject?.name}>
              <Avatar shape="square" size={64} src={avatar} />
            </Tooltip>
          </div>
        )}
        {!selectedProject && (
          <Menu
            mode="inline"
            onClick={({ key }) => {
              navigate(key);
            }}
            selectedKeys={[activeMenu?.key]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={mainMenu}
            inlineIndent={10}
            className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
          />
        )}
        {selectedProject && (
          <>
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={projectMenu}
              inlineIndent={5}
              className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
            />
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              items={bottomMenu}
              inlineIndent={5}
              className={styles.bottom_menu}
            />
          </>
        )}
        {/* {!!auth.user && (
          <div
            className={styles.avatarContainer}
            style={{
              justifyContent: collapsed ? 'center' : 'flex-start',
              paddingInline: collapsed ? '4px 5px' : '5px 0px',
            }}
          >
            <div className={styles.avatarContainer_wrapper}>
              {!collapsed && (
                <>
                  <Avatar
                    size={26}
                    style={{ backgroundColor: Utils.stringToColour(auth?.user?.Id) }}
                  >
                    {auth.user.Firstname.charAt(0)}
                  </Avatar>
                  <Typography.Text
                    className={`${styles.avatarContainer_userName} ${collapsed ? styles.textCollapsed : ''}`}
                    strong
                  >
                    {`${auth.user.Lastname} ${auth.user.Firstname}`.trim()}
                  </Typography.Text>
                  <Button type="text" shape='circle' className={styles.logoutButton} onClick={handleLogout}>
                    <img src={LogoutIcon} alt="logout" />
                  </Button>
                </>
              )}
              {collapsed && (
                <Button type="text" shape='circle' className={styles.logoutButton} onClick={handleLogout}>
                  <img src={LogoutIcon} alt="logout" />
                </Button>
              )}
            </div>
          </div>
        )} */}
      </div>
      <Modal open={showSyncDialog} footer={null} centered title={'Synchronized PAMS Proccess'} closeIcon={null} closable={false}>
        <div>
          <p>
            {!isAsyncPiping || syncPipingTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green',  marginRight: 5 }} /> {syncPipingTaskStatus?.message}
              </>
            ) : syncPipingTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red',  marginRight: 5 }} /> {syncPipingTaskStatus?.message}
              </>
            ) : (
              <>
                <LoadingOutlined style={{marginRight: 5}}/> 
                {syncPipingTaskStatus?.message && syncPipingTaskStatus?.message.length > 0 ? syncPipingTaskStatus?.message : 'Syncing Piping Task...'  } 
              </>
            )}
          </p>
          <p>
            {!isAsyncRemain || syncRemainPipingTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green', marginRight: 5 }} />
                {syncRemainPipingTaskStatus?.message}
              </>
            ) : syncRemainPipingTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red',  marginRight: 5 }} /> {syncRemainPipingTaskStatus?.message}
              </>
            ) : (
              <>
                <LoadingOutlined style={{marginRight: 5}} /> 
                {syncRemainPipingTaskStatus?.message && syncRemainPipingTaskStatus?.message.length > 0 ? syncRemainPipingTaskStatus?.message : 'Syncing Piping Remain...'  } 
              </>
            )}
          </p>
          <p>
            {!isAsyncTestPackage || syncTestPackageTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green',  marginRight: 5 }} /> {syncTestPackageTaskStatus?.message}
              </>
            ) : syncTestPackageTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red',  marginRight: 5 }} /> {syncTestPackageTaskStatus?.message}
              </>
            ) : (
              <>
                <LoadingOutlined style={{marginRight: 5}} /> 
                {syncTestPackageTaskStatus?.message && syncTestPackageTaskStatus?.message.length > 0 ? syncTestPackageTaskStatus?.message : 'Syncing Piping Test Package...'  } 
              </>
            )}
          </p>
        </div>
      </Modal>
    </Sider>
  );
};
