import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateAreaModalName, RemovingAllAreas } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const AreaListHeader = () => {
  const { t } = useTranslation('BasedData');
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const selectedProject = useAppSelector(getSelectedProject());
  const isResetting = useAppSelector(getLoading(RemovingAllAreas));

  const createArea = () => {
    dispatch(showModal({ key: CreateUpdateAreaModalName }));
  };

  const handleResetAreas = () => {
    if (selectedProject) {
      dispatch(
        areaActions.removeAllAreasRequest({
          projectId: selectedProject.id,
        }),
      );
    }
  };

  return (
    <Row style={{ padding: 10, backgroundColor: 'white' }}>
      <Space style={{ flex: 1 }}>
        <Typography.Title style={{ margin: 0, wordBreak: 'keep-all' }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Space>
      <Space wrap>
        <Button type="primary" icon={<PlusOutlined />} onClick={createArea}>
          {t('New area')}
        </Button>
        <Popconfirm title={t('Are you sure to reset all areas ?')} onConfirm={handleResetAreas}>
          <Button loading={isResetting} danger icon={<DeleteOutlined style={{ color: 'red' }} />}>
            {t('Reset areas')}
          </Button>
        </Popconfirm>
      </Space>
    </Row>
  );
};
