import { createSlice } from '@reduxjs/toolkit';

interface PersistAppState {
  defaultTaskTableColumnsDisplayed: string[];
  taskTableColumnsDisplayed?: string[];
}

const initialState: PersistAppState = {
  defaultTaskTableColumnsDisplayed: []
};

const persistAppSlice = createSlice({
  name: 'persistApp',
  initialState,
  reducers: {
    setDefaultTaskTableColumnsDisplayed: (state, action) => {
      state.defaultTaskTableColumnsDisplayed = action.payload;
    },
    setTaskTableColumnsDisplayed: (state, action) => {},
  },
});

export const persistAppActions = persistAppSlice.actions;
export const persistAppReducer = persistAppSlice.reducer;
