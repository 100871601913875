import React, { Key, useEffect, useState } from 'react';

import {
  ImportOutlined,
  ExportOutlined,
  DownOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import type {
  TableColumnsType,
  CheckboxProps,
  UploadFile,
  UploadProps,
  PaginationProps,
  TableProps,
  TablePaginationConfig,
} from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import styles from './IssueByTracking.module.less';
import {
  CreateUpdateTrackingIssueModalName,
  DisciplineOptionType,
  RemovingTrackingIssues,
  formatDateDisplay,
  invalidDateStrings,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { IssueResponse, IssueService, IssueSortKey } from '@/services/IssueService';
import { getActiveMenu } from '@/store/app';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getAttributes,
  getIssueQueryParams,
  getIssues,
  getIssuesView,
  getNewColumns,
  getTargets,
  getTrackerId,
  issueActions,
} from '@/store/issue';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';
import Utils from '@/utils';

var listSortAttLeft = ['subject', 'selectionSetName', 'description'];
var listSortAttRight = ['plannedStartDate', 'plannedEndDate', 'actualStartDate', 'actualEndDate'];
var dateAtt = ['plannedStartDate', 'plannedEndDate', 'actualStartDate', 'actualEndDate'];
var columnWidth = 130;

type IssueColumnGroupType<RecordType = unknown> = Omit<ColumnGroupType<RecordType>, 'children'> & {
  children: IssueColumnType<RecordType>;
  hidden?: boolean;
};
type IssueColumnType<RecordType = unknown> = ColumnType<RecordType> & { sortKey?: IssueSortKey; hidden?: boolean };
type CustomSorterResult<RecordType = unknown> = Omit<SorterResult<RecordType>, 'column'> & {
  column?: IssueColumnType<RecordType>;
};
export type ColumnsType<RecordType = unknown> = (IssueColumnGroupType<RecordType> | IssueColumnType<RecordType>)[];

interface IssuesByTrackingTableProps {
  atrrSortCols?: string[];
  code: string;
}

export const IssuesByTrackingTable = ({ atrrSortCols = [], code }: IssuesByTrackingTableProps) => {
  const { t } = useTranslation('issue');
  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const view = useAppSelector(getIssuesView());
  const issues = useAppSelector(getIssues());
  const windowSize = useWindowSize();
  const activeMenu = useAppSelector(getActiveMenu());

  const [tasks, setTasks] = useState<IssueResponse[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const queryParams = useAppSelector(getIssueQueryParams());
  const [searchStr, setSearchStr] = useState(queryParams?.search);

  const [issuesColumns, setIssueColumn] = useState<ColumnsType<IssueResponse>>([]);
  const [openColumnsDisplayed, setOpenColumnsDisplayed] = useState(false);
  const [defaultCheckedList, setDefaultCheckedList] = useState<string[]>([]);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isImporting = useAppSelector(getLoading('ImportingIssue'));
  const isExporting = useAppSelector(getLoading('ExportingIssue'));
  const isFetching = useAppSelector(getLoading('GetIssuesByTracker'));
  const isFetchingsyncPams = useAppSelector(getLoading('syncPams'));
  const isFetchingasyncPiping = useAppSelector(getLoading('asyncPiping'));
  const isFetchingasyncRemain = useAppSelector(getLoading('asyncRemain'));
  const isFetchingasyncTestPackage = useAppSelector(getLoading('asyncTestPackage'));
  const issuesDeleting = useAppSelector(getLoading(RemovingTrackingIssues));

  const trackerId = useAppSelector(getTrackerId());
  const attribute = useAppSelector(getAttributes());
  const targets = useAppSelector(getTargets());
  const areas = useAppSelector(getAreas());
  const workPackages = useAppSelector(getWorkPackages());

  const [exportForm] = Form.useForm();

  const [areaOptions, setAreaOptions] = useState<{ label: string; value: number }[]>([]);
  // const [exportArea, setExportArea] = useState<any>(-1);

  const [workPackageOptions, setWorkPackageOptions] = useState<{ label: string; value: number }[]>([]);
  // const [exportWorkPackage, setExportWorkPackage] = useState<any>(-1);

  const defaultColumns = [
    {
      title: t('area'),
      width: columnWidth,
      key: 'areaId',
      sorter: false,
      filters: (areas?.results || []).map(area => ({
        text: area.name,
        value: area.id,
      })),
      filterMultiple: false,
      render: (value: any, record: IssueResponse) => areaOptions.find(x => x.value === record.areaId)?.label ?? '',
    },
    {
      title: t('workPackage'),
      width: columnWidth + 20,
      key: 'workPackageId',
      sorter: false,
      filters: (workPackages?.results || [])
        .filter(wpk => wpk.discipline === DisciplineOptionType[code])
        .map(wpk => ({
          text: wpk.name,
          value: wpk.id,
        })),
      filterMultiple: false,
      render: (value: any, record: IssueResponse) =>
        workPackageOptions.find(x => x.value === record.workPackageId)?.label ?? '',
    },
    ...Array.from(
      new Set(
        listSortAttLeft
          .concat((attribute?.results || []).map(attr => attr.name))
          .concat(listSortAttRight)
          .concat(
            (targets?.results || []).reduce<string[]>(
              (prev, target) => [...prev, target.name, target.actualDisplayName],
              [],
            ),
          ),
      ),
    ).map(key => ({
      title: t(key),
      dataIndex: key,
      key: key,
      width: 130,
    })),
  ];

  useEffect(() => {
    setNewColumns([]);
  }, []);

  useEffect(() => {
    const options = (areas?.results || []).map(area => ({
      label: area.name,
      value: area.id,
    }));
    setAreaOptions([{ label: 'all', value: -1 }].concat(options));
  }, [areas]);

  useEffect(() => {
    const options = (workPackages?.results || [])
      .filter(wpk => wpk.discipline === DisciplineOptionType[code])
      .map(workPackage => ({
        label: workPackage.name,
        value: workPackage.id,
      }));
    setWorkPackageOptions([{ label: 'all', value: -1 }].concat(options));
  }, [workPackages]);

  useEffect(() => {
    setSearchStr(queryParams?.search);
    // if(queryParams?.areaId >= 0) dispatch(issueActions.getAttributesByTrackerRequest({ trackerId: trackerId, queryParams: {...queryParams, areaId: undefined}}));
  }, [queryParams]);

  useEffect(() => {
    const issueList = issues?.results || [];
    setTasks(issueList);
    const allAttribute = attribute?.results || [];
    const allTargets = targets?.results || [];

    const firstIssue = issueList[0];
    var columns: ColumnsType<IssueResponse> = [];
    var header: IssueResponse = firstIssue;

    if (header) {
      for (const key in header) {
        var nameKey = t(key);
        if (nameKey !== '' && nameKey !== undefined && nameKey !== key) {
          columns.push({
            title: nameKey,
            dataIndex: key,
            key: key,
            // width: 130,
            ellipsis: true,
            sortKey: IssueSortKey.Property,
          });
        }
      }
    } else {
      for (const key of listSortAttLeft) {
        var nameKey = t(key);
        if (nameKey !== '' && nameKey !== undefined && nameKey !== key) {
          columns.push({
            title: nameKey,
            dataIndex: key,
            key: key,
            // width: columnWidth,
            ellipsis: true,
            sortKey: IssueSortKey.Property,
          });
        }
      }
    }
    for (let i = 0; i < allAttribute.length; i++) {
      const code = allAttribute[i].code;
      if (code == 'SelectionSetName') continue;
      columns.push({
        title: allAttribute[i].name,
        dataIndex: allAttribute[i].code,
        key: allAttribute[i].code,
        // width: columnWidth,
        ellipsis: true,
        sortKey: IssueSortKey.Attribute,
        render: (value: string, record: IssueResponse) => record.attributes?.find(x => x.code === code)?.value ?? '',
      });
    }
    if (!header) {
      for (const key of listSortAttRight) {
        var nameKey = t(key);
        if (nameKey !== '' && nameKey !== undefined && nameKey !== key) {
          columns.push({
            title: nameKey,
            dataIndex: key,
            key: key,
            // width: columnWidth,
            ellipsis: true,
            sortKey: IssueSortKey.Property,
          });
        }
      }
    }
    allTargets.forEach((target, index) => {
      columns.push({
        title: target.name,
        dataIndex: (target.code || index) + 'plan',
        key: target.name,
        // width: columnWidth,
        ellipsis: true,
        sortKey: IssueSortKey.TargetPlane,
        render: (value: string, record: IssueResponse) =>
          record.issueTargets?.find(iTarget => iTarget.targetId === target.id)?.planValue ?? '',
      });
      columns.push({
        title: target.actualDisplayName,
        dataIndex: (target.code || index) + 'actual',
        key: target.actualDisplayName,
        // width: columnWidth,
        ellipsis: true,
        sortKey: IssueSortKey.TargetActual,
        render: (value: string, record: IssueResponse) =>
          record.issueTargets?.find(iTarget => iTarget.targetId === target.id)?.actualValue ?? '',
      });
    });

    columns.push({
      title: t('action'),
      // width: 80,
      key: 'action',
      fixed: 'right',
      sorter: false,
      render: (_, record) => {
        return (
          <Space>
            {moreActions.map(action => (
              <Tooltip placement="left" title={t(action.key)}>
                <Button
                  type="text"
                  icon={action.icon}
                  key={action.key}
                  onClick={() => handleMoreActionClick(action.key, record)}
                />
              </Tooltip>
            ))}
          </Space>
        );
      },
    });
    const firstColumns: ColumnsType<IssueResponse> = [];
    firstColumns.push({
      title: t('id'),
      // width: columnWidth,
      ellipsis: true,
      key: 'id',
      dataIndex: 'id',
      sortKey: IssueSortKey.Property,
    });
    firstColumns.push({
      title: t('area'),
      // width: columnWidth,
      ellipsis: true,
      key: 'areaId',
      sorter: false,
      filters: (areas?.results || []).map(area => ({
        text: area.name,
        value: area.id,
      })),
      filterMultiple: false,
      render: (value, record: IssueResponse) => (areas?.results || []).find(x => x.id === record.areaId)?.name ?? '',
    });
    firstColumns.push({
      title: t('workPackage'),
      // width: columnWidth + 20,
      ellipsis: true,
      key: 'workPackageId',
      sorter: false,
      filters: (workPackages?.results || [])
        .filter(wpk => wpk.discipline === DisciplineOptionType[code])
        .map(wpk => ({
          text: wpk.name,
          value: wpk.id,
        })),
      filterMultiple: false,
      render: (value, record: IssueResponse) =>
        (workPackages?.results || []).find(x => x.id === record.workPackageId)?.name ?? '',
    });

    var sortColums: ColumnsType<IssueResponse> = [];
    for (let data of listSortAttLeft) {
      var col = columns.find(x => x.key === data);
      if (col != null) {
        if (col.key === 'selectionSetName') {
          sortColums.push({
            ...col,
            sortKey: IssueSortKey.Attribute,
          });
        } else {
          sortColums.push(col);
        }
      }
    }
    //sort column for attributes
    for (let attrCol of atrrSortCols) {
      var col = columns.find(x => x.key === attrCol);
      if (col != null) {
        sortColums.push(col);
      }
    }

    for (let i = 0; i < allAttribute.length; i++) {
      const code = allAttribute[i].code;
      if (code == 'SelectionSetName') continue;
      var col = columns.find(x => x.key === code);
      if (col != null && !atrrSortCols.includes(code)) {
        sortColums.push(col);
      }
    }

    for (let data of listSortAttRight) {
      var col = columns.find(x => x.key === data);
      if (col != null) {
        sortColums.push(col);
      }
    }
    const allCols = firstColumns.concat(
      sortColums.concat(columns.filter(x => x.key != null && !sortColums.some(sortCol => sortCol.key === x.key))),
    );
    const uniqueAllCols = allCols.filter((col, index, cols) => {
      return index === cols.findIndex(t => t.key === col.key);
    });
    console.log({
      columns,
      uniqueAllCols,
    });

    const newIssueColumns: ColumnsType<IssueResponse> = uniqueAllCols.map(col => {
      const sortCol = {
        ...col,
        sorter: col.sorter !== undefined ? col.sorter : true,
      };
      if (!col.key) return sortCol;
      if (dateAtt.includes(col.key.toString())) {
        return {
          ...sortCol,
          render(value, record, index) {
            return (
              <>{value && !invalidDateStrings.includes(value) ? moment.utc(value).format(formatDateDisplay) : ''}</>
            );
          },
        };
      }
      return sortCol;
    });

    setIssueColumn(newIssueColumns);
    if (!newColumns?.length) {
      if (issues?.results?.length && attribute?.results && targets?.results) {
        setNewColumns(newIssueColumns);
        const checkList = newIssueColumns.map((item: any) => item.key as string);
        setCheckedList(checkList);
      }
    }
  }, [issues, attribute, targets]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [issues]);

  useEffect(() => {
    // setNewColumns(issuesColumns);
    const checkList = issuesColumns.map((item: any) => item.key as string);
    setDefaultCheckedList(checkList);
    // setCheckedList(checkList);
  }, [issuesColumns]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // eslint-disable-next-line
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const [timer, setTimer] = useState<any>(null);
  const onSearchChange = (evt: any) => {
    clearTimeout(timer);
    const search = evt.target.value;
    setSearchStr(search);
    const timeoutId = setTimeout(() => {
      dispatch(
        issueActions.getIssuesByTrackerRequest({
          projectId: selectedProject?.id,
          trackerId: trackerId,
          queryParams: { ...queryParams, page: 1, search },
        }),
      );
    }, 500);
    setTimer(timeoutId);
  };

  const moreActions = [
    {
      key: 'edit',
      label: t('edit'),
      icon: <EditOutlined style={{ color: '#1890ff' }} />,
    },
    {
      key: 'remove',
      label: t('remove'),
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
    },
  ];

  const handleMoreActionClick = (key: any, record: any) => {
    console.log(key);
    switch (key) {
      case 'edit':
        editIssue(record);
        break;
      default:
        confirmRemoveIssue(record);
        break;
    }
  };

  const editIssue = (issue: any) => {
    dispatch(issueActions.setSelectedIssue(issue));
    setTimeout(() => {
      dispatch(showModal({ key: CreateUpdateTrackingIssueModalName }));
    }, 100);
  };

  const confirmRemoveIssue = (issue: any) => {
    Modal.confirm({
      title: t('remove'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${issue.name}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveIssue(issue.id);
        close();
      },
    });
  };

  const handleRemoveIssue = (issueId: number) => {
    dispatch(issueActions.deleteIssueRequest({ issueId, projectId: selectedProject?.id, trackerId: trackerId }));
  };

  const confirmRemoveIssues = () => {
    if (selectedRowKeys) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: `Are you sure to remove ${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''}`,
            }}
          />
        ),
        closable: true,
        onOk: close => {
          handleRemoveIssues(selectedRowKeys);
          close();
        },
      });
    }
  };

  const handleRemoveIssues = (issueIds: React.Key[]) => {
    dispatch(issueActions.deleteIssuesRequest({ issueIds, projectId: selectedProject?.id, trackerId: trackerId }));
  };

  // const [newColumns, setNewColumns] = useState<ColumnsType<IssueResponse>>(issuesColumns);
  const newColumns = useAppSelector(getNewColumns()) as ColumnsType<IssueResponse>;
  const setNewColumns = (columns: ColumnsType<IssueResponse>) => {
    dispatch(issueActions.setNewColumns(columns));
  };

  const checkAll = issuesColumns.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < issuesColumns.length;

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    setCheckedList(e.target.checked ? defaultCheckedList : []);
  };

  const options = issuesColumns.map(({ key, title }: any) => ({
    label: title || t('action'),
    value: key,
  }));

  const changeColumnsDisplayed = (value: any) => {
    setCheckedList(value as string[]);
  };

  const handleOpenColumnsChange = (newOpen: boolean) => {
    const columnsDisplayed = newColumns.filter(x => !x.hidden).map((item: any) => item.key as string);
    setCheckedList(columnsDisplayed);
    setOpenColumnsDisplayed(newOpen);
  };

  const applyColumnsDisplayed = () => {
    const columnsDisplayed = issuesColumns.map((item: any) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
    setNewColumns(columnsDisplayed);
    setOpenColumnsDisplayed(false);
  };

  const onPagingChange = (page: number, pageSize: number) => {
    dispatch(
      issueActions.getIssuesByTrackerRequest({
        projectId: selectedProject?.id,
        trackerId: trackerId,
        queryParams: { ...queryParams, page, pageSize },
      }),
    );
  };

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      dispatch(issueActions.importFileRequest({ projectId: selectedProject?.id, importType: importType, file }));
      return false;
    },
    fileList,
  };

  const [importType, setImportType] = useState(0);
  const importTypeList = [
    { key: 'new', label: t('new'), value: 0 },
    { key: 'edit', label: t('edit'), value: 1 },
  ];
  const [openImportFileType, setOpenImportFileType] = useState(false);

  const onChangeImportType = (type: any) => {
    setImportType(type.target.value === 'new' ? 0 : 1);
  };

  const importFileRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          {importTypeList.map(item => (
            <Col span={60} key={item.key}>
              <Radio value={item.key} checked={importType === item.value} onClick={onChangeImportType}>
                {item.label}
              </Radio>
            </Col>
          ))}
        </Row>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => setOpenImportFileType(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
            <Button type="primary" onClick={() => setOpenImportFileType(false)}>
              {' '}
              {t('Import')}{' '}
            </Button>
          </Upload>
        </Row>
      </div>
    );
  };

  const [openExportFile, setOpenExportFile] = useState(false);

  // const onChangeExportArea = (type: any) => {
  //     setExportArea(type);
  // };
  // const onChangeExportWorkPackage = (type: any) => {
  //     setExportWorkPackage(type);
  // };

  const exportFileRender = () => {
    return (
      <div style={{ width: 250 }}>
        <Form
          form={exportForm}
          layout="vertical"
          onFinish={onExportFileHandle}
          initialValues={{
            exportArea: -1,
            exportWorkPackage: -1,
            fileName: `Issue (${activeMenu?.label})`,
          }}
        >
          <Row>
            <Form.Item name={'exportArea'}>
              <Select options={areaOptions} style={{ width: 250 }} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={'exportWorkPackage'}>
              <Select options={workPackageOptions} style={{ width: 250 }} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={'fileName'} style={{ width: '100%' }}>
              <Input addonAfter={'.xlsx'} />
            </Form.Item>
          </Row>
          <Divider style={{ margin: '10px 0' }} />
          <Row>
            <Space style={{ flex: 1 }}>
              <Button onClick={() => setOpenExportFile(false)} type="text">
                Cancel
              </Button>
            </Space>
            <Button type="primary" onClick={exportForm.submit}>
              {t('Export')}
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  const onExportFileHandle = (values: any) => {
    const { exportArea, exportWorkPackage, fileName } = values;

    setOpenExportFile(false);
    if (selectedProject && trackerId) {
      const downloadedFileName = fileName ? `${fileName}.xlsx` : 'issues.xlsx';
      dispatch(startLoading({ key: 'ExportingIssue' }));

      IssueService.Get.exportFile(selectedProject?.id, {
        search: { trackerId: trackerId.toString(), areaId: exportArea, workpackageId: exportWorkPackage },
      }).subscribe(
        (issueFile: any) => {
          if (issueFile != null) {
            const url = window.URL.createObjectURL(new Blob([issueFile]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadedFileName);
            document.body.appendChild(link);
            link.click();
            Utils.successNotification(t('Export successfully'));
          }
        },
        err => {
          Utils.errorHandling(err);
          dispatch(stopLoading({ key: 'ExportingIssue' }));
        },
        () => {
          dispatch(stopLoading({ key: 'ExportingIssue' }));
        },
      );
    }
  };

  const checkboxRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            All
          </Checkbox>
        </Row>
        <Checkbox.Group value={checkedList} onChange={changeColumnsDisplayed}>
          <Row>
            {options.map(item => (
              <Col span={24} key={item.value}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => handleOpenColumnsChange(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Button type="primary" onClick={applyColumnsDisplayed}>
            Apply
          </Button>
        </Row>
      </div>
    );
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) => `${range[0]}-${range[1]} of ${total} items`;

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (boolean | Key)[] | null>,
    sorter: CustomSorterResult<IssueResponse> | CustomSorterResult<IssueResponse>[],
    extra: TableCurrentDataSource<IssueResponse>,
  ) => {
    let sortParams;
    if (Array.isArray(sorter)) {
      if (sorter.length && sorter[0]?.columnKey) {
        sortParams = {
          'SortBy.Key': sorter[0]?.column?.sortKey,
          'SortBy.Value': Utils.toCapitalize(sorter[0]?.columnKey.toString()),
          ascending: sorter[0]?.order === 'ascend',
        };
      }
    } else {
      if (sorter.columnKey && sorter?.order) {
        sortParams = {
          'SortBy.Key': sorter?.column?.sortKey,
          'SortBy.Value': Utils.toCapitalize(sorter?.columnKey.toString()),
          ascending: sorter?.order === 'ascend',
        };
      } else {
        sortParams = {
          'SortBy.Key': undefined,
          'SortBy.Value': undefined,
          ascending: undefined,
        };
      }
    }
    const newParams = {
      ...sortParams,
      ...Utils.convertAntFilterToParams(filters),
      page: 1,
    };

    dispatch(
      issueActions.getIssuesByTrackerRequest({
        projectId: selectedProject?.id,
        trackerId: trackerId,
        queryParams: { ...queryParams, ...newParams },
      }),
    );
  };

  return (
    <Spin spinning={isFetching || isFetchingsyncPams || isFetchingasyncPiping || isFetchingasyncRemain || isFetchingasyncTestPackage}>
      <Row style={{ padding: 10 }} gutter={[10, 10]}>
        <Space style={{ flex: 1 }}>
          <Input
            value={searchStr}
            placeholder={t('Search issue')}
            style={{ width: 260 }}
            onChange={onSearchChange}
            allowClear
            suffix={searchStr ? null : <SearchOutlined />}
          />
        </Space>
        <Space wrap>
          {!!tasks?.length && (
            <Popover
              content={checkboxRender()}
              trigger="click"
              placement="bottomLeft"
              arrow={false}
              open={openColumnsDisplayed}
              onOpenChange={handleOpenColumnsChange}
            >
              <Button>
                {checkedList.length} columns selected <DownOutlined />
              </Button>
            </Popover>
          )}
          <Popover
            content={importFileRender()}
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            open={openImportFileType}
            onOpenChange={setOpenImportFileType}
          >
            <Button loading={isImporting} icon={<ImportOutlined />}>
              {t('Import')}
            </Button>
          </Popover>
          <Popover
            content={exportFileRender()}
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            open={openExportFile}
            onOpenChange={setOpenExportFile}
          >
            <Button loading={isExporting} icon={<ExportOutlined />}>
              {t('Export')}
            </Button>
          </Popover>
          {!!selectedRowKeys.length && (
            <>
              <Divider type="vertical" style={{ borderInlineStart: '1px solid rgba(0, 0, 0, 0.16)' }} />
              <Space direction="horizontal">
                <Typography.Text>
                  {`${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''} selected`}
                </Typography.Text>
                <div>
                  <Button danger loading={issuesDeleting} onClick={confirmRemoveIssues}>
                    Delete
                  </Button>
                </div>
              </Space>
            </>
          )}
          {/* <Button icon={<ExportOutlined />} onClick={onExportFileHandle}>{t('Export')}</Button> */}
        </Space>
      </Row>
      <div>
        {view === 'List' && (
          <div style={{ margin: '0 10px' }}>
            <Table
              rowKey={record => record.id}
              // virtual
              dataSource={tasks}
              rowSelection={rowSelection}
              expandable={{
                defaultExpandAllRows: true,
              }}
              columns={!!tasks?.length ? newColumns.filter(x => !x.hidden) : defaultColumns}
              size="small"
              scroll={{ x: 'max-content', y: windowSize[1] - 260 }}
              pagination={false}
              sticky={{ offsetHeader: 39 }}
              onChange={onTableChange}
              tableLayout="auto"
              className={styles.issueTable}
            />
            <div style={{ marginTop: 10, textAlign: 'right' }}>
              <Pagination
                total={issues?.queryCount || 0}
                current={issues?.page || 1}
                pageSize={issues?.pageSize || 20}
                onChange={onPagingChange}
                // showSizeChanger
                responsive
                showTotal={showTotal}
              />
            </div>
          </div>
        )}
        {view === 'Gantt' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Typography.Title level={2}>{t('The feature is developing')}</Typography.Title>
          </div>
        )}
      </div>
    </Spin>
  );
};
