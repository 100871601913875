import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { appActions, getAuthenticated } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getProjectList, getSelectedProject, projectActions } from '@/store/project';
import { getUserOrganizations, getUserPreferences, userActions } from '@/store/user';
import Utils from '@/utils';

interface AuthenticationFlowProps {
  children: React.ReactNode;
}

export const AuthenticationFlow = ({ children }: AuthenticationFlowProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizations = useAppSelector(getUserOrganizations());
  const projectsList = useAppSelector(getProjectList());
  const selectedProject = useAppSelector(getSelectedProject());
  const preferences = useAppSelector(getUserPreferences());
  const auth = useAppSelector(getAuthenticated());
  const location = useLocation();
  let {refresh_token, orgId, projectId} = Utils.getPrameterToken(location.search);
  const from = location.pathname || '/';
  
  useEffect(() => {
    if (orgId == null && refresh_token == null) {
      dispatch(userActions.getOrganizations());
    }
    if (refresh_token && orgId) {
      const loginData = {
        grant_type:'refresh_token',
        refresh_token: refresh_token,
        remember: true,
        orgId: orgId,
      };
      dispatch(appActions.loginRequest({ input: loginData}));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (auth?.user && refresh_token && orgId && selectedProject) {
      if (projectsList.length > 0) {
        var url = `${from}?refresh_token=${refresh_token}&orgId=${orgId}&projectId=${selectedProject.id}`;
        
        navigate(url, { replace: true });
      }
    }
  }, [selectedProject])
  useEffect(() => {
    if (auth?.user && refresh_token && orgId) {
      if (projectsList.length > 0) {
        let selectedProject = projectsList.find(x => x.id == Number(projectId));
        if (selectedProject != null) {
          dispatch(projectActions.setSelectedProject(selectedProject));
        }
      }
    }
  }, [projectsList])
  useEffect(() => {
    if (auth?.user && refresh_token && orgId) {
      dispatch(userActions.getOrganizations());
      dispatch(projectActions.getProjectsRequest({
        search: {page: 1, pageSize: 1000 },
      }));
    }
  },[auth, refresh_token, orgId]);

  useEffect(() => {
    if (!auth?.token || !Utils.isTokenValid(auth?.token)) {
      // nếu token đã hết hạn, token sẽ được tự động renew trong class HttpCLient
      return;
    }
    if (preferences?.defaultOrganization) {
      // nếu đã có organization thì gọi renew để lấy lại token
      const loginData = {
        grant_type: 'refresh_token',
        refresh_token: auth?.refresh_token,
        remember: auth?.remember,
        orgId: preferences?.defaultOrganization,
      };
      dispatch(appActions.loginRequest({ input: loginData }));
    }
    // eslint-disable-next-line
  }, [preferences]);

  useEffect(() => {
    // lấy organization đấu tiên, nếu preferences chưa có
    if (!preferences?.defaultOrganization && organizations.length > 0) {
      const org = organizations[0];
      dispatch(userActions.setUserPreferences({defaultOrganization: org.guid}));
    }
    // eslint-disable-next-line
  }, [organizations, preferences]);

  useEffect(() => {
    if (organizations.length === 0 || !preferences?.defaultOrganization) {
      dispatch(userActions.setDefaultOrganization(undefined));
    } else {
      const defaultOrg = organizations.find(x => x.guid === preferences.defaultOrganization);
      dispatch(userActions.setDefaultOrganization(defaultOrg));
    }
    // eslint-disable-next-line
  }, [organizations, preferences]);

  return <>{children}</>;
};
