import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import { JwtDecoded } from '@/common/define';
import { setToken } from '@/services/HttpClient';

interface AppState {
  language: string;
  auth?: {
    remember?: boolean,
    user?: any,
    token?: string,
    refresh_token?: string,
    roles?: any,
    orgRoles?: any,
    orgId?: string,
    companyId?: number,
  };
  captcha?: any;
  activeMenu?: any;
}

const initialState: AppState = {
  language: 'en',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    ping: (state) => {},
    // prettier-ignore
    loginRequest: (state, action: PayloadAction<{ input: any; callback?: VoidFunction }>) => {},
    loginSuccess: (state, action) => {
      const { loginResponse, loginData } = action.payload;
      const { access_token, refresh_token } = loginResponse;
      const decoded: JwtDecoded = jwtDecode(access_token);
      setToken(access_token);
      const auth = {
        remember: loginData.remember,
        user: JSON.parse(decoded.profile),
        token: access_token,
        refresh_token,
        roles: decoded.role,
        orgRoles: decoded.orgRoles,
        orgId: decoded.orgId,
        companyId: decoded.CompanyId,
      };
      state.auth = auth;
      state.captcha = undefined;
    },
    getCaptcha: (state, action) => {},
    setCaptcha: (state, action) => {
      state.captcha = action.payload;
    },
    logout: (state, action: PayloadAction<{ callback?: VoidFunction }>) => {
      const { callback } = action.payload;
      setToken(null);
      if (callback) {
        callback();
      }
    },
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    }
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
