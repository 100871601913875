import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

const getState = (state: RootState) => state.shift;

export function getShifts() {
  return createSelector([getState], state => state.shifts || []);
}

export function getSelectedShift() {
  return createSelector([getState], state => state.selectedShift);
}