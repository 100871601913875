import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { CreateUpdatePipingWorkload } from './CreateUpdatePipingWorkload';
import { PipingWorkloadHeader } from './PipingWorkloadHeader';
import { PipingWorkloadTable } from './PipingWorkloadTable';
import {
  CreateUpdatePipingWorkloadModalName,
  CreateUpdateTrackingIssueModalName,
  largePagingParams,
} from '@/common/define';
import { getAuthenticated } from '@/store/app';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { workPackageActions } from '@/store/workPackage';
import Utils from '@/utils';

export const PipingWorkload = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const selectedProject = useAppSelector(getSelectedProject());
  const tracker = useAppSelector(getTracker());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdatePipingWorkloadModalName));
  const auth = useAppSelector(getAuthenticated());
  const [showRevision, setShowRevision] = useState(false);
  const toggleShowRevision = () => {
    setShowRevision(prev => !prev);
  };
  const {refresh_token,
    orgId,
  } = Utils.getPrameterToken(location.search);
  useEffect(() => {
    dispatch(issueActions.setIssues([]));
    // eslint-disable-next-line
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  }, []);

  useEffect(() => {
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  },  [auth])

  useEffect(() => {
    const trackerIds = tracker?.results ?? [];
    if (trackerIds.length > 0) {
      var trackerItem = trackerIds.find(t => t.code == 'PipingAreaWorkload');
      dispatch(issueActions.setTrackerId(trackerItem?.id));
      if (selectedProject != null && trackerItem != null) {
        dispatch(issueActions.getAttributesByTrackerRequest({ 
          trackerId: trackerItem.id, 
          queryParams: {
            ...largePagingParams
          }
        }));
        dispatch(
          issueActions.getTargetsByTrackerRequest({
            queryParams: { ...largePagingParams, trackerId: trackerItem.id, projectId: -1 },
          }),
        );
        // dispatch(issueActions.getIssuesByTrackerRequest({ projectId: selectedProject.id, trackerId: trackerItem.id, queryParams }));
      }
    }
  }, [tracker]);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    dispatch(
      areaActions.getAreasRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    dispatch(
      workPackageActions.getWorkPackagesRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <>
      {!refresh_token && <PipingWorkloadHeader showRevision={showRevision} toggleShowRevision={toggleShowRevision} />}
      <PipingWorkloadTable showRevision={showRevision} />
      {isModalOpen && <CreateUpdatePipingWorkload />}
    </>
  );
};
