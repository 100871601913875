import HttpClient from "./HttpClient";
import { RequestOptions } from "./types";
import { getEnvVars } from "@/environment";

const { identityUrl } = getEnvVars();

export interface UserPreferencesReponse {
  clientId: string;
  defaultOrganization: string;
  settings: string;
  userGuid: string;
}

class UserController {
  public Get = {
    getUserByEmail: (email: string, options?: RequestOptions) => {
      return HttpClient.get(`${identityUrl}/license_manager/users/${email}`, options);
    },
    getUserPreferences: (options?: RequestOptions) => {
      return HttpClient.get(`${identityUrl}/license_manager/users/current/preferences/currentClient`, options);
    },
    getOrganizations: (options?: RequestOptions) => {
      return HttpClient.get(`${identityUrl}/license_manager/users/current/organizations`, options);
    }
  };
}

export const UserService = new UserController();