export const shiftList = [
  {
    id: '1',
    name: 'Ca Sáng (TC)',
    startTime: '06:45:00',
    endTime: '11:00:00',
  },
  {
    id: '2',
    name: 'Ca Chiều (TC)',
    startTime: '12:45:00',
    endTime: '17:00:00',
  },
];