import { useEffect, useState } from 'react';

import { ImportOutlined, ExportOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons';
import {
  Row,
  Space,
  Input,
  Upload,
  Button,
  Popover,
  Form,
  UploadFile,
  UploadProps,
  Select,
  Divider,
  Col,
  Radio,
  Typography,
  Modal,
  CheckboxProps,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ExportingStructureBaseSet,
  ImportingStructureBaseSet,
  RemovingMultipleStructureBaseSet,
} from '@/common/define';
import { StructureBaseSetService } from '@/services/StructureBaseSetService';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { getSelectedProject } from '@/store/project';
import { getSelectedRowKeys, getStructureBaseSetQueryParams, structureBaseSetActions } from '@/store/structureBaseSet';
import { getWorkPackages } from '@/store/workPackage';
import Utils from '@/utils';

enum ImportType {
  NEW = 0,
  EDIT = 1,
}
interface StructureBaseSetTableToolbarProps {
  tableColumns: any;
  selectedColumns: any;
  setSelectedColumns: any;
}

export const StructureBaseSetTableToolbar = ({
  tableColumns,
  selectedColumns,
  setSelectedColumns,
}: StructureBaseSetTableToolbarProps) => {
  const { t } = useTranslation('structureBaseSet');
  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const queryParams = useAppSelector(getStructureBaseSetQueryParams());
  const selectedRowKeys = useAppSelector(getSelectedRowKeys());
  const [searchStr, setSearchStr] = useState(queryParams?.search);

  const [importType, setImportType] = useState(ImportType.NEW);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isImporting = useAppSelector(getLoading(ImportingStructureBaseSet));
  const isExporting = useAppSelector(getLoading(ExportingStructureBaseSet));
  const structureBaseSetsDeleting = useAppSelector(getLoading(RemovingMultipleStructureBaseSet));

  const areas = useAppSelector(getAreas());
  const workPackages = useAppSelector(getWorkPackages());

  const [exportForm] = Form.useForm();

  const [checkedColumnsKey, setCheckedColumnsKey] = useState<string[]>(selectedColumns.map((col: any) => col.key));
  const [openColumnsDisplayed, setOpenColumnsDisplayed] = useState(false);

  const [areaOptions, setAreaOptions] = useState<{ label: string; value: number }[]>([]);
  const [workPackageOptions, setWorkPackageOptions] = useState<{ label: string; value: number }[]>([]);
  const [openImportFileType, setOpenImportFileType] = useState(false);
  const [openExportFile, setOpenExportFile] = useState(false);

  const importTypeList = [
    { key: 'new', label: t('New'), value: ImportType.NEW },
    { key: 'edit', label: t('Edit'), value: ImportType.EDIT },
  ];

  useEffect(() => {
    const options = (areas?.results || []).map(area => ({
      label: area.name,
      value: area.id,
    }));
    setAreaOptions([{ label: 'all', value: -1 }].concat(options));
  }, [areas]);

  useEffect(() => {
    const options = (workPackages?.results || []).map(workPackage => ({
      label: workPackage.name,
      value: workPackage.id,
    }));
    setWorkPackageOptions([{ label: 'all', value: -1 }].concat(options));
  }, [workPackages]);

  useEffect(() => {
    setSearchStr(queryParams?.search);
  }, [queryParams]);

  const [timer, setTimer] = useState<any>(null);
  const onSearchChange = (evt: any) => {
    clearTimeout(timer);
    const search = evt.target.value;
    setSearchStr(search);
    const timeoutId = setTimeout(() => {
      dispatch(
        structureBaseSetActions.getStructureBaseSetsRequest({
          projectId: selectedProject?.id,
          queryParams: { ...queryParams, page: 1, search },
        }),
      );
    }, 500);
    setTimer(timeoutId);
  };

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      if (importType === ImportType.NEW) {
        dispatch(structureBaseSetActions.importStructureBaseFileRequest({ projectId: selectedProject?.id, file }));
        return false;
      } else if (importType === ImportType.EDIT) {
        dispatch(structureBaseSetActions.importEditStructureBaseFileRequest({ projectId: selectedProject?.id, file }));
        return false;
      }
    },
    fileList,
  };

  const onChangeImportType = (type: any) => {
    setImportType(type.target.value === 'new' ? 0 : 1);
  };

  const importFileRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          {importTypeList.map(item => (
            <Col span={60} key={item.key}>
              <Radio value={item.key} checked={importType === item.value} onClick={onChangeImportType}>
                {item.label}
              </Radio>
            </Col>
          ))}
        </Row>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => setOpenImportFileType(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
            <Button type="primary"> {t('Import')} </Button>
          </Upload>
        </Row>
      </div>
    );
  };

  const exportFileRender = () => {
    return (
      <div style={{ width: 250 }}>
        <Form
          form={exportForm}
          layout="vertical"
          onFinish={onExportFileHandle}
          initialValues={{
            exportArea: -1,
            exportWorkPackage: -1,
            fileName: `StructureBaseSet`,
          }}
        >
          <Row>
            <Form.Item name={'exportArea'}>
              <Select options={areaOptions} style={{ width: 250 }} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={'exportWorkPackage'}>
              <Select options={workPackageOptions} style={{ width: 250 }} />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={'fileName'} style={{ width: '100%' }}>
              <Input addonAfter={'.xlsx'} />
            </Form.Item>
          </Row>
          <Divider style={{ margin: '10px 0' }} />
          <Row>
            <Space style={{ flex: 1 }}>
              <Button onClick={() => setOpenExportFile(false)} type="text">
                Cancel
              </Button>
            </Space>
            <Button type="primary" onClick={exportForm.submit}>
              {t('Export')}
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  const onExportFileHandle = (values: any) => {
    const { exportArea, exportWorkPackage, fileName } = values;

    setOpenExportFile(false);
    if (selectedProject) {
      const downloadedFileName = fileName ? `${fileName}.xlsx` : 'structureBaseSets.xlsx';
      dispatch(startLoading({ key: ExportingStructureBaseSet }));
      StructureBaseSetService.Get.exportStructureBaseFile(selectedProject?.id, {
        search: { areaId: exportArea, workpackageId: exportWorkPackage },
      }).subscribe(
        (structureBaseSetFile: any) => {
          if (structureBaseSetFile != null) {
            const url = window.URL.createObjectURL(new Blob([structureBaseSetFile]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadedFileName);
            document.body.appendChild(link);
            link.click();
            Utils.successNotification(t('Export successfully'));
          }
        },
        err => {
          Utils.errorHandling(err);
          dispatch(stopLoading({ key: ExportingStructureBaseSet }));
        },
        () => {
          dispatch(stopLoading({ key: ExportingStructureBaseSet }));
        },
      );
    }
  };

  const confirmRemoveMultipleStructureBaseSet = () => {
    if (selectedRowKeys) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: `Are you sure to remove ${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''}`,
            }}
          />
        ),
        closable: true,
        onOk: close => {
          handleRemoveMultipleStructureBaseSet(selectedRowKeys);
          close();
        },
      });
    }
  };

  const handleRemoveMultipleStructureBaseSet = (structureBaseSetIds: React.Key[]) => {
    dispatch(
      structureBaseSetActions.deleteMultipleStructureBaseSetRequest({
        structureBaseSetIds,
        projectId: selectedProject?.id,
      }),
    );
  };

  const checkAll = tableColumns.length === checkedColumnsKey.length;
  const indeterminate = checkedColumnsKey.length > 0 && checkedColumnsKey.length < tableColumns.length;

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    setCheckedColumnsKey(e.target.checked ? tableColumns.map((col: any) => col.key) : []);
  };

  const options = tableColumns.map(({ key, title }: any) => ({
    label: title || t('action'),
    value: key,
  }));

  const changeColumnsDisplayed = (value: any) => {
    setCheckedColumnsKey(value as string[]);
  };

  const handleOpenColumnsChange = (newOpen: boolean) => {
    const columnsDisplayed = selectedColumns.filter((x: any) => !x.hidden).map((item: any) => item.key as string);
    setCheckedColumnsKey(columnsDisplayed);
    setOpenColumnsDisplayed(newOpen);
  };

  const applyColumnsDisplayed = () => {
    const columnsDisplayed = tableColumns.map((item: any) => ({
      ...item,
      hidden: !checkedColumnsKey.includes(item.key as string),
    }));
    setSelectedColumns(columnsDisplayed);
    setOpenColumnsDisplayed(false);
  };

  const checkboxRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            All
          </Checkbox>
        </Row>
        <Checkbox.Group value={checkedColumnsKey} onChange={changeColumnsDisplayed}>
          <Row>
            {options.map((item: any) => (
              <Col span={24} key={item.value}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => handleOpenColumnsChange(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Button type="primary" onClick={applyColumnsDisplayed}>
            Apply
          </Button>
        </Row>
      </div>
    );
  };

  return (
    <Row style={{ padding: 10 }} gutter={[10, 10]}>
      <Space style={{ flex: 1 }}>
        <Input
          value={searchStr}
          placeholder={t('Search')}
          style={{ width: 300 }}
          onChange={onSearchChange}
          allowClear
          suffix={searchStr ? null : <SearchOutlined />}
        />
      </Space>
      <Space wrap>
        <Popover
          content={checkboxRender()}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          open={openColumnsDisplayed}
          onOpenChange={handleOpenColumnsChange}
        >
          <Button>
            {checkedColumnsKey.length} columns selected <DownOutlined />
          </Button>
        </Popover>
        <Popover
          content={importFileRender()}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          open={openImportFileType}
          onOpenChange={setOpenImportFileType}
        >
          <Button loading={isImporting} icon={<ImportOutlined />}>
            {t('Import')}
          </Button>
        </Popover>
        <Popover
          content={exportFileRender()}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          open={openExportFile}
          onOpenChange={setOpenExportFile}
        >
          <Button loading={isExporting} icon={<ExportOutlined />}>
            {t('Export')}
          </Button>
        </Popover>
        {!!selectedRowKeys?.length && (
          <>
            <Divider type="vertical" style={{ borderInlineStart: '1px solid rgba(0, 0, 0, 0.16)' }} />
            <Space direction="horizontal">
              <Typography.Text>
                {`${selectedRowKeys?.length} record${selectedRowKeys?.length > 1 ? 's' : ''} selected`}
              </Typography.Text>
              <div>
                <Button danger loading={structureBaseSetsDeleting} onClick={confirmRemoveMultipleStructureBaseSet}>
                  Delete
                </Button>
              </div>
            </Space>
          </>
        )}
      </Space>
    </Row>
  );
};
