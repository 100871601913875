import { catchError, concat, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { structureBaseSetActions } from './structureBaseSetSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  GettingStructureBaseSetList,
  SavingStructureBaseSet,
  RemovingStructureBaseSet,
  ExportingStructureBaseSet,
  ImportingStructureBaseSet,
  defaultPagingParams,
  CreateUpdateStructureBaseSetModalName,
  RemovingMultipleStructureBaseSet,
  RemovingStructureBaseSetByProject,
  GettingStructureBaseSetColumn,
  GettingStructureBaseSetColumnAggregateValue,
  SavingMultipleStructureBaseSetColumn,
  CreateUpdateMultipleStructureBaseSetModalName,
} from '@/common/define';
import { StructureBaseSetService } from '@/services/StructureBaseSetService';
import Utils from '@/utils';

const getStructureBaseSetsRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(structureBaseSetActions.getStructureBaseSetsRequest.match),
    switchMap(action => {
      const { projectId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingStructureBaseSetList })],
        StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search: queryParams }).pipe(
          mergeMap(structureBaseSets => {
            return [
              structureBaseSetActions.setQueryParams(queryParams),
              structureBaseSetActions.setStructureBaseSets(structureBaseSets),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [structureBaseSetActions.setStructureBaseSets(undefined)];
          }),
        ),
        [stopLoading({ key: GettingStructureBaseSetList })],
      );
    }),
  );
};

const createStructureBaseSetRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.createStructureBaseSetRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { structureBaseSet } = action.payload;
      const { selectedProject } = state.project;
      if (!selectedProject) return [];
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: SavingStructureBaseSet })],
        StructureBaseSetService.Post.createStructureBaseSet(structureBaseSet).pipe(
          switchMap(createdStructureBaseSet => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(selectedProject.id, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Create successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                  hideModal({ key: CreateUpdateStructureBaseSetModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingStructureBaseSet })],
      );
    }),
  );
};

const updateStructureBaseSetRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.updateStructureBaseSetRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { structureBaseSetId, structureBaseSet } = action.payload;
      const { selectedProject } = state.project;
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      const updateData = {
        ...structureBaseSet,
      };
      if (!selectedProject) return [];
      return concat(
        [startLoading({ key: SavingStructureBaseSet })],
        StructureBaseSetService.Put.updateStructureBaseSet(structureBaseSetId, updateData).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(selectedProject.id, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Edit successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                  hideModal({ key: CreateUpdateStructureBaseSetModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(error => {
            console.log(error);
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingStructureBaseSet })],
      );
    }),
  );
};

const importStructureBaseFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.importStructureBaseFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, file } = action.payload;
      const data = new FormData();
      data.append('file', file);
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: ImportingStructureBaseSet })],
        StructureBaseSetService.Post.importStructureBaseFile(projectId, data).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Import successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingStructureBaseSet })],
      );
    }),
  );
};

const importEditStructureBaseFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.importEditStructureBaseFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, file } = action.payload;
      const data = new FormData();
      data.append('file', file);
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: ImportingStructureBaseSet })],
        StructureBaseSetService.Put.importEditStructureBaseFile(projectId, data).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Import successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingStructureBaseSet })],
      );
    }),
  );
};

const exportStructureBaseFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.exportStructureBaseFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: ExportingStructureBaseSet })],
        StructureBaseSetService.Get.exportStructureBaseFile(projectId).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: ExportingStructureBaseSet })],
      );
    }),
  );
};

const deleteStructureBaseSetRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.deleteStructureBaseSetRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { structureBaseSetId, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.structureBaseSet.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingStructureBaseSet })],
        StructureBaseSetService.Delete.deleteStructureBaseSet(structureBaseSetId).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Delete successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingStructureBaseSet })],
      );
    }),
  );
};

const deleteMultipleStructureBaseSetRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.deleteMultipleStructureBaseSetRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { structureBaseSetIds, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.structureBaseSet.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingMultipleStructureBaseSet })],
        StructureBaseSetService.Delete.deleteMultipleStructureBaseSet(structureBaseSetIds).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Delete successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingMultipleStructureBaseSet })],
      );
    }),
  );
};

const resetStructureBaseSetRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.resetStructureBaseSetRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.structureBaseSet.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingStructureBaseSetByProject })],
        StructureBaseSetService.Delete.resetStructureBaseSet(projectId).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(projectId, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Reset successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingStructureBaseSetByProject })],
      );
    }),
  );
};

const getStructureBaseSetColumnRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.getStructureBaseSetColumnRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      // const search = { ...defaultPagingParams, ...state.structureBaseSet.queryParams, page: 1 };
      return concat(
        [startLoading({ key: GettingStructureBaseSetColumn })],
        StructureBaseSetService.Put.getStructureBaseAllValueColumn(projectId).pipe(
          switchMap(columns => {
            return [structureBaseSetActions.setColumns(columns)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: GettingStructureBaseSetColumn })],
      );
    }),
  );
};

const getStructureBaseSetColumnsAggregateValueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.getStructureBaseSetColumnsAggregateValueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { columns, projectId } = action.payload;
      // const search = { ...defaultPagingParams, ...state.structureBaseSet.queryParams, page: 1 };
      return concat(
        [startLoading({ key: GettingStructureBaseSetColumnAggregateValue })],
        StructureBaseSetService.Put.getColumnAggregateValue(columns, projectId).pipe(
          switchMap(value => {
            return [structureBaseSetActions.setColumnsAggregateValue(value)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [structureBaseSetActions.setColumnsAggregateValue(undefined)];
          }),
        ),
        [stopLoading({ key: GettingStructureBaseSetColumnAggregateValue })],
      );
    }),
  );
};

const updateStructuresRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(structureBaseSetActions.updateStructuresRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { columns, input, projectId } = action.payload;
      const { selectedProject } = state.project;
      const search = state.structureBaseSet.queryParams || defaultPagingParams;
      if (!selectedProject) return [];
      return concat(
        [startLoading({ key: SavingMultipleStructureBaseSetColumn })],
        StructureBaseSetService.Put.updateStructrues(projectId, {
          columnVaules: columns,
          structureBaseSetDto: input,
        }).pipe(
          switchMap(() => {
            return StructureBaseSetService.Get.getStructureBaseSetsByProjectId(selectedProject.id, { search }).pipe(
              mergeMap(structureBaseSets => {
                Utils.successNotification('Edit successfully');
                return [
                  structureBaseSetActions.setQueryParams(search),
                  structureBaseSetActions.setStructureBaseSets(structureBaseSets),
                  structureBaseSetActions.setSelectedColumns([]),
                  structureBaseSetActions.setColumnsAggregateValue(undefined),
                  hideModal({ key: CreateUpdateMultipleStructureBaseSetModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [structureBaseSetActions.setStructureBaseSets(undefined)];
              }),
            );
          }),
          catchError(error => {
            console.log(error);
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingMultipleStructureBaseSetColumn })],
      );
    }),
  );
};

export const structureBaseSetEpics = [
  getStructureBaseSetsRequest$,
  createStructureBaseSetRequest$,
  updateStructureBaseSetRequest$,
  deleteStructureBaseSetRequest$,
  exportStructureBaseFileRequest$,
  importStructureBaseFileRequest$,
  importEditStructureBaseFileRequest$,
  deleteMultipleStructureBaseSetRequest$,
  resetStructureBaseSetRequest$,
  getStructureBaseSetColumnRequest$,
  getStructureBaseSetColumnsAggregateValueRequest$,
  updateStructuresRequest$,
];
