import { useEffect, useState } from "react";

import {DownOutlined, UpOutlined, CloseOutlined} from '@ant-design/icons';
import { Button, Flex, Modal, Progress } from "antd";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from './UploadedFilesPopup.module.less';
import { FileStatus, FileStatusConstant } from "@/common/define";
import { getFiles } from "@/store/document";

export const UploadedFilesPopup = (props: {closeDialog?: () => void}) => {
    const { t } = useTranslation(['document']);
    const files = useSelector(getFiles());
    const [data, setData] = useState<FileStatus[]>([]);
    const [title, setTitle] = useState('');
    const [expand, setExpand] = useState(true);
    useEffect(() => {
        let array = files ?? [];
        
        let uploadingCount = array.filter(x => x.status != FileStatusConstant.success).length;
        console.log('files ', array)
        if(uploadingCount > 0){
            setTitle(`${uploadingCount}/${array.length} ${t('uploading')}`);
        }else{
            setTitle(`${array.length} ${t('uploaded')}`);
        }
        setData(array)
    }, [files])
    return (
        // <Modal
        //     open={props.open}
        //     style={{ position: 'fixed', bottom: 0, right: 20, height: '100px', }}
        //     title={title}
        //     closable={false}
        //     maskClosable={false}
        //     mask={false}
        //     closeIcon={false}
        //     maskTransitionName=""
        //     transitionName=""
        //     rootClassName={styles.ModelRoot}
        //     wrapClassName={styles.ModelRoot}
        //     footer=""
        //     >
        //     <div style={{ height: '100px', overflow: 'auto' }}>
        //         {
        //             data.map(x => {
        //                 return <Flex key={x.fileId} 
        //                 style={{flex: 1,  alignItems: 'center', marginTop: '10px', marginBottom: '10px'}}
        //                 >{x.name}
        //                 <div style={{flex: 1}}></div>
        //                 <Progress percent={x.percent} size={[40, 40]} type="circle" />
        //                 </Flex>
        //             })
        //             .concat(data.map(x => {
        //                 return <Flex key={x.fileId} 
        //                 style={{flex: 1,  alignItems: 'center', marginTop: '10px', marginBottom: '10px'}}
        //                 >{x.name}
        //                 <div style={{flex: 1}}></div>
        //                 <Progress percent={x.percent} size={[40, 40]} type="circle" />
        //                 </Flex>
        //             }))
        //         }
               
        //     </div>
        // </Modal>
         <Draggable>
         <div className={styles.popup} style={{ height: expand && data.length > 0? 300 : 55}}>
           <div className={styles.popupheader}>
             {title}
             <div style={{flex: 1}}></div>
             <Button icon={expand ? <DownOutlined /> : <UpOutlined/>} size="small" style={{borderWidth: 0}} onClick={() => setExpand(!expand)}></Button>
             {
                title.includes(t('uploaded')) && <Button icon={<CloseOutlined />} size="small" style={{borderWidth: 0, marginLeft: 10}} onClick={() => props.closeDialog && props.closeDialog()}></Button>
             }
           </div>
           {expand && <div className={styles.inContent}>
                 {
                     data.map(x => {
                        return <Flex key={x.fileId} 
                        style={{flex: 1,  alignItems: 'center', marginTop: '10px', marginBottom: '10px'}}
                        ><p style={{padding: 0, margin: 0, textOverflow: 'ellipsis', width: '70%', overflowWrap: 'break-word'}}>{x.name}</p>
                        <div style={{flex: 1}}></div>
                        {
                            x.status != FileStatusConstant.error?
                            <Progress percent={x.percent} size={[30, 30]} type="circle" />
                            :<Progress percent={x.percent} size={[30, 30]} type="circle" status="exception" />
                        }
                        </Flex>
                    })
                 }
               
             </div>
            }
         </div>
       </Draggable>
    );
}