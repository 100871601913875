import { useEffect } from 'react';

import { CreateUpdateWorkPackageModal } from './CreateUpdateWorkPackageModal';
import { WorkPackagesHeader } from './WorkPackagesHeader';
import { WorkPackagesTable } from './WorkPackagesTable';
import { WorkPackagesToolbar } from './WorkPackagesToolbar';
import { CreateUpdateWorkPackageModalName } from '@/common/define';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackageQueryParams, workPackageActions } from '@/store/workPackage';

export const WorkPackages = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(getWorkPackageQueryParams());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateWorkPackageModalName));
  const selectedProject = useAppSelector(getSelectedProject());

  useEffect(() => {
    if (selectedProject) {
      dispatch(workPackageActions.getWorkPackagesRequest({ params, projectId: selectedProject.id }));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <WorkPackagesHeader />
      <WorkPackagesToolbar />
      <WorkPackagesTable />
      {isModalOpen && <CreateUpdateWorkPackageModal />}
    </>
  );
};
