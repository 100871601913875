import { combineEpics } from 'redux-observable';

import { appEpics } from './app';
import { areaEpics } from './area';
import { documentEpics } from './document';
import { issueEpics } from './issue';
import { linkedDocumentEpics } from './linkedDocument';
import { projectEpics } from './project/projectEpics';
import { structureBaseSetEpics } from './structureBaseSet';
import { timekeepingEpics } from './timekeeping';
import { userEpics } from './user';
import { workPackageEpics } from './workPackage';
import { workpackageProgressEpics } from './workpackageProgress';

const rootEpics = combineEpics(
  ...appEpics,
  ...userEpics,
  ...timekeepingEpics,
  ...issueEpics,
  ...projectEpics,
  ...areaEpics,
  ...workPackageEpics,
  ...documentEpics,
  ...linkedDocumentEpics,
  ...workpackageProgressEpics,
  ...structureBaseSetEpics,
);

export default rootEpics;
