import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { defaultPagingParams } from '@/common/define';

const getState = (state: RootState) => state.workpackageProgress;

export function getIssues() {
  return createSelector([getState], state => state.issues);
}

export function getAttributes() {
  return createSelector([getState], state => state.attributes);
}
export function getTargets() {
  return createSelector([getState], state => state.targets);
}

export function getIssueQueryParams() {
  return createSelector([getState], state => state.queryParams || defaultPagingParams);
}

export function getSelectedIssue() {
  return createSelector([getState], state => state.selectedIssue);
}
