import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface AreasPagingResponse extends PagingResponse {
  results: AreaResponse[];
}

export interface AreaResponse {
  id: number;
  parentId: number;
  name: string;
  code: string;
  description: string;
  projectId: number;
}

class AreaController {
  public Get = {
    getAllAreas: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Area`, options);
    },
    getAreaById: (areaId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Area/${areaId}`, options);
    },
    getAreasByProjectId: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/Area/project/${projectId}`, options);
    },
    exportAreas: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/ExcelAction/exportAreaFile/${projectId}`, {
        ...options,
        ...{ headers: { ...options?.headers, accept: '*/*' }, responseType: 'blob' },
      });
    },
  };

  public Post = {
    createArea: (input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/Area`, input, options);
    },
    importAreas: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/ExcelAction/importArea/${projectId}`, input, options);
    },
  };

  public Put = {
    updateArea: (areaId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/Area/${areaId}`, input, options);
    },
    importEditAreas: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/ExcelAction/editAreaFile/${projectId}`, input, options);
    },
  };

  public delete = {
    removeArea: (areaId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/Area/${areaId}`, options);
    },
    removeAllAreas: (projectId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/Area/deleteAllArea/${projectId}`, options);
    },
  };
}

export const AreaService = new AreaController();
