import React from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '@/hooks';
import Utils from '@/utils';

interface RequireAuthProps {
  children: JSX.Element;
}
export const RequireAuth = ({ children }: RequireAuthProps) => {
  const auth = useAuth();
  const location = useLocation();
  let {refresh_token, orgId} = Utils.getPrameterToken(location.search);
  if (!auth?.user && (!refresh_token || !orgId)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}; 
