import { catchError, concat, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { areaActions } from './areaSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateAreaModalName,
  GettingAreaList,
  ImportingAreas,
  RemovingAllAreas,
  RemovingArea,
  SavingArea,
  defaultPagingParams,
} from '@/common/define';
import { AreaService } from '@/services/AreaService';
import Utils from '@/utils';

const getAreasRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.getAreasRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingAreaList })],
        AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
          mergeMap(areas => {
            return [areaActions.setQueryParams(search), areaActions.setAreas(areas)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [areaActions.setAreas(undefined)];
          }),
        ),
        [stopLoading({ key: GettingAreaList })],
      );
    }),
  );
};

const createAreaRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.createAreaRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { area } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams };
      return concat(
        [startLoading({ key: SavingArea })],
        AreaService.Post.createArea(area).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(area.projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  areaActions.setAreas(empResult),
                  areaActions.setSelectedArea(undefined),
                  hideModal({ key: CreateUpdateAreaModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingArea })],
      );
    }),
  );
};

const updateAreaRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.updateAreaRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { areaId, area } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams };
      return concat(
        [startLoading({ key: SavingArea })],
        AreaService.Put.updateArea(areaId, area).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(area.projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  areaActions.setAreas(empResult),
                  areaActions.setSelectedArea(undefined),
                  hideModal({ key: CreateUpdateAreaModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingArea })],
      );
    }),
  );
};

const removeAreaRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.removeAreaRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { areaId, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingArea })],
        AreaService.delete.removeArea(areaId).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Removed successfully');
                return [
                  areaActions.setAreas(empResult),
                  areaActions.setSelectedArea(undefined),
                  hideModal({ key: CreateUpdateAreaModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingArea })],
      );
    }),
  );
};
const removeAllAreasRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.removeAllAreasRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      const search = { ...defaultPagingParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingAllAreas })],
        AreaService.delete.removeAllAreas(projectId).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Reset successfully');
                return [areaActions.setAreas(empResult), areaActions.setSelectedArea(undefined)];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingAllAreas })],
      );
    }),
  );
};
const importAreasRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.importAreasRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, body } = action.payload;
      const search = {
        ...defaultPagingParams,
        page: 1,
      };
      return concat(
        [startLoading({ key: ImportingAreas })],
        AreaService.Post.importAreas(projectId, body).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Import successfully');
                return [areaActions.setAreas(empResult), areaActions.setSelectedArea(undefined)];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingAreas })],
      );
    }),
  );
};

const importEditAreasRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(areaActions.importEditAreasRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, body } = action.payload;
      const search = {
        ...defaultPagingParams,
        page: 1,
      };
      return concat(
        [startLoading({ key: ImportingAreas })],
        AreaService.Put.importEditAreas(projectId, body).pipe(
          switchMap(() => {
            return AreaService.Get.getAreasByProjectId(projectId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Import successfully');
                return [areaActions.setAreas(empResult), areaActions.setSelectedArea(undefined)];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [areaActions.setAreas(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: ImportingAreas })],
      );
    }),
  );
};
export const areaEpics = [
  getAreasRequest$,
  createAreaRequest$,
  updateAreaRequest$,
  removeAreaRequest$,
  removeAllAreasRequest$,
  importAreasRequest$,
  importEditAreasRequest$,
];
