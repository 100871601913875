import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface StructureBaseSetPagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  results: StructureBaseSetResponse[];
}

export interface StructureBaseSetResponse {
  projectId: number;
  area: string | null;
  item: string | null;
  column: string | null;
  grade: string | null;
  partMark: string | null;
  member: string | null;
  size: string | null;
  length: number;
  weight: number;
  planStart: string | null;
  planEnd: string | null;
  actStart: string | null;
  actEnd: string | null;
  combination: string | null;
  id: number;
}

export interface CreateUpdateStructureBaseSetPayload {
  projectId: number;
  area?: string;
  item?: string;
  column?: string;
  grade?: string;
  partMark?: string;
  member?: string;
  size?: string;
  length?: number;
  weight?: number;
  planStart?: string;
  planEnd?: string;
  actStart?: string;
  actEnd?: string;
}

class StructureBaseSetController {
  public Get = {
    getStructureBaseSetsByProjectId: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/StructureBaseSet/getByProjectId/${projectId}`, options);
    },
    getStructureBaseSetById: (id: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/StructureBaseSet/${id}`, options);
    },
    exportStructureBaseFile: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Pcvm/exportStructureBaseFile/${projectId}`, {
        ...options,
        ...{ headers: { ...options?.headers, accept: '*/*' }, responseType: 'blob' },
      });
    },
  };

  public Post = {
    createStructureBaseSet: (input: CreateUpdateStructureBaseSetPayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/StructureBaseSet`, input, options);
    },
    importStructureBaseFile: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/importStructureBaseFile/${projectId}`, input, options);
    },
  };

  public Put = {
    updateStructureBaseSet: (id: number, input: CreateUpdateStructureBaseSetPayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/StructureBaseSet/${id}`, input, options);
    },
    importEditStructureBaseFile: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/editStructureBaseFile/${projectId}`, input, options);
    },
    getStructureBaseAllValueColumn: (projectId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/StructureBaseSet/getAllValueColumn/${projectId}`, options);
    },
    updateStructrues: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/StructureBaseSet/updateStructures/${projectId}`, input, options);
    },
    getColumnAggregateValue: (body: string[], projectId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/StructureBaseSet/aggregate/${projectId}`, body, options);
    },
  };

  public Delete = {
    deleteStructureBaseSet: (structureBaseSetId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/StructureBaseSet/${structureBaseSetId}`, options);
    },
    deleteMultipleStructureBaseSet: (structureBaseSetIds: number[], options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/StructureBaseSet/deletes`, structureBaseSetIds, options);
    },
    resetStructureBaseSet: (projectId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/StructureBaseSet/deleteAll/${projectId}`, options);
    },
  };
}

export const StructureBaseSetService = new StructureBaseSetController();
