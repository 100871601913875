import React, { useEffect, useState } from 'react';

import { Table } from 'antd';

import { ActivityChartTable, IssueResponse } from '@/services/IssueService';
import { useAppSelector } from '@/store/hooks';
import { getWorkPackages } from '@/store/workPackage';
import { getIssues } from '@/store/workpackageProgress';

export const WorkpackageProgressTable = () => {

  const workPackageReponse = useAppSelector(getWorkPackages());

  const issuesResponse = useAppSelector(getIssues());
  const [dataSource, setDataSource] = useState<ActivityChartTable[]>([])

  // It run when changed issuesResponse data .
  useEffect(() => {
    if (!issuesResponse) return
    const data: ActivityChartTable[] = issuesResponse?.results.map((item: IssueResponse, index: number) => {
      return {
        key: index.toString(),
     
        discipline : workPackageReponse?.results.find(x=>x.id == item.workPackageId)?.discipline,
        setName:item.selectionSetName,
        planStart: item.plannedStartDate,
        planEnd: item.plannedEndDate,
        actStart: item.actualStartDate,
        actEnd : item.actualEndDate,
        planVolume : item.issueTargets.reduce((total , currentItem)=> total + currentItem.planValue, 0),

        
        actVolumne : 100,
        remain :100,
        unit: "",
      }
    });

    data.length > 0 && setDataSource(data)

  }, [issuesResponse])
  
  const tableHeaders = [
    {
      title: 'Discipline',
      dataIndex: 'discipline',
      key: 'discipline',
    },
    {
      title: 'Set Name',
      dataIndex: 'setName',
      key: 'setName',
    },    
    {
      title: 'Plan Start',
      dataIndex: 'planStart',
      key: 'planStart',
    },
    {
      title: 'Plan End',
      dataIndex: 'planEnd',
      key: 'planEnd',
    },
    {
      title: 'Act Start',
      dataIndex: 'actStart',
      key: 'actStart',
    },
    {
      title: 'Act End',
      dataIndex: 'actEnd',
      key: 'actEnd',
    },
    {
      title: 'Plan Volume',
      dataIndex: 'planVolume',
      key: 'planVolume',
    },
    {
      title: 'Act Volumne',
      dataIndex: 'actVolumne',
      key: 'actVolumne',
    },
    {
      title: 'Remain',
      dataIndex: 'remain',
      key: 'remain',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];
  
  return <Table dataSource={dataSource || []} columns={tableHeaders} />;
};
