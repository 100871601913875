import { useEffect, useState } from 'react';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

// import { CreateUpdatePipingWorkloadModalName } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTrackerId, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
// import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

interface PipingAreaWorkloadHeaderProps {
  showRevision: boolean;
  toggleShowRevision: () => void;
}

export const PipingWorkloadHeader = ({ showRevision, toggleShowRevision }: PipingAreaWorkloadHeaderProps) => {
  const [endDayProject, setEndDayProject] = useState(0);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const selectedProject = useAppSelector(getSelectedProject());
  const isTrackerLoading = useAppSelector(getLoading('getTrackerRequest'));
  const trackerId = useAppSelector(getTrackerId());

  useEffect(() => {
    console.log(selectedProject);
    if (selectedProject != null && new Date() < new Date(selectedProject?.endDate)) {
      var endDate = new Date(selectedProject?.endDate);
      console.log(endDate);
      if (endDate < new Date('01-09-1990')) {
        endDate = new Date();
      }
      var leftDays = calculateDifference(new Date(), endDate);
      setEndDayProject(leftDays > 0 ? leftDays : 0);
    } else {
      setEndDayProject(0);
    }
  }, [selectedProject]);

  const calculateDifference = (date1: Date, date2: Date) => {
    const differenceTime = Math.abs(date1.getTime() - date2.getTime());
    const differenceInDays = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };

  // const createIssue = () => {
  //   dispatch(showModal({ key: CreateUpdatePipingWorkloadModalName }));
  // };

  const handleResetTasks = () => {
    if (selectedProject && trackerId) {
      dispatch(
        issueActions.deleteIssuesByProjectRequest({
          projectId: selectedProject.id,
          areaId: -1,
          workPackageId: -1,
          trackerId,
        }),
      );
    }
  };

  return (
    <Row style={{ backgroundColor: 'white', padding: 10 }}>
      <Typography.Title style={{ flex: 1, margin: 0, wordBreak: 'keep-all' }} level={4}>
        {activeMenu?.label}
      </Typography.Title>
      <Space wrap>
        <Typography.Text style={{ fontWeight: '400', fontSize: '14px', marginLeft: '10px', wordBreak: 'keep-all' }}>
          {t('Project left ') + endDayProject + t(' days')}
        </Typography.Text>
        <Button type="primary" onClick={toggleShowRevision}>
          {showRevision ? t('Hide Revision Plan') : t('Show Revision Plan')}
        </Button>
        {/* <Button onClick={createIssue} icon={<PlusOutlined />}>
          {t('New issue')}
        </Button> */}
        {isTrackerLoading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
            spinning={isTrackerLoading}
            style={{ marginLeft: 10 }}
          />
        ) : trackerId ? (
          <Popconfirm title={t('Are you sure to reset all tasks?')} onConfirm={handleResetTasks}>
            <Button danger icon={<DeleteOutlined style={{ color: 'red' }} />}>
              {t('Reset tasks')}
            </Button>
          </Popconfirm>
        ) : (
          <></>
        )}
      </Space>
    </Row>
  );
};
