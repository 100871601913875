import { PlusOutlined, DashOutlined } from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateDocumentModalName } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/modal';
import Utils from '@/utils';

export const DocumentsHeader = () => {
  const { t } = useTranslation('BasedData');
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());

  const createDocument = () => {
    dispatch(showModal({ key: CreateUpdateDocumentModalName }));
  };

  return (
    <Row style={{ padding: 10, backgroundColor: 'white' }}>
      <Space style={{ flex: 1 }}>
        <Typography.Title style={{ margin: 0 }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Space>
      {/* <Space>
        <Button type="primary" icon={<PlusOutlined />} onClick={createDocument}>
          {t('Upload')}
        </Button>
        <Button
          icon={<DashOutlined />}
          onClick={() => {
            Utils.developingNotification();
          }}
        />
      </Space> */}
    </Row>
  );
};
