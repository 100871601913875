import { catchError, concat, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { workpackageProgressActions } from './workpackageProgressSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateTrackingIssueModalName,
  RemovingTrackingIssue,
  RemovingTrackingIssues,
  SavingTrackingIssue,
  defaultPagingParams,
} from '@/common/define';
import { IssueService } from '@/services/IssueService';
import { TargetDimService } from '@/services/TargetDimService';
import Utils from '@/utils';

const getIssuesRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(workpackageProgressActions.getIssuesRequest.match),
    switchMap(action => {
      const { projectId, queryParams, accessToken } = action.payload;
      return concat(
        [startLoading({ key: 'GetIssues' })], 
        IssueService.Get.getIssues(projectId, { search: queryParams, headers: {Authorization: `Bearer ${accessToken}`} }).pipe(
          mergeMap(issues => {
            return [
              workpackageProgressActions.setQueryParams(queryParams),
              workpackageProgressActions.setIssues(issues),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [workpackageProgressActions.setIssues(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetIssues' })],
      );
    }),
  );
};

const getIssuesByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(workpackageProgressActions.getIssuesByTrackerRequest.match),
    switchMap(action => {
      const { projectId, trackerId, queryParams } = action.payload;
      console.log(queryParams);
      return concat(
        [startLoading({ key: 'GetIssuesByTracker' })],
        IssueService.Get.getIssuesByTracker(projectId, trackerId, { search: queryParams }).pipe(
          mergeMap(issues => {
            return [
              workpackageProgressActions.setQueryParams(queryParams),
              workpackageProgressActions.setIssues(issues),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [workpackageProgressActions.setIssues(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetIssuesByTracker' })],
      );
    }),
  );
};

const getAttributesByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(workpackageProgressActions.getAttributesByTrackerRequest.match),
    switchMap(action => {
      const { trackerId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'GetAttributesByTracker' })],
        IssueService.Get.getAttributesByTracker(trackerId, { search: queryParams }).pipe(
          mergeMap(attributes => {
            return [workpackageProgressActions.setAttribute(attributes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [workpackageProgressActions.setAttribute(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetAttributesByTracker' })],
      );
    }),
  );
};

const getTargetsByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(workpackageProgressActions.getTargetsByTrackerRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'GetTargetsByTracker' })],
        TargetDimService.Get.getTargetDimByCondition({ search: queryParams }).pipe(
          mergeMap(targets => {
            return [workpackageProgressActions.setTargets(targets)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [workpackageProgressActions.setAttribute(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetTargetsByTracker' })],
      );
    }),
  );
};

const createIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.createIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issue } = action.payload;
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: SavingTrackingIssue })],
        IssueService.Post.createIssue(issue).pipe(
          switchMap(createdIssue => {
            const issueTargetUpdates: { targetId: number; planValue: string; actualValue: string }[] =
              issue.issueTargetUpdates;
            if (!!issueTargetUpdates?.length && createdIssue) {
              return TargetDimService.Put.addTargetToIssue(
                issueTargetUpdates.map(issueTarget => ({
                  ...issueTarget,
                  issueId: createdIssue.id,
                })),
                createdIssue.id,
              ).pipe(
                switchMap(() => {
                  return IssueService.Get.getIssuesByTracker(issue.projectId, issue.trackerId, { search }).pipe(
                    mergeMap(issues => {
                      Utils.successNotification();
                      return [
                        workpackageProgressActions.setIssues(issues),
                        hideModal({ key: CreateUpdateTrackingIssueModalName }),
                      ];
                    }),
                    catchError(error => {
                      Utils.errorHandling(error);
                      return [workpackageProgressActions.setIssues([])];
                    }),
                  );
                }),
                catchError(error => {
                  Utils.errorHandling(error);
                  return [];
                }),
              );
            }
            return IssueService.Get.getIssuesByTracker(issue.projectId, issue.trackerId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [
                  workpackageProgressActions.setIssues(issues),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [workpackageProgressActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingTrackingIssue })],
      );
    }),
  );
};

const updateIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.updateIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, issue } = action.payload;
      const { selectedProject } = state.project;
      const search = state.issue.queryParams || defaultPagingParams;
      const updateData = {
        ...issue,
        issueTargetUpdates: issue.issueTargetUpdates
          ? issue.issueTargetUpdates.map(
              (issueTarget: { targetId: number; planValue: string; actualValue: string }) => ({
                targetId: issueTarget.targetId,
                value: issueTarget.planValue,
              }),
            )
          : undefined,
      };
      if (!selectedProject) return [];
      return concat(
        [startLoading({ key: SavingTrackingIssue })],
        IssueService.Put.updateIssue(issueId, updateData).pipe(
          switchMap(() => {
            const issueTargetsUpdate: { targetId: number; planValue: string; actualValue: string }[] =
              issue.issueTargetUpdates;

            if (!!issueTargetsUpdate?.length) {
              return TargetDimService.Put.logTargetsToIssue(
                {
                  logIssueTargetDTOs: issueTargetsUpdate.map(issueTarget => ({
                    targetId: issueTarget.targetId,
                    actualValue: issueTarget.actualValue,
                  })),
                },
                issueId,
              ).pipe(
                switchMap(() => {
                  return IssueService.Get.getIssuesByTracker(selectedProject.id, issue.trackerId, { search }).pipe(
                    mergeMap(issues => {
                      Utils.successNotification();
                      return [
                        workpackageProgressActions.setIssues(issues),
                        hideModal({ key: CreateUpdateTrackingIssueModalName }),
                      ];
                    }),
                    catchError(error => {
                      Utils.errorHandling(error);
                      return [workpackageProgressActions.setIssues([])];
                    }),
                  );
                }),
                catchError(error => {
                  Utils.errorHandling(error);
                  return [];
                }),
              );
            }
            return IssueService.Get.getIssuesByTracker(selectedProject.id, issue.trackerId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [
                  workpackageProgressActions.setIssues(issues),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [workpackageProgressActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            console.log(error);
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingTrackingIssue })],
      );
    }),
  );
};

const importFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.importFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, importType, file } = action.payload;
      const data = new FormData();
      data.append('file', file);
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: 'ImportingIssue' })],
        IssueService.Post.importFile(data, { search: { projectId, importType } }).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, state.issue.trackerId ?? -1, { search }).pipe(
              map(issues => {
                Utils.successNotification(importType === 1 ? 'Updated successfully.' : 'Imported successfully.');
                return workpackageProgressActions.setIssues(issues);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [workpackageProgressActions.setIssues([])];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: 'ImportingIssue' })],
      );
    }),
  );
};

const exportFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.exportFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, trackerId, areaId, workpackageId } = action.payload;
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: 'ExportingIssue' })],
        IssueService.Get.exportFile(projectId, { search: { trackerId, areaId, workpackageId } }).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, state.issue.trackerId ?? -1, { search }).pipe(
              map(issues => {
                Utils.successNotification('Export successfully.');
                return workpackageProgressActions.setIssues(issues);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [workpackageProgressActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'ExportingIssue' })],
      );
    }),
  );
};

const deleteIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.deleteIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, projectId, trackerId } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingTrackingIssue })],
        IssueService.Delete.deleteIssue(issueId).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, trackerId, { search }).pipe(
              mergeMap(issuesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  workpackageProgressActions.setIssues(issuesResult),
                  workpackageProgressActions.setSelectedIssue(undefined),
                  workpackageProgressActions.setQueryParams(search),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workpackageProgressActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingTrackingIssue })],
      );
    }),
  );
};

const deleteIssuesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(workpackageProgressActions.deleteIssuesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueIds, projectId, trackerId } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingTrackingIssues })],
        IssueService.Delete.deleteIssues(issueIds).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, trackerId, { search }).pipe(
              mergeMap(issuesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  workpackageProgressActions.setIssues(issuesResult),
                  workpackageProgressActions.setSelectedIssue(undefined),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [workpackageProgressActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingTrackingIssues })],
      );
    }),
  );
};

export const workpackageProgressEpics = [
  getIssuesRequest$,
  getIssuesByTrackerRequest$,
  getAttributesByTrackerRequest$,
  createIssueRequest$,
  updateIssueRequest$,
  importFileRequest$,
  exportFileRequest$,
  deleteIssueRequest$,
  deleteIssuesRequest$,
  getTargetsByTrackerRequest$,
];
