export const PrintHTMLToPDF = (data: any) => {
    var dataHtml = replaceHtml(data, htmlTemplate);
    var newWindows = window.open();
    console.log("print");
    if (newWindows) {
      newWindows.document.write(dataHtml);
      newWindows.document.close();
      newWindows?.print();
      newWindows?.focus();
      newWindows.onafterprint = () => {
        newWindows?.close();
      };
    }
}
const replaceHtml = (data: any, baseHtml: string) => {
    var htmlString = baseHtml;
    const keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var value = data[key];
        htmlString = htmlString.replace(new RegExp(`{{Model.${key}}}`, 'g'), value);
    }
    return htmlString;
}
var htmlTemplate = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    
    <style>
        body {
            font-family: Arial, sans-serif;
        }

        .info-section {
            display: flex;
            justify-content: space-between;
        }

        .info-item {
            display: flex;
            flex: 1;
            padding: 5px;
            align-items: center;
        }

        .container {
            max-width: 800px;
            margin: 0 auto;
            border: 1px solid blue;
        }

        .container1 {
            padding: 10px;
        }

        .project-name {
            flex: 2;
        }
        /* Thêm dòng này để Project Name chiếm đủ không gian */
        .value-box1 {
            border: 0.01px solid blue;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 5px;
            margin-left: 5px;
            width: 100px;
        }

        .info-section > div {
            flex: 1;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 5px;
        }

        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
        }

        th {
            background-color: #f2f2f2;
        }

        .highlight {
            background-color: #ffcccb;
        }

        .center {
            text-align: center;
        }

        .density-table th {
            background-color: #e6e6fa;
        }

        .plan-table th {
            background-color: #e0ffff;
        }

        .settings-table table {
            width: 500px;
        }

        .settings-table td {
            text-align: left;
        }

        .area-info {
            margin-top: 10px;
            border: 1px solid blue;
            padding: 10px;
        }
        @media print { @page { size: A4 landscape; } body { margin: 0; padding: 0; } }
        @media print,screen {
            @page :footer {color: #fff }
            @page :header {color: #fff}
        }
        .diagram {
            padding: 10px;
            display: inline-block;
        }

        .legend {
            margin-top: 0px;
        }

        .color-box {
            display: inline-block;
            width: 40px;
            height: 20px;
            margin-right: 10px;
        }

        .red {
            background-color: red;
        }

        .blue {
            background-color: lightblue;
        }

        .gray {
            background-color: lightgray;
        }

        .origin-row {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin: 10px;
        }

        .note {
            display: flex;
            margin-left: 200px;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            border: 1px solid blue;
            padding-left: 10px;
        }

        .gridBorder {
            border: 1px solid black;
            border-collapse: collapse;
        }

            .gridBorder th, .gridBorder td {
                border: 1px solid black;
                padding: 6px;
                text-align: left;
            }

        .gridBorder1 th, .gridBorder1 td {
            border: 1px solid black;
            padding: 5px;
            text-align: left;
        }

        .gridBorder th {
            background-color: #f2f2f2;
        }

        .imageChart {
            margin-right: 10px;
            flex: 1;
            width: 360px;
            height: 180px;
        }

        .container h2 {
            font-size: 14px;
            margin-left: 10px;
        }

        .container p {
            font-size: 11px;
        }

        .container span {
            font-size: 11px;
        }

        .container strong {
            font-size: 11px;
        }

        .container div {
            font-size: 11px;
        }

        .container table {
            font-size: 11px;
        }

        .container th {
            font-size: 11px;
        }

        .container td {
            font-size: 11px;
        }

        .info-item strong {
            font-size: 14px;
        }

        .info-item span {
            font-size: 14px;
        }
    </style>
</head>
<body>
    <div class="container">
        <div style="display: flex; justify-content: center;">
            <h1 style="font-size: 16px;">Remain Piping Workload</h1>
        </div>
        <div style="border-bottom: 1px solid blue; border-top: 1px solid blue; ">
            <div class="info-section">
                <div class="info-item project-name"><span>Project Name: </span> <span style="margin-left: 5px">{{Model.ProjectName}}</span></div>
                <div class="info-item" style="justify-content: flex-end;"><span>Project left: </span> <span class="value-box1">{{Model.ProjectLeftDay}} day</span></div>
            </div>
            <div class="info-section">
                <div class="info-item"><span>Area: </span> <span style="margin-left: 5px">{{Model.Area}}</span></div>
                <div class="info-item" style="flex: 2; justify-content: flex-end;"><span>Base Date: </span> <span class="value-box1">{{Model.CalculationBaseDate}}</span></div>
            </div>
        </div>
        <h2>Remain Piping Workload</h2>
        <div class="origin-row">
            <table class="settings-table gridBorder1" style="margin-right: 10px; margin-top: 5px;">
                <tr>
                    <th >Material</th>
                    <th >Planned Welding D/I</th>
                    <th >Remain Welding (D/I)</th>
                    <th >Required Daily W Vol (D/I)</th>
                    <th >Average Daily W Vol (D/I)</th>
                    <th >Welder M/P</th>
                    <th>Actual Daily W Vol D/I</th>
                    <th >Actual working day</th>
                    <th >Cost per D/I</th>
                    <th >Status</th>
                    <th >Remain W Cost</th>
                </tr>
                {{Model.RemainPipingWorkload}}
            </table>
        </div>
        <h2>Revision plan</h2>
        <div class="origin-row">
            <table class="settings-table gridBorder1" style="margin-right: 10px; margin-top: 5px;">
                <tr>
                    <th >Material</th>
                    <th >Average Daily W Vol (AS-IS)</th>
                    <th >Welder M/P (AS-IS)</th>
                    <th >Welder M/P (TO BE)</th>
                    <th >Remain Welding (D/I)</th>
                    <th >Required Daily W Vol (D/I)</th>
                    <th >Actual Daily W Vol (D/I)</th>
                    <th >Actual working day</th>
                    <th >Status</th>
                </tr>
                {{Model.RevisionPlan}}
            </table>
        </div>
    </div>
</body>
</html>
`