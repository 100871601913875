import React, { useEffect, useState } from 'react';

import type { UploadProps } from 'antd';
import { Modal, Form, Input, DatePicker, Select, Row, message, Button, Space, Col } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateTrackingIssueModalName,
  DisciplineOptionType,
  SavingTrackingIssue,
  formatDateDisplay,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { AttributeResponse, AttributeValueType, IssueResponse } from '@/services/IssueService';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getAttributes,
  getIssueQueryParams,
  getSelectedIssue,
  getTargets,
  getTrackerId,
  issueActions,
} from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';

export const CreateUpdateTrackingIssue = (props: { code: string }) => {
  const { t } = useTranslation('issue');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateTrackingIssueModalName));

  const selectedIssue: IssueResponse | null | undefined = useAppSelector(getSelectedIssue());
  const [title, setTitle] = useState(selectedIssue ? t('edit') : t('new'));
  const selectedProject = useAppSelector(getSelectedProject());
  const trackerId = useAppSelector(getTrackerId());
  const isSaving = useAppSelector(getLoading(SavingTrackingIssue));

  const [form] = Form.useForm();

  const attributes = useAppSelector(getAttributes());
  const targets = useAppSelector(getTargets());
  const areas = useAppSelector(getAreas());
  const workPackages = useAppSelector(getWorkPackages());
  const queryParams = useAppSelector(getIssueQueryParams());
  const [additionAttributes, setAdditionAttributes] = useState<AttributeResponse[]>([]);

  const areaOptions = (areas?.results || []).map(area => ({
    label: area.name,
    value: area.id,
  }));

  const workPackageOptions = (workPackages?.results || [])
    .filter(x => x.discipline == DisciplineOptionType[props.code])
    .map(workPackage => ({
      label: workPackage.name,
      value: workPackage.id,
    }));

  useEffect(() => {
    const attrList = attributes?.results || [];
    setAdditionAttributes(attrList);
  }, [attributes]);
  const [initialValues, setInitialValues] = useState<any>(null);
  useEffect(() => {
    setTitle(selectedIssue ? t('edit') : t('new'));
    var initData: any = {
      ...selectedIssue,
      plannedStartDate: selectedIssue?.plannedStartDate ? dayjs.utc(selectedIssue?.plannedStartDate) : null,
      plannedEndDate: selectedIssue?.plannedEndDate ? dayjs.utc(selectedIssue?.plannedEndDate) : null,
      actualStartDate: selectedIssue?.actualStartDate ? dayjs.utc(selectedIssue?.actualStartDate) : null,
      actualEndDate: selectedIssue?.actualEndDate ? dayjs.utc(selectedIssue?.actualEndDate) : null,
    };
    if (selectedIssue) {
      for (let att of selectedIssue?.attributes || []) {
        if (att.valueType === AttributeValueType.Date) {
          initData[att.code] = att.dateTimeValue ? dayjs.utc(att.dateTimeValue) : null;
        } else {
          initData[att.code] = att.value;
        }
      }
      for (let target of selectedIssue?.issueTargets || []) {
        initData[target.targetDim.code] = target;
      }
      console.log(initData);
    }
    setInitialValues(initData);
  }, [selectedIssue]);
  const handleSaveIssue = (values: any) => {
    const tzOffset = new Date().getTimezoneOffset();
    if (selectedProject) {
      const inputData = {
        ...values,
        description: values.description || selectedIssue?.description || '',
        projectId: selectedProject.id,
        trackerId,
        areaId: (workPackages?.results || []).find(x => x.id == values.workPackageId)?.areaId,
        plannedStartDate: values?.plannedStartDate ? values?.plannedStartDate.subtract(tzOffset, 'minutes').toISOString() : null,
        plannedEndDate: values?.plannedEndDate ? values?.plannedEndDate.subtract(tzOffset, 'minutes').toISOString() : null,
        actualStartDate: values?.actualStartDate ? values?.actualStartDate.subtract(tzOffset, 'minutes').toISOString() : null,
        actualEndDate: values?.actualEndDate ? values?.actualEndDate.subtract(tzOffset, 'minutes').toISOString() : null,
        ...{
          issueAttributes: additionAttributes.map((attr: AttributeResponse) => {
            if (attr.valueType === AttributeValueType.Date) {
              return {
                attributeId: attr.id,
                valueType: attr.valueType,
                dateTimeValue: values[attr.code]
                  ? values[attr.code].subtract(tzOffset, 'minutes').toISOString()
                  : undefined,
              };
            } else {
              return {
                attributeId: attr.id,
                valueType: attr.valueType,
                value: values[attr.code],
              };
            }
          }),
        },
        issueTargetUpdates: (targets?.results || []).map(target => ({
          targetId: target.id,
          planValue: values[target.code].planValue,
          actualValue: values[target.code].actualValue,
        })),
      };
      console.log(inputData);
      if (selectedIssue) {
        dispatch(
          issueActions.updateIssueRequest({ issueId: selectedIssue.id, issue: { ...selectedIssue, ...inputData } }),
        );
      } else {
        dispatch(issueActions.createIssueRequest({ issue: inputData }));
      }
      // dispatch(hideModal({ key: CreateUpdateTrackingIssueModalName }));
    }
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedIssue(undefined));
    dispatch(hideModal({ key: CreateUpdateTrackingIssueModalName }));
  };

  const handleOk = () => form.submit();

  // const haveAdditional = additionAttributes.length > 0;
  const confirmRemoveIssue = () => {
    if (selectedIssue) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('confirmRemove', {
                name: `<strong>"${selectedIssue.subject}"</strong>`,
              }),
            }}
          />
        ),
        closable: true,
        onOk: close => {
          dispatch(
            issueActions.deleteIssueRequest({
              issueId: selectedIssue.id,
              projectId: selectedProject?.id,
              trackerId: trackerId,
            }),
          );
          dispatch(issueActions.setSelectedIssue(undefined));
          close();
        },
      });
    }
  };
  return (
    <DraggableModal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('save')}
      confirmLoading={isSaving}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedIssue ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button
                key="remove"
                type="primary"
                danger
                onClick={() => {
                  confirmRemoveIssue();
                  dispatch(hideModal({ key: CreateUpdateTrackingIssueModalName }));
                }}
              >
                {t('remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      {initialValues && (
        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSaveIssue}>
          <Form.Item label={t('Name')} name="subject" rules={[{ required: true, message: t('Please input name!') }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Description')}
            // rules={[{ required: true, message: t('requiredField') }]}
            name="description"
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Row gutter={[10, 10]}>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('actualStartDate')}
                name="actualStartDate"
                // rules={[{ required: true, message: t('requiredField') }]}
              >
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label={t('actualEndDate')} name="actualEndDate">
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col sm={24} md={12}>
              <Form.Item label={t('plannedStartDate')} name="plannedStartDate">
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label={t('plannedEndDate')} name="plannedEndDate">
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            {additionAttributes.map(input => (
              <Col sm={24} md={12}>
                <Form.Item
                  label={t(input.name)}
                  name={input.code}
                  rules={[{ required: input.code === 'SelectionSetName', message: t('requiredField') }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}
          </Row>
          {!!targets?.results?.length && (
            <Row gutter={[10, 5]}>
              {targets.results.map(target => (
                <>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={target.name || t(target.code + 'Plan')}
                      name={[target.code, 'planValue']}
                      rules={[{ required: true, message: t('requiredField') }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={target.actualDisplayName || t(target.code + 'Actual')}
                      name={[target.code, 'actualValue']}
                    >
                      <Input disabled={!selectedIssue} />
                    </Form.Item>
                  </Col>
                </>
              ))}
            </Row>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            {/* <Form.Item
            label={t('area')}
            name="area"
            rules={[{ required: true, message: t('requiredArea') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Select options={areaOptions} defaultValue={selectedIssue && selectedIssue.areaId? selectedIssue.areaId : ''} />
          </Form.Item> */}
            <Form.Item
              label={t('workPackage')}
              name="workPackageId"
              rules={[{ required: true, message: t('requiredWorkPackage') }]}
              style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
            >
              <Select options={workPackageOptions} />
            </Form.Item>
          </Form.Item>
        </Form>
      )}
    </DraggableModal>
  );
};
