import React, { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateMultipleStructureBaseSetModalName,
  SavingStructureBaseSet,
  invalidDateStrings,
  formatDateDisplay,
  GettingStructureBaseSetColumn,
  GettingStructureBaseSetColumnAggregateValue,
  SavingMultipleStructureBaseSetColumn,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import {
  getColumnAggregateValue,
  getColumns,
  getSelectedColumns,
  structureBaseSetActions,
} from '@/store/structureBaseSet';

export const EditMultipleStructureBaseSetModal = () => {
  const { t } = useTranslation('structureBaseSet');
  const [searchStr, setSearchStr] = useState();
  const [timer, setTimer] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [checkedColumns, setCheckedColumns] = useState<string[]>([]);

  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateMultipleStructureBaseSetModalName));
  const isGettingColumns = useAppSelector(getLoading(GettingStructureBaseSetColumn));
  const isGettingAggregateValue = useAppSelector(getLoading(GettingStructureBaseSetColumnAggregateValue));
  const isSaving = useAppSelector(getLoading(SavingMultipleStructureBaseSetColumn));

  const selectedProject = useAppSelector(getSelectedProject());
  const columns = useAppSelector(getColumns());
  const selectedColumns = useAppSelector(getSelectedColumns());
  const columnAggregateValue = useAppSelector(getColumnAggregateValue());

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedProject) {
      dispatch(structureBaseSetActions.getStructureBaseSetColumnRequest({ projectId: selectedProject.id }));
    }
  }, [selectedProject]);

  useEffect(() => {
    setCheckedColumns(selectedColumns || []);
  }, [selectedColumns]);

  useEffect(() => {
    if (columnAggregateValue) {
      form.setFieldsValue({
        ...columnAggregateValue,
        planStart:
          columnAggregateValue.planStart && !invalidDateStrings.includes(columnAggregateValue.planStart)
            ? dayjs(columnAggregateValue.planStart + 'Z')
            : undefined,
        planEnd:
          columnAggregateValue.planEnd && !invalidDateStrings.includes(columnAggregateValue.planEnd)
            ? dayjs(columnAggregateValue.planEnd + 'Z')
            : undefined,
        actStart:
          columnAggregateValue.actStart && !invalidDateStrings.includes(columnAggregateValue.actStart)
            ? dayjs(columnAggregateValue.actStart + 'Z')
            : undefined,
        actEnd:
          columnAggregateValue.actEnd && !invalidDateStrings.includes(columnAggregateValue.actEnd)
            ? dayjs(columnAggregateValue.actEnd + 'Z')
            : undefined,
      });
    } else {
      form.resetFields();
    }
  }, [columnAggregateValue]);

  const onSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {}, 500);
    setTimer(timeoutId);
  };

  const handleSaveStructureBaseSet = (values: any) => {
    if (!selectedColumns?.length) {
      notification.info({
        message: 'Please apply at least 1 column',
      });
      return;
    }
    if (selectedProject) {
      let prevAggregateValue: any = {};
      const inputData = {
        projectId: selectedProject.id,
        ...values,
      };
      for (const [key, value] of Object.entries(columnAggregateValue)) {
        prevAggregateValue[key] = null; // reset value to null
      }
      dispatch(
        structureBaseSetActions.updateStructuresRequest({
          input: {
            ...prevAggregateValue,
            ...inputData,
            id: undefined,
          },
          columns: selectedColumns,
          projectId: selectedProject.id,
        }),
      );
    }
  };

  const handleCancel = () => {
    dispatch(structureBaseSetActions.setSelectedColumns([]));
    dispatch(structureBaseSetActions.setColumnsAggregateValue(undefined));
    dispatch(hideModal({ key: CreateUpdateMultipleStructureBaseSetModalName }));
  };

  const handleOk = () => form.submit();

  const handleCheckColumn = (column: string, checked: boolean) => {
    if (checked) {
      if (checkedColumns.includes(column)) return;
      setCheckedColumns(prev => [...prev, column]);
    } else {
      if (!checkedColumns.includes(column)) return;
      setCheckedColumns(prev => prev.filter(prevColumn => prevColumn !== column));
    }
  };

  const handleCheckAllColumns = (checked: boolean) => {
    if (checked) {
      setCheckedColumns(columns || []);
    } else {
      setCheckedColumns([]);
    }
  };

  const handleApplyCheckedColumns = () => {
    if (selectedProject) {
      dispatch(structureBaseSetActions.setSelectedColumns(checkedColumns));
      dispatch(
        structureBaseSetActions.getStructureBaseSetColumnsAggregateValueRequest({
          columns: checkedColumns || [],
          projectId: selectedProject.id,
        }),
      );
    }
  };

  return (
    <DraggableModal
      width={600}
      styles={{
        content: {
          maxHeight: 604,
        },
      }}
      okText={t('save')}
      confirmLoading={isSaving}
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      centered
      destroyOnClose
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedColumns?.length ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Typography.Text>{`${selectedColumns?.length} columns applied`}</Typography.Text>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Row gutter={[16, 10]}>
        <Col span={24} sm={10}>
          <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <Typography.Text>Columns</Typography.Text>
            <Input
              value={searchStr}
              onChange={onSearchChange}
              allowClear
              placeholder={t('Search')}
              suffix={searchStr ? null : <SearchOutlined />}
              style={{ marginBlock: 4 }}
            />
            <div style={{ flex: 1, minHeight: 1 }}>
              <Spin spinning={isGettingColumns}>
                {!!columns?.length && (
                  <>
                    <Checkbox
                      checked={checkedColumns.length === columns?.length}
                      onChange={e => handleCheckAllColumns(e.target.checked)}
                      indeterminate={checkedColumns.length > 0 && checkedColumns.length < columns?.length}
                      style={{ marginBottom: 4, marginLeft: 10 }}
                    >
                      Check all
                    </Checkbox>
                    <div
                      style={{
                        width: '100%',
                        border: '1px solid #0000003d',
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className="custom_scrollbar"
                        style={{
                          overflowY: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 5,
                          maxHeight: '381px',
                          minHeight: '381px',
                          // maxHeight: 'calc(100vh - 360px)',
                          // minHeight: 'calc(100vh - 360px)',
                          width: 'calc(100% - 10px)',
                          padding: '2px 0px 2px 10px',
                        }}
                      >
                        {columns
                          ?.filter(column => column?.includes(searchStr || ''))
                          .map(column => (
                            <Checkbox
                              key={column}
                              checked={checkedColumns?.includes(column)}
                              onChange={e => handleCheckColumn(column, e.target.checked)}
                            >
                              {column}
                            </Checkbox>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </Spin>
            </div>
            {!!columns?.length && (
              <div style={{ marginBottom: 8, display: 'flex', justifyContent: 'flex-end' }}>
                <Button loading={isGettingAggregateValue} onClick={handleApplyCheckedColumns}>
                  Apply
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col span={24} sm={14}>
          <Form form={form} layout="vertical" onFinish={handleSaveStructureBaseSet}>
            <Spin spinning={isGettingAggregateValue}>
              <Form.Item label={t('area')} name="area">
                <Input />
              </Form.Item>
              <Row gutter={[10, 10]}>
                <Col sm={24} md={12}>
                  <Form.Item label={t('item')} name="item">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label={t('column')} name="column">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t('grade')} name="grade">
                <Input />
              </Form.Item>
              <Row gutter={[10, 10]}>
                <Col sm={24} md={12}>
                  <Form.Item label={t('partMark')} name="partMark">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label={t('member')} name="member">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t('size')} name="size">
                <Input />
              </Form.Item>
              <Row gutter={[10, 10]}>
                <Col sm={24} md={12}>
                  <Form.Item label={t('length(m)')} name="length">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label={t('weight(kg)')} name="weight">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col sm={24} md={12}>
                  <Form.Item label={t('planStart')} name="planStart">
                    <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label={t('planEnd')} name="planEnd">
                    <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col sm={24} md={12}>
                  <Form.Item label={t('actStart')} name="actStart">
                    <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label={t('actEnd')} name="actEnd">
                    <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </Form>
        </Col>
      </Row>
    </DraggableModal>
  );
};
