import { createSelector } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { RootState } from '@/store/types';

export const getDocumentState = (state: RootState) => state.document;

export function getSelectedDocument() {
  return createSelector([getDocumentState], state => state.selectedDocument);
}

export function getDocuments() {
  return createSelector([getDocumentState], state => state.documents);
}
export function getDocumentPath() {
  return createSelector([getDocumentState], state => state.documentPath);
}

export function getDocumentQueryParams() {
  return createSelector([getDocumentState], state => state.queryParams || defaultPagingParams);
}
export function getFolderRootId() {
  return createSelector([getDocumentState], state => state.folderRootId);
}
export function getSelectedRowKeys() {
  return createSelector([getDocumentState], state => state.selectedRowKeys);
}
export function getFiles() {
  return createSelector([getDocumentState], state => state.files);
}
