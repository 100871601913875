import { AreaResponse } from './AreaService';
import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { DisciplineOption, PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface WorkPackagesPagingResponse extends PagingResponse {
  results: WorkPackageResponse[];
}

export interface WorkPackageResponse {
  id: number;
  parentId: number;
  name: string;
  code: string;
  description: string;
  projectId: number;
  discipline: DisciplineOption;
  areaId: number;
  area: AreaResponse;
  disciplineName: string;
}
export interface CreateWorkPackagePayload {
  parentId?: number;
  name: string;
  code?: string;
  description?: string;
  projectId: number;
  discipline: DisciplineOption;
  areaId: number;
}
export interface UpdateWorkPackagePayload {
  parentId?: number;
  name: string;
  code?: string;
  description?: string;
  discipline: DisciplineOption;
  areaId: number;
}

class WorkPackageController {
  public Get = {
    getAllWorkPackages: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/WorkPackage`, options);
    },
    getWorkPackageById: (workPackageId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/WorkPackage/${workPackageId}`, options);
    },
    getWorkPackagesByProjectId: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/WorkPackage/project/${projectId}`, options);
    },
    exportWorkPackages: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/ExcelAction/exportWorkPackagesFile/${projectId}`, {
        ...options,
        ...{ headers: { ...options?.headers, accept: '*/*' }, responseType: 'blob' },
      });
    },
  };

  public Post = {
    createWorkPackage: (input: CreateWorkPackagePayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/WorkPackage`, input, options);
    },
    importWorkPackages: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/ExcelAction/importWorkPackages/${projectId}`, input, options);
    },
  };

  public Put = {
    updateWorkPackage: (workPackageId: number, input: UpdateWorkPackagePayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/WorkPackage/${workPackageId}`, input, options);
    },
    importEditWorkPackages: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/ExcelAction/editWorkPackagesFile/${projectId}`, input, options);
    },
  };

  public delete = {
    removeWorkPackage: (workPackageId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/WorkPackage/${workPackageId}`, options);
    },
    removeAllWorkPackages: (projectId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/WorkPackage/deleteAllWPK/${projectId}`, options);
    },
  };
}

export const WorkPackageService = new WorkPackageController();
