import { createSelector } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { RootState } from '@/store/types';

export const getWorkPackageState = (state: RootState) => state.workPackage;

export function getSelectedWorkPackage() {
  return createSelector([getWorkPackageState], state => state.selectedWorkPackage);
}

export function getWorkPackages() {
  return createSelector([getWorkPackageState], state => state.workPackages);
}

export function getAreas() {
  return createSelector([getWorkPackageState], state => state.areas);
}

export function getWorkPackageQueryParams() {
  return createSelector([getWorkPackageState], state => state.queryParams || defaultPagingParams);
}
