import { useEffect } from 'react';

import { CreateUpdateStructureBaseSet } from './CreateUpdateStructureBaseSet';
import { EditMultipleStructureBaseSetModal } from './EditMultipleStructureBaseSetModal';
import { StructureBaseSetHeader } from './StructureBaseSetHeader';
import { StructureBaseSetTable } from './StructureBaseSetTable';
import {
  CreateUpdateMultipleStructureBaseSetModalName,
  CreateUpdateStructureBaseSetModalName,
  defaultPagingParams,
  largePagingParams,
} from '@/common/define';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { structureBaseSetActions } from '@/store/structureBaseSet';
import { workPackageActions } from '@/store/workPackage';

export const StructureBaseSet = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateStructureBaseSetModalName));
  const isMultilpleBaseSetModalOpen = useAppSelector(getModalVisible(CreateUpdateMultipleStructureBaseSetModalName));
  const selectedProject = useAppSelector(getSelectedProject());

  useEffect(() => {
    // eslint-disable-next-line
    if (selectedProject) {
      dispatch(
        structureBaseSetActions.getStructureBaseSetsRequest({
          projectId: selectedProject.id,
          queryParams: defaultPagingParams,
        }),
      );
      dispatch(
        areaActions.getAreasRequest({
          params: { ...largePagingParams },
          projectId: selectedProject.id,
        }),
      );
      dispatch(
        workPackageActions.getWorkPackagesRequest({
          params: { ...largePagingParams },
          projectId: selectedProject.id,
        }),
      );
    }
  }, [selectedProject]);

  return (
    <>
      <StructureBaseSetHeader />
      <StructureBaseSetTable />
      {isModalOpen && <CreateUpdateStructureBaseSet />}
      {isMultilpleBaseSetModalOpen && <EditMultipleStructureBaseSetModal />}
    </>
  );
};
