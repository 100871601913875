import area from './area.json';
import bidding from './bidding.json';
import checkin from './checkin.json';
import common from './common.json';
import document from './document.json';
import layout from './layout.json';
import login from './login.json';
import projects from './projects.json';
import shift from './shift.json';
import welcome from './welcome.json';
import workPackage from './workPackage.json';

// eslint-disable-next-line
export default {
  bidding,
  common,
  welcome,
  login,
  layout,
  projects,
  checkin,
  shift,
  area,
  workPackage,
  document,
};
