import { Button, Col, Divider, Form, Popconfirm, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RemovingTrackingIssuesByProject } from '@/common/define';
import { InvestorInfo, ProjectInfo } from '@/pages/CreateProjectPage/components/GeneralInformation/components';
import { CreateProjectData } from '@/services/ProjectService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getProjectAvatar, getSelectedProject, projectActions } from '@/store/project';
import { getDefaultOrganization } from '@/store/user';

export const GeneralInformation = () => {
  const { t } = useTranslation(['projects']);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const createProjectInformationValue = useAppSelector(getCreateProjectInformationValue());
  const selectedProject = useAppSelector(getSelectedProject());
  const projectAvatar = useAppSelector(getProjectAvatar());
  const defaultOrg = useAppSelector(getDefaultOrganization());
  const isSaving = useAppSelector(getLoading('saveProject'));
  const isReseting = useAppSelector(getLoading(RemovingTrackingIssuesByProject));

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleSubmit = (values: any) => {
    if (selectedProject) {
      const data: CreateProjectData = {
        ...selectedProject,
        ...values,
        companyId: defaultOrg?.companyId,
        startDate: values.startDate?.format('YYYY-MM-DD'),
        endDate: values.endDate?.format('YYYY-MM-DD'),
        avatar: projectAvatar,
      };
      dispatch(
        projectActions.saveProjectRequest({
          projectId: selectedProject.id,
          input: data,
        }),
      );
    }
  };

  const handleResetTasks = () => {
    if (selectedProject) {
      dispatch(
        issueActions.deleteIssuesByProjectRequest({
          projectId: selectedProject.id,
          areaId: -1,
          workPackageId: -1,
          trackerId: -1,
        }),
      );
    }
  };

  const AdditionComponent = () => {
    return (
      <Col span={24}>
        {/* <div className={styles.footerContainer}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.buttonWithIcon}>
              {t('createProject.next')}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </div> */}
      </Col>
    );
  };

  const deleteTask = () => {
    if (selectedProject) {
      dispatch(
        projectActions.deleteProjectRequest({
          projectId: selectedProject.id,
        }),
      );
      navigate('/');
    }
  };

  return (
    <>
      <Space style={{ width: '100%', gap: 10, alignItems: 'center', paddingInline: 30 }}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('Reset tasks? ')}
        </Typography.Title>
        <Popconfirm title={t('Are you sure to reset all tasks?')} onConfirm={handleResetTasks}>
          <Button danger loading={isReseting}>
            {t('Reset')}
          </Button>
        </Popconfirm>
      </Space>
      <Divider style={{ paddingInline: 30 }} />
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          ...selectedProject,
          startDate: selectedProject ? dayjs(selectedProject.startDate) : null,
          endDate: selectedProject ? dayjs(selectedProject.endDate) : null,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={12}>
            <ProjectInfo />
          </Col>
          <Col span={24} lg={24} xl={12}>
            <InvestorInfo AdditionComponent={<AdditionComponent />} />
          </Col>
          <Col span={24}>
            <div style={{ paddingInline: 30 }}>
              <Button loading={isSaving} type="primary" onClick={form.submit}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div style={{ paddingInline: 30 }}>
        <Divider />
        <Popconfirm title={t('Are you sure to delete project?')} onConfirm={deleteTask}>
          <Button danger loading={isReseting}>
            {t('Delete')}
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};
