import { useEffect } from 'react';

import { IssueHeader } from './IssueHeader';
import { CreateUpdateTestPackageIssue } from './TestPackage/CreateUpdateTestPackageIssue';
import { TestPackageTable } from './TestPackage/TestPackageTable';
import { CreateUpdateTrackingIssueModalName, largePagingParams, defaultPagingParams } from '@/common/define';
import { getAuthenticated } from '@/store/app';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { workPackageActions } from '@/store/workPackage';

export const IssueTestPackageS = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateTrackingIssueModalName));
  const selectedProject = useAppSelector(getSelectedProject());
  const tracker = useAppSelector(getTracker());
  const auth = useAppSelector(getAuthenticated());
  const code = 'PipingTestPackage';
  useEffect(() => {
    dispatch(issueActions.setIssues([]));
    // eslint-disable-next-line
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  }, []);

  useEffect(() => {
    const trackerIds = tracker?.results ?? [];
    if (trackerIds.length > 0) {
      var trackerItem = trackerIds.find(t => t.code == code);
      dispatch(issueActions.setTrackerId(trackerItem?.id));
      if (selectedProject != null && trackerItem != null) {
        dispatch(
          issueActions.getAttributesByTrackerRequest({
            trackerId: trackerItem.id,
            queryParams: {
              ...largePagingParams,
            },
          }),
        );
        dispatch(
          issueActions.getIssuesByTrackerRequest({
            projectId: selectedProject.id,
            trackerId: trackerItem.id,
            queryParams: defaultPagingParams,
          }),
        );
        dispatch(
          issueActions.getTargetsByTrackerRequest({
            queryParams: { ...largePagingParams, trackerId: trackerItem.id, projectId: -1 },
          }),
        );
      }
    }
  }, [tracker]);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    dispatch(
      areaActions.getAreasRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    dispatch(
      workPackageActions.getWorkPackagesRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    // eslint-disable-next-line
  }, [selectedProject]);
  return (
    <>
      {isModalOpen && <CreateUpdateTestPackageIssue code={code}></CreateUpdateTestPackageIssue>}
      <IssueHeader />
      <TestPackageTable code={code} />
    </>
  );
};
