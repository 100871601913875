import { useEffect } from 'react';

import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { WorkpackageProgressTable, WorkpackageProgressChart, WorkpackageProgressToolbar } from '.';
import { defaultPagingParams, largePagingParams } from '@/common/define';
import { areaActions } from '@/store/area';
import { useAppDispatch } from '@/store/hooks';
import { workPackageActions } from '@/store/workPackage';
import { workpackageProgressActions } from '@/store/workpackageProgress';

let projectId = 3; // FOR DEV ONLY
export const WorkpackageProgressPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  useEffect(() => {
    dispatch(
      workpackageProgressActions.getIssuesRequest({
        projectId,
        queryParams: {...defaultPagingParams , page:391},
        accessToken,
      }),
    );
    dispatch(
      areaActions.getAreasRequest({
        params: { ...largePagingParams },
        projectId,
      }),
    );
    dispatch(
      workPackageActions.getWorkPackagesRequest({
        params: { ...largePagingParams },
        projectId,
      }),
    );
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Typography.Title style={{ marginLeft: 20, marginTop: 10 }} level={4}>
        {t('Work Package Progress')}
      </Typography.Title>
      <div style={{ padding: 10 }}>
        <WorkpackageProgressToolbar />
        <WorkpackageProgressChart />
        <Divider />
        <WorkpackageProgressTable />
      </div>
    </>
  );
};
