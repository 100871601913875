import { useEffect } from 'react';

import { CreateUpdateDocumentModal } from './CreateUpdateDocumentModal';
import { DocumentsHeader } from './DocumentsHeader';
import { DocumentsTable } from './DocumentsTable';
import { DocumentsToolbar } from './DocumentsToolbar';
import { CreateUpdateDocumentModalName, defaultPagingParams } from '@/common/define';
import { documentActions, getFolderRootId, getDocumentPath } from '@/store/document';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const Documents = () => {
  const dispatch = useAppDispatch();
  const folderRootId = useAppSelector(getFolderRootId());
  const documentPath = useAppSelector(getDocumentPath());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateDocumentModalName));
  const selectedProject = useAppSelector(getSelectedProject());

  useEffect(() => {
    if (selectedProject) {
      // dispatch(documentActions.getDocumentsRequest({ params, projectId: selectedProject.id }));
      dispatch(documentActions.getFolderRootIdRequest({ projectId: selectedProject.id }));
      //[30-07-2024] [cong_vt]  #18926 Khi vào Document của Project nhưng vẫn hiển thị Document của Project khác
      dispatch(documentActions.setDocumentPath([]));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lastPath = documentPath[documentPath?.length - 1];
    if (lastPath) {
      dispatch(documentActions.getLabelRequest({ documentId: lastPath?.id, params: defaultPagingParams }));
    } else {
      if (folderRootId) {
        // dispatch(documentActions.getDocumentsRequest({ params, projectId: selectedProject.id }));
        dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: defaultPagingParams }));
      }
    }
    // eslint-disable-next-line
  }, [folderRootId, documentPath, selectedProject]);

  return (
    <>
      <DocumentsHeader />
      <DocumentsToolbar />
      <DocumentsTable />
      {isModalOpen && <CreateUpdateDocumentModal />}
    </>
  );
};
