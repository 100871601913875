import { useEffect, useState } from 'react';

import { Modal, Form, Input, DatePicker, Row, Button, Space, Col, Select } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateTrackingIssueModalName,
  DisciplineOptionType,
  SavingTrackingIssue,
  formatDateDisplay,
  invalidDateStrings,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { AttributeResponse, AttributeValueType, IssueResponse } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getAttributes, getSelectedIssue, getTargets, getTrackerId, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';

dayjs.extend(utc);
export const CreateUpdateTestPackageIssue = (props: { code: string }) => {
  const { t } = useTranslation('issue');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateTrackingIssueModalName));

  const selectedIssue: IssueResponse | null | undefined = useAppSelector(getSelectedIssue());
  const [title, setTitle] = useState(selectedIssue ? t('edit') : t('new'));
  const selectedProject = useAppSelector(getSelectedProject());
  const trackerId = useAppSelector(getTrackerId());
  const isSaving = useAppSelector(getLoading(SavingTrackingIssue));

  const [form] = Form.useForm();

  const attributes = useAppSelector(getAttributes());
  const targets = useAppSelector(getTargets());
  const workPackages = useAppSelector(getWorkPackages());
  const [additionAttributes, setAdditionAttributes] = useState<AttributeResponse[]>([]);

  const workPackageOptions = (workPackages?.results || [])
    .filter(x => x.discipline == DisciplineOptionType[props.code])
    .map(workPackage => ({
      label: workPackage.name,
      value: workPackage.id,
    }));

  useEffect(() => {
    const attrList = attributes?.results || [];
    setAdditionAttributes(attrList);
  }, [attributes]);
  const [initialValues, setInitialValues] = useState<any>(null);

  useEffect(() => {
    setTitle(selectedIssue ? t('edit') : t('new'));
    var initData: any = {
      ...selectedIssue,
    };
    if (selectedIssue) {
      for (let att of selectedIssue?.attributes || []) {
        if (att.valueType === AttributeValueType.Date) {
          initData[att.code] =
            att.dateTimeValue && !invalidDateStrings.includes(att.dateTimeValue) ? dayjs.utc(att.dateTimeValue) : null;
        } else {
          initData[att.code] = att.value;
        }
      }
      for (let target of selectedIssue?.issueTargets || []) {
        initData[target.targetDim.code] = target;
      }
      // for (let taget of selectedIssue?.issueTargets || []) {
      //   initData[taget.targetDim.code] = taget.actualValue;
      // }
      console.log(initData);
    }
    setInitialValues(initData);
  }, [selectedIssue]);

  const handleSaveIssue = (values: any) => {
    if (selectedProject) {
      const inputData = {
        ...values,
        description: values.description || selectedIssue?.description || '',
        projectId: selectedProject.id,
        trackerId,
        areaId: (workPackages?.results || []).find(x => x.id == values.workPackageId)?.areaId,
        ...{
          issueAttributes: additionAttributes.map((attr: AttributeResponse) => {
            if ((targets?.results || []).some(target => target.code === attr.code)) {
              return {
                attributeId: attr.id,
              };
            }
            if (attr.valueType === AttributeValueType.Date) {
              const tzOffset = new Date().getTimezoneOffset();
              return {
                attributeId: attr.id,
                valueType: attr.valueType,
                dateTimeValue: values[attr.code]
                  ? values[attr.code].subtract(tzOffset, 'minutes').toISOString()
                  : undefined,
              };
            } else {
              return {
                attributeId: attr.id,
                valueType: attr.valueType,
                value: values[attr.code],
              };
            }
          }),
        },
        issueTargetUpdates: (targets?.results || []).map(target => ({
          targetId: target.id,
          planValue: values[target.code].planValue,
          actualValue: values[target.code].actualValue,
        })),
      };
      console.log(inputData);
      if (selectedIssue) {
        dispatch(
          issueActions.updateIssueRequest({ issueId: selectedIssue.id, issue: { ...selectedIssue, ...inputData } }),
        );
      } else {
        dispatch(issueActions.createIssueRequest({ issue: inputData }));
      }
    }
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedIssue(undefined));
    dispatch(hideModal({ key: CreateUpdateTrackingIssueModalName }));
  };

  const handleOk = () => form.submit();

  // const haveAdditional = additionAttributes.length > 0;
  const confirmRemoveIssue = () => {
    if (selectedIssue) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('confirmRemove', {
                name: `<strong>"${selectedIssue.subject}"</strong>`,
              }),
            }}
          />
        ),
        closable: true,
        onOk: close => {
          dispatch(
            issueActions.deleteIssueRequest({
              issueId: selectedIssue.id,
              projectId: selectedProject?.id,
              trackerId: trackerId,
            }),
          );
          dispatch(issueActions.setSelectedIssue(undefined));
          close();
        },
      });
    }
  };
  return (
    <DraggableModal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('save')}
      confirmLoading={isSaving}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedIssue ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button
                key="remove"
                type="primary"
                danger
                onClick={() => {
                  confirmRemoveIssue();
                  dispatch(hideModal({ key: CreateUpdateTrackingIssueModalName }));
                }}
              >
                {t('remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      {initialValues && (
        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSaveIssue}>
          <Form.Item label={t('Name')} name="subject" rules={[{ required: true, message: t('Please input name!') }]}>
            <Input />
          </Form.Item>
          <Row gutter={[10, 10]}>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('Line Number')}
                name="LineNo"
                rules={[{ required: true, message: t('requiredField') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label={t('Iso Number')} name="IsoNo" rules={[{ required: true, message: t('requiredField') }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('Selection Set Name')}
            name="SelectionSetName"
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input />
          </Form.Item>
          <Row gutter={[10, 10]}>
            <Col sm={24} md={12}>
              <Form.Item label={t('Welding Complete Date')} name="WeldingCompleteDate">
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label={t('Punch A Clear Date')} name="PunchAClearDate">
                <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('Ready For Test Date')} name="ReadyForTestDate">
            <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
          </Form.Item>
          {!!targets?.results?.length && (
            <Row gutter={[10, 5]}>
              {targets.results.map(target => (
                <>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={target.name || t(target.code + 'Plan')}
                      name={[target.code, 'planValue']}
                      rules={[{ required: true, message: t('requiredField') }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={target.actualDisplayName || t(target.code + 'Actual')}
                      name={[target.code, 'actualValue']}
                    >
                      <Input disabled={!selectedIssue} />
                    </Form.Item>
                  </Col>
                </>
              ))}
            </Row>
          )}
          <Form.Item
            label={t('workPackage')}
            name="workPackageId"
            rules={[{ required: true, message: t('requiredWorkPackage') }]}
          >
            <Select
              options={workPackageOptions}
              defaultValue={selectedIssue && selectedIssue.workPackageId ? selectedIssue.workPackageId : ''}
            />
          </Form.Item>
        </Form>
      )}
    </DraggableModal>
  );
};
