import { createSelector } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { RootState } from '@/store/types';

export const getAreaState = (state: RootState) => state.area;

export function getSelectedArea() {
  return createSelector([getAreaState], state => state.selectedArea);
}

export function getAreas() {
  return createSelector([getAreaState], state => state.areas);
}

export function getAreaQueryParams() {
  return createSelector([getAreaState], state => state.queryParams || defaultPagingParams);
}
