import React, { Key, useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Pagination, Space, Spin, Table, Tooltip } from 'antd';
import type { PaginationProps, TablePaginationConfig } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './StructureBaseSet.module.less';
import { StructureBaseSetTableToolbar } from './StructureBaseSetTableToolbar';
import {
  CreateUpdateStructureBaseSetModalName,
  GettingStructureBaseSetList,
  formatDateDisplay,
  invalidDateStrings,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { StructureBaseSetResponse } from '@/services/StructureBaseSetService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import {
  getSelectedRowKeys,
  getStructureBaseSetQueryParams,
  getStructureBaseSets,
  structureBaseSetActions,
} from '@/store/structureBaseSet';
import Utils from '@/utils';

type StructureBaseSetColumnGroupType<RecordType = unknown> = Omit<ColumnGroupType<RecordType>, 'children'> & {
  children: StructureBaseSetColumnType<RecordType>;
  hidden?: boolean;
};
type StructureBaseSetColumnType<RecordType = unknown> = ColumnType<RecordType> & {
  sortKey?: number;
  hidden?: boolean;
};
type CustomSorterResult<RecordType = unknown> = Omit<SorterResult<RecordType>, 'column'> & {
  column?: StructureBaseSetColumnType<RecordType>;
};
export type ColumnsType<RecordType = unknown> = (
  | StructureBaseSetColumnGroupType<RecordType>
  | StructureBaseSetColumnType<RecordType>
)[];

export const StructureBaseSetTable = () => {
  const { t } = useTranslation('structureBaseSet');
  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const structureBaseSets = useAppSelector(getStructureBaseSets());
  const windowSize = useWindowSize();

  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const selectedRowKeys = useAppSelector(getSelectedRowKeys());
  const queryParams = useAppSelector(getStructureBaseSetQueryParams());

  const isFetching = useAppSelector(getLoading(GettingStructureBaseSetList));

  const setSelectedRowKeys = (keys: any[]) => {
    dispatch(structureBaseSetActions.setSelectedRowKeys(keys));
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [structureBaseSets]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // eslint-disable-next-line
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const moreActions = [
    {
      key: 'edit',
      label: t('edit'),
      icon: <EditOutlined style={{ color: '#1890ff' }} />,
    },
    {
      key: 'remove',
      label: t('remove'),
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
    },
  ];

  const handleMoreActionClick = (key: any, record: any) => {
    console.log(key);
    switch (key) {
      case 'edit':
        editStructureBaseSet(record);
        break;
      default:
        confirmRemoveStructureBaseSet(record);
        break;
    }
  };

  const columns: ColumnsType<StructureBaseSetResponse> = [
    {
      key: 'combination',
      dataIndex: 'combination',
      title: t('combination'),
      width: 300,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'area',
      dataIndex: 'area',
      title: t('area'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'item',
      dataIndex: 'item',
      title: t('item'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'column',
      dataIndex: 'column',
      title: t('column'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'grade',
      dataIndex: 'grade',
      title: t('grade'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'partMark',
      dataIndex: 'partMark',
      title: t('partMark'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'member',
      dataIndex: 'member',
      title: t('member'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'size',
      dataIndex: 'size',
      title: t('size'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'length',
      dataIndex: 'length',
      title: t('length(m)'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'weight',
      dataIndex: 'weight',
      title: t('weight(kg)'),
      width: 120,
      sorter: true,
      sortKey: 0,
    },
    {
      key: 'planStart',
      dataIndex: 'planStart',
      title: t('planStart'),
      width: 120,
      sorter: true,
      sortKey: 0,
      render(value, record, index) {
        return value && !invalidDateStrings.includes(value) ? dayjs(value + 'Z').format(formatDateDisplay) : <></>;
      },
    },
    {
      key: 'planEnd',
      dataIndex: 'planEnd',
      title: t('planEnd'),
      width: 120,
      sorter: true,
      sortKey: 0,
      render(value, record, index) {
        return value && !invalidDateStrings.includes(value) ? dayjs(value + 'Z').format(formatDateDisplay) : <></>;
      },
    },
    {
      key: 'actStart',
      dataIndex: 'actStart',
      title: t('actStart'),
      width: 120,
      sorter: true,
      sortKey: 0,
      render(value, record, index) {
        return value && !invalidDateStrings.includes(value) ? dayjs(value + 'Z').format(formatDateDisplay) : <></>;
      },
    },
    {
      key: 'actEnd',
      dataIndex: 'actEnd',
      title: t('actEnd'),
      width: 120,
      sorter: true,
      sortKey: 0,
      render(value, record, index) {
        return value && !invalidDateStrings.includes(value) ? dayjs(value + 'Z').format(formatDateDisplay) : <></>;
      },
    },
    {
      title: t('action'),
      width: 80,
      key: 'action',
      fixed: 'right',
      sorter: false,
      render: (_, record) => {
        return (
          <Space>
            {moreActions.map(action => (
              <Tooltip placement="left" title={t(action.key)}>
                <Button
                  type="text"
                  icon={action.icon}
                  key={action.key}
                  onClick={() => handleMoreActionClick(action.key, record)}
                />
              </Tooltip>
            ))}
          </Space>
        );
      },
    },
  ];
  // const [checkedColumns, setCheckedColumns] = useState(columns);
  const [selectedColumns, setSelectedColumns] = useState(columns);

  const editStructureBaseSet = (structureBaseSet: any) => {
    dispatch(structureBaseSetActions.setSelectedStructureBaseSet(structureBaseSet));
    setTimeout(() => {
      dispatch(showModal({ key: CreateUpdateStructureBaseSetModalName }));
    }, 100);
  };

  const confirmRemoveStructureBaseSet = (structureBaseSet: any) => {
    Modal.confirm({
      title: t('remove'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${structureBaseSet.name}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveStructureBaseSet(structureBaseSet.id);
        close();
      },
    });
  };

  const handleRemoveStructureBaseSet = (structureBaseSetId: number) => {
    dispatch(
      structureBaseSetActions.deleteStructureBaseSetRequest({
        structureBaseSetId,
        projectId: selectedProject?.id,
      }),
    );
  };

  const onPagingChange = (page: number, pageSize: number) => {
    dispatch(
      structureBaseSetActions.getStructureBaseSetsRequest({
        projectId: selectedProject?.id,
        queryParams: { ...queryParams, page, pageSize },
      }),
    );
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) => `${range[0]}-${range[1]} of ${total} items`;

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (boolean | Key)[] | null>,
    sorter: CustomSorterResult<StructureBaseSetResponse> | CustomSorterResult<StructureBaseSetResponse>[],
    extra: TableCurrentDataSource<StructureBaseSetResponse>,
  ) => {
    let sortParams;
    if (Array.isArray(sorter)) {
      if (sorter.length && sorter[0]?.columnKey) {
        sortParams = {
          'SortBy.Key': sorter[0]?.column?.sortKey,
          'SortBy.Value': Utils.toCapitalize(sorter[0]?.columnKey.toString()),
          ascending: sorter[0]?.order === 'ascend',
        };
      }
    } else {
      if (sorter.columnKey && sorter?.order) {
        sortParams = {
          'SortBy.Key': sorter?.column?.sortKey,
          'SortBy.Value': Utils.toCapitalize(sorter?.columnKey.toString()),
          ascending: sorter?.order === 'ascend',
        };
      } else {
        sortParams = {
          'SortBy.Key': undefined,
          'SortBy.Value': undefined,
          ascending: undefined,
        };
      }
    }
    const newParams = {
      ...sortParams,
      ...Utils.convertAntFilterToParams(filters),
      page: 1,
    };

    dispatch(
      structureBaseSetActions.getStructureBaseSetsRequest({
        projectId: selectedProject?.id,
        queryParams: { ...queryParams, ...newParams },
      }),
    );
  };
  return (
    <Spin spinning={isFetching}>
      <StructureBaseSetTableToolbar
        tableColumns={columns || []}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
      <div style={{ margin: '0 5px' }}>
        <Table
          rowKey={record => record.id}
          // virtual
          dataSource={structureBaseSets?.results || []}
          rowSelection={rowSelection}
          expandable={{
            defaultExpandAllRows: true,
          }}
          columns={selectedColumns.filter(col => !col.hidden)}
          size="small"
          scroll={{ x: 'max-content', y: windowSize[1] - 260 }}
          pagination={false}
          sticky={{ offsetHeader: 39 }}
          onChange={onTableChange}
          tableLayout="auto"
          className={styles.structureBaseSetTable}
        />
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Pagination
            total={structureBaseSets?.queryCount || 0}
            current={structureBaseSets?.page || 1}
            pageSize={structureBaseSets?.pageSize || 20}
            onChange={onPagingChange}
            responsive
            showTotal={showTotal}
          />
        </div>
      </div>
    </Spin>
  );
};
