import { useEffect } from 'react';

import { Modal, Form, Row, Col, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateDocumentModalName, DisciplineOption, SavingDocument, largePagingParams } from '@/common/define';
import { documentActions, getSelectedDocument } from '@/store/document';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const CreateUpdateDocumentModal = () => {
  const { t } = useTranslation('document');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateDocumentModalName));
  const selectedDocument = useAppSelector(getSelectedDocument());
  const selectedProject = useAppSelector(getSelectedProject());
  const isSaving = useAppSelector(getLoading(SavingDocument));
  const [form] = Form.useForm();

  const disciplineOptions = [
    {
      label: t('Piping'),
      value: DisciplineOption.Piping,
    },
    {
      label: t('Equipment'),
      value: DisciplineOption.Equipment,
    },
    {
      label: t('E&I'),
      value: DisciplineOption.E_AND_I,
    },
    {
      label: t('Structure'),
      value: DisciplineOption.Structure,
    },
    {
      label: t('Civil'),
      value: DisciplineOption.Civil,
    },
  ];

  useEffect(() => {
    dispatch(
      documentActions.getAreasRequest({
        params: {
          ...largePagingParams,
        },
        projectId: selectedProject?.id,
      }),
    );
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    dispatch(documentActions.setSelectedDocument(undefined));
    dispatch(hideModal({ key: CreateUpdateDocumentModalName }));
  };

  const handleOk = () => form.submit();

  const handleSaveDocument = (values: any) => {
    if (selectedProject) {
      const inputData = {
        projectId: selectedProject.id,
        ...values,
      };
      if (selectedDocument) {
        dispatch(
          documentActions.updateDocumentRequest({
            document: { ...selectedDocument, ...inputData },
            projectId: selectedProject.id,
          }),
        );
        return;
      }
      const formData = new FormData();

      for (var key in inputData) {
        formData.append(key, inputData[key]);
      }
      dispatch(documentActions.createDocumentRequest({ document: formData, projectId: selectedProject.id }));
    }
  };

  return (
    <Modal
      title={selectedDocument ? t('updateDocument') : t('addNewDocument')}
      open={isModalOpen}
      okText={t('ok')}
      onOk={handleOk}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      confirmLoading={isSaving}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedDocument,
        }}
        onFinish={handleSaveDocument}
      >
        <Row>
          <Col span={24} md={24}>
            <Form.Item label={t('name')} name="name" rules={[{ required: true, message: t('requireName') }]}>
              <Input />
            </Form.Item>
            <Form.Item label={t('code')} name="code" rules={[{ required: true, message: t('requireCode') }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t('discipline')}
              name="discipline"
              rules={[{ required: true, message: t('requireDiscipline') }]}
            >
              <Select options={disciplineOptions} />
            </Form.Item>
            <Form.Item label={t('description')} name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
