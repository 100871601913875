import { useEffect, useState } from 'react';

import { SearchOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons';
import { Row, Space, Input, Button, Upload, UploadProps, UploadFile, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { IssueService } from '@/services/IssueService';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker } from '@/store/issue';
import { getSSNameDocumentQueryParams, linkedDocumentActions } from '@/store/linkedDocument';
import { getSelectedProject } from '@/store/project';

export const LinkedDocumentsToolbar = () => {
  const { t } = useTranslation('BasedData');
  const [searchStr, setSearchStr] = useState();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [timer, setTimer] = useState<any>(null);
  const dispatch = useAppDispatch();
  const params = useAppSelector(getSSNameDocumentQueryParams());
  const selectedProject = useAppSelector(getSelectedProject());
  const areas = useAppSelector(getAreas());
  const trackers = useAppSelector(getTracker());

  useEffect(() => {
    setSearchStr(params?.search);
  }, [params]);

  const onSearchChange = (evt: any) => {
    if (!selectedProject) return;
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        linkedDocumentActions.getSSNameDocumentsRequest({
          params: { ...params, page: 1, search },
          projectId: selectedProject?.id,
          areaId: params?.areaId || -1,
        }),
      );
    }, 500);
    setTimer(timeoutId);
  };

  const onFilterChange = (newParams: any) => {
    if (!selectedProject) return;
    dispatch(
      linkedDocumentActions.getSSNameDocumentsRequest({
        params: { ...params, ...newParams, page: 1 },
        projectId: selectedProject?.id,
        areaId: newParams?.areaId || -1,
      }),
    );
  };

  const onExportFileHandle = () => {
    if (selectedProject) {
      IssueService.Get.exportSSLinkedDocuments(selectedProject?.id, {
        search: {},
      }).subscribe((issueFile: any) => {
        if (issueFile != null) {
          const url = window.URL.createObjectURL(new Blob([issueFile]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'linkedDocument.xlsx');
          document.body.appendChild(link);
          link.click();
        }
      });
    }
  };
  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      dispatch(
        linkedDocumentActions.importLinkedDocument({
          projectId: selectedProject?.id,
          file,
          areaId: params?.areaId || -1,
        }),
      );
      return false;
    },
    fileList,
  };

  return (
    <Row style={{ padding: 10 }} gutter={[10, 10]}>
      <Space style={{ flex: 1 }} wrap>
        <Input
          value={searchStr}
          onChange={onSearchChange}
          allowClear
          placeholder={t('Search')}
          suffix={searchStr ? null : <SearchOutlined />}
          style={{ width: 300 }}
        />
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8,}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Area</div>
          <Select
            placeholder="Area"
            options={[{ label: 'All', value: -1 }].concat(
              (areas?.results || []).map(area => ({
                label: area.name,
                value: area.id,
              })),
            )}
            value={params.areaId}
            onChange={value =>
              onFilterChange({
                ...params,
                areaId: value,
              })
            }
            style={{ minWidth: 100 }}
          />
          <div style={{display: 'flex', marginLeft: 5, alignItems: 'center', justifyContent: 'center'}}>Discipline</div>
          <Select
            placeholder="Discipline"
            options={[{ label: 'All', value: -1 }].concat(
              (trackers?.results || []).filter(x => x.code != 'PipingAreaWorkload').map(tracker => ({
                label: tracker.name,
                value: tracker.id,
              })),
            )}
            onChange={value =>
              onFilterChange({
                ...params,
                trackerId: value,
              })
            }
            value={params?.trackerId}
            style={{ minWidth: 150 }}
          />
        </div>
      </Space>
      <Space>
        <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
          <Button icon={<ImportOutlined />}> {t('Import')} </Button>
        </Upload>
        <Button
          icon={<ExportOutlined />}
          onClick={() => {
            onExportFileHandle();
          }}
        >
          {t('Export')}
        </Button>
      </Space>
    </Row>
  );
};
