import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { defaultPagingParams } from '@/common/define';

const getState = (state: RootState) => state.structureBaseSet;

export function getStructureBaseSets() {
  return createSelector([getState], state => state.structureBaseSets);
}

export function getStructureBaseSetQueryParams() {
  return createSelector([getState], state => state.queryParams || defaultPagingParams);
}

export function getSelectedStructureBaseSet() {
  return createSelector([getState], state => state.selectedStructureBaseSet);
}
export function getSelectedRowKeys() {
  return createSelector([getState], state => state.selectedRowKeys);
}
export function getColumns() {
  return createSelector([getState], state => state.columns);
}
export function getSelectedColumns() {
  return createSelector([getState], state => state.selectedColumns);
}
export function getColumnAggregateValue() {
  return createSelector([getState], state => state.columnAggregateValue);
}
