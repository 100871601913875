import { useEffect, useState } from 'react';

import { SearchOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons';
import { Row, Space, Input, Form, Button, Col, Divider, Popover, Radio, Upload, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ExportingAreas, ImportingAreas } from '@/common/define';
import { AreaService } from '@/services/AreaService';
import { areaActions, getAreaQueryParams } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

enum ImportType {
  NEW = 0,
  EDIT = 1,
}

export const AreaListToolbar = () => {
  const { t } = useTranslation('BasedData');
  const [exportForm] = Form.useForm();
  const dispatch = useAppDispatch();

  const [searchStr, setSearchStr] = useState();
  const [timer, setTimer] = useState<any>(null);
  const [importType, setImportType] = useState(ImportType.NEW);
  const [openImportFileType, setOpenImportFileType] = useState(false);
  const [openExportFile, setOpenExportFile] = useState(false);

  const params = useAppSelector(getAreaQueryParams());
  const selectedProject = useAppSelector(getSelectedProject());
  const isImporting = useAppSelector(getLoading(ImportingAreas));
  const isExporting = useAppSelector(getLoading(ExportingAreas));

  const importTypeList = [
    { key: 'new', label: t('New'), value: ImportType.NEW },
    { key: 'edit', label: t('Edit'), value: ImportType.EDIT },
  ];

  useEffect(() => {
    if (params.search) {
      setSearchStr(params.search);
    }
  }, [params]);

  const onSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(areaActions.getAreasRequest({ params: { ...params, page: 1, search }, projectId: selectedProject?.id }));
    }, 500);
    setTimer(timeoutId);
  };

  const props: UploadProps = {
    beforeUpload: file => {
      setOpenImportFileType(false);
      if (selectedProject) {
        const formData = new FormData();
        formData.append('file', file);
        if (importType === ImportType.NEW) {
          dispatch(areaActions.importAreasRequest({ projectId: selectedProject?.id, body: formData }));
          return false;
        } else if (importType === ImportType.EDIT) {
          dispatch(areaActions.importEditAreasRequest({ projectId: selectedProject?.id, body: formData }));
          return false;
        }
      }
    },
    maxCount: 1,
  };

  const onChangeImportType = (type: any) => {
    setImportType(type.target.value === 'new' ? 0 : 1);
  };

  const importFileRender = () => {
    return (
      <div style={{ width: 200 }}>
        <Row>
          {importTypeList.map(item => (
            <Col span={60} key={item.key}>
              <Radio value={item.key} checked={importType === item.value} onClick={onChangeImportType}>
                {item.label}
              </Radio>
            </Col>
          ))}
        </Row>
        <Divider style={{ margin: '10px 0' }} />
        <Row>
          <Space style={{ flex: 1 }}>
            <Button onClick={() => setOpenImportFileType(false)} type="text">
              Cancel
            </Button>
          </Space>
          <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
            <Button type="primary"> {t('Import')} </Button>
          </Upload>
        </Row>
      </div>
    );
  };

  const exportFileRender = () => {
    return (
      <div style={{ width: 250 }}>
        <Form
          form={exportForm}
          layout="vertical"
          onFinish={onExportFileHandle}
          initialValues={{
            fileName: `Areas`,
          }}
        >
          <Row>
            <Form.Item name={'fileName'} style={{ width: '100%' }}>
              <Input addonAfter={'.xlsx'} />
            </Form.Item>
          </Row>
          <Divider style={{ margin: '10px 0' }} />
          <Row>
            <Space style={{ flex: 1 }}>
              <Button onClick={() => setOpenExportFile(false)} type="text">
                Cancel
              </Button>
            </Space>
            <Button type="primary" onClick={exportForm.submit}>
              {t('Export')}
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  const onExportFileHandle = (values: any) => {
    const { fileName } = values;

    setOpenExportFile(false);
    if (selectedProject) {
      const downloadedFileName = fileName ? `${fileName}.xlsx` : 'Areas.xlsx';
      dispatch(startLoading({ key: ExportingAreas }));

      AreaService.Get.exportAreas(selectedProject?.id).subscribe(
        (issueFile: any) => {
          if (issueFile != null) {
            const url = window.URL.createObjectURL(new Blob([issueFile]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadedFileName);
            document.body.appendChild(link);
            link.click();
            Utils.successNotification(t('Export successfully'));
          }
        },
        err => {
          Utils.errorHandling(err);
          dispatch(stopLoading({ key: ExportingAreas }));
        },
        () => {
          dispatch(stopLoading({ key: ExportingAreas }));
        },
      );
    }
  };

  return (
    <Row style={{ padding: 10 }} gutter={[10, 10]}>
      <Space style={{ flex: 1 }}>
        <Input
          value={searchStr}
          onChange={onSearchChange}
          allowClear
          placeholder={t('Search')}
          suffix={searchStr ? null : <SearchOutlined />}
          style={{ width: 260 }}
        />
      </Space>
      <Space wrap>
        <Popover
          content={importFileRender()}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          open={openImportFileType}
          onOpenChange={setOpenImportFileType}
        >
          <Button loading={isImporting} icon={<ImportOutlined />}>
            {t('Import')}
          </Button>
        </Popover>
        <Popover
          content={exportFileRender()}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          open={openExportFile}
          onOpenChange={setOpenExportFile}
        >
          <Button loading={isExporting} icon={<ExportOutlined />}>
            {t('Export')}
          </Button>
        </Popover>
        {/* <Button icon={<ExportOutlined />} onClick={onExportFileHandle}>{t('Export')}</Button> */}
      </Space>
    </Row>
  );
};
