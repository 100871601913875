import React from 'react';

import { PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateTrackingIssueModalName, RemovingTrackingIssuesByProject } from '@/common/define';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTrackerId, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const IssueHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const isTrackerLoading = useAppSelector(getLoading('getTrackerRequest'));
  const selectedProject = useAppSelector(getSelectedProject());
  const trackerId = useAppSelector(getTrackerId());
  const isIssueRemoving = useAppSelector(getLoading(RemovingTrackingIssuesByProject));

  const createIssue = () => {
    dispatch(issueActions.setSelectedIssue(null));
    dispatch(showModal({ key: CreateUpdateTrackingIssueModalName }));
  };

  const handleResetTasks = () => {
    if (selectedProject && trackerId) {
      dispatch(
        issueActions.deleteIssuesByProjectRequest({
          projectId: selectedProject.id,
          areaId: -1,
          workPackageId: -1,
          trackerId,
        }),
      );
    }
  };

  return (
    <Row style={{ backgroundColor: 'white', padding: 10 }} gutter={[10, 10]}>
      <Spin fullscreen spinning={isIssueRemoving} />
      <Typography.Title style={{ flex: 1, margin: 0, wordBreak: 'keep-all' }} level={4}>
        {activeMenu?.label}
      </Typography.Title>
      <Space wrap>
        <Button type="primary" icon={<PlusOutlined />} onClick={createIssue}>
          {t('New issue')}
        </Button>
        {isTrackerLoading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
            spinning={isTrackerLoading}
            style={{ marginLeft: 10 }}
          />
        ) : trackerId ? (
          <Popconfirm title={t('Are you sure to reset all tasks?')} onConfirm={handleResetTasks}>
            <Button danger icon={<DeleteOutlined style={{ color: 'red' }} />}>
              {t('Reset tasks')}
            </Button>
          </Popconfirm>
        ) : (
          <></>
        )}
      </Space>
    </Row>
  );
};
