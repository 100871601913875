import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, InputNumber, Row, Select, Spin, Typography } from 'antd';

import styles from './PlanTotalVolume.module.css';
import { DisciplineOption } from '@/common/define';
import { ProjectTargetResponse } from '@/services/ProjectTargetService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getProjectTargets, getSelectedProject, projectActions } from '@/store/project';
const formFields = [
  // {
  //   discipline: {
  //     title: 'Piping',
  //     code: 'PipingTask',
  //     value: DisciplineOption.Piping,
  //   },
  // unitOptions: [
  //   {
  //     label: 'D/I',
  //     value: 'D/I',
  //   },
  // ],
  // },
  {
    discipline: {
      title: 'Equipment',
      code: 'EquipmentTask',
      value: DisciplineOption.Equipment,
    },
    unitOptions: [
      {
        label: 'Set',
        value: 'Set',
      },
    ],
  },
  {
    discipline: {
      title: 'Structure',
      code: 'StructureTask',
      value: DisciplineOption.Structure,
    },
    unitOptions: [
      {
        label: 'Ton',
        value: 'Ton',
      },
    ],
  },
  {
    discipline: {
      title: 'E&I',
      code: 'E&ITask',
      value: DisciplineOption.E_AND_I,
    },
    unitOptions: [
      {
        label: 'Set',
        value: 'Set',
      },
    ],
  },
  {
    discipline: {
      title: 'Civil',
      code: 'CivilTask',
      value: DisciplineOption.Civil,
    },
    unitOptions: [
      {
        label: (
          <span>
            m<sup>3</sup>
          </span>
        ),
        value: 'm3',
      },
    ],
  },
] as {
  discipline: {
    title: string;
    code: string;
    value: DisciplineOption;
  };
  unitOptions: {
    label: React.ReactNode;
    value: any;
  }[];
}[];
export const CreateUpdatePlanTotalVolume = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoading('GetProjectTargets'));
  const isSavingProjectTargets = useAppSelector(getLoading('SaveProjectTargets'));
  const projectTargets = useAppSelector(getProjectTargets());
  const selectedProject = useAppSelector(getSelectedProject());
  const tracker = useAppSelector(getTracker());
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const pipingValue = Form.useWatch('PipingTask', form);
  useEffect(() => {
    if (projectTargets?.results?.length && tracker?.results?.length) {
      const trackers = tracker.results || [];
      const targets = projectTargets.results || [];
      const pipingTracker = trackers.find(tracker => tracker.code === 'PipingTask');
      const pipingTargets = targets.filter(target => target.trackerId === pipingTracker?.id);
      const pipingShopDI = targets.find(target => target.targetCode === 'PipingPlanShopVolume');
      const pipingFieldDI = pipingTargets.find(target => target.targetCode === 'PipingPlanFieldVolume');
      let data: any = {};
      if (pipingTracker) {
        data = {
          ...data,
          [pipingTracker.code]: {
            shopDI: parseInt(pipingShopDI?.planValue || '0'),
            fieldDI: parseInt(pipingFieldDI?.planValue || '0'),
            plannedTotalVolume: parseInt(pipingShopDI?.planValue || '0') + parseInt(pipingFieldDI?.planValue || '0'),
            unitVolume: pipingShopDI?.unitVolume || pipingFieldDI?.unitVolume,
          },
        };
      }
      targets.forEach(pTarget => {
        const foundTracker = trackers.find(tracker => tracker.id === pTarget.trackerId);
        if (foundTracker) {
          if (foundTracker.code === 'PipingTask') {
          } else {
            data[foundTracker.code] = {
              ...data[foundTracker.code],
              planValue: parseInt(pTarget.planValue),
              unitVolume: pTarget.unitVolume,
            };
          }
        }
      });
      setFormData(data);
    }
  }, [projectTargets, tracker]);

  useEffect(() => {
    if (pipingValue) {
      form.setFieldValue('PipingTask', {
        ...pipingValue,
        plannedTotalVolume: (pipingValue.shopDI || 0) + (pipingValue.fieldDI || 0),
      });
    }
  }, [pipingValue]);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData]);

  const handleSubmit = (values: any) => {
    if (projectTargets?.results?.length && tracker?.results?.length && selectedProject) {
      const trackers = tracker.results || [];
      const targets = projectTargets.results || [];
      let newTargets: ProjectTargetResponse[] = [];
      targets.forEach(pTarget => {
        const foundTracker = trackers.find(tracker => tracker.id === pTarget.trackerId);
        if (foundTracker) {
          if (foundTracker.code === 'PipingTask') {
            if (pTarget.targetCode === 'PipingPlanShopVolume') {
              newTargets.push({
                ...pTarget,
                planValue: (values[foundTracker.code].shopDI || 0).toString(),
              });
            } else if (pTarget.targetCode === 'PipingPlanFieldVolume') {
              newTargets.push({
                ...pTarget,
                planValue: (values[foundTracker.code].fieldDI || 0).toString(),
              });
            }
          } else {
            newTargets.push({
              ...pTarget,
              planValue: (values[foundTracker.code].planValue || 0).toString(),
            });
          }
        }
      });
      console.log(newTargets);
      dispatch(
        projectActions.saveProjectTargetsRequest({
          projectTargets: newTargets,
          projectId: selectedProject.id,
          disciplines: ['PipingTask', 'EquipmentTask', 'E&ITask', 'StructureTask', 'CivilTask'],
        }),
      );
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        style={{ padding: 20, maxWidth: 750, marginInline: 'auto' }}
      >
        <div className={styles.formWrapper}>
          <div className={styles.gridContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography.Text strong>Discipline</Typography.Text>
              <Typography.Text strong style={{ color: '#a7a7a7' }}>
                {'Piping'}
              </Typography.Text>
            </div>
            <Form.Item label={'Volume Unit'} name={['PipingTask', 'unitVolume']}>
              <Select
                options={[
                  {
                    label: 'D/I',
                    value: 'D/I',
                  },
                ]}
                disabled={true}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label={'Planned total volume'} name={['PipingTask', 'plannedTotalVolume']}>
              <InputNumber
                readOnly={true}
                controls={false}
                style={{ width: '100%', minWidth: 150, border: 'unset', outline: 'none', boxShadow: 'unset' }}
                min={0}
              />
            </Form.Item>

            <Form.Item label={'Shop DI'} name={['PipingTask', 'shopDI']}>
              <InputNumber controls={false} min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label={'Field DI'} name={['PipingTask', 'fieldDI']}>
              <InputNumber controls={false} min={0} style={{ width: '100%' }} />
            </Form.Item>
            {formFields.map((field, index) => (
              <React.Fragment key={index}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                  <Typography.Text strong style={{ color: '#a7a7a7' }}>
                    {field.discipline.title}
                  </Typography.Text>
                </div>
                <Form.Item name={[field.discipline.code, 'unitVolume']}>
                  <Select options={field.unitOptions} disabled={true} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name={[field.discipline.code, 'planValue']}>
                  <InputNumber style={{ width: '100%', minWidth: 150 }} controls={false} min={0} />
                </Form.Item>
                <div></div>
                <div></div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={form.submit} type="primary" loading={isSavingProjectTargets}>
            Save
          </Button>
        </div>
      </Form>
    </Spin>
  );
};
