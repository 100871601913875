import React, { useEffect } from 'react';

import { LockOutlined, MailOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Grid, Input, Row, Space, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import styles from './LoginPage.module.less';
import { LOGO, LoginInput } from '@/common/define';
import { useAuth, useReduxStore } from '@/hooks';
import background from '@/image/BackgroundNVH.svg';
import { persistConfigStorage } from '@/store';
import { appActions, getCaptcha } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { changeStoreConfig } = useReduxStore();
  const { t } = useTranslation(['login']);
  const dispatch = useAppDispatch();
  const captcha = useAppSelector(getCaptcha());
  const screens = Grid.useBreakpoint();
  const from = location.state?.from?.pathname || '/';

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t('Require username')),
    password: Yup.string().required(t('Require password')),
    captcha: captcha ? Yup.string().required(t('Require captcha')) : Yup.string().optional(),
  });

  useEffect(() => {
    dispatch(appActions.setCaptcha(undefined));
    // sau khi logout, và dữ liệu trong store được xóa hết thì thiết lập lại redux
    setTimeout(() => {
      changeStoreConfig(persistConfigStorage);
    }, 200)
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true,
    },
    validationSchema,
    onSubmit: (values: LoginInput) => {
      handleLogin(values);
    },
  });

  const handleLogin = (values: LoginInput) => {
    if (values) {
      auth.signin({ ...values, captchaId: captcha?.CaptchaId }, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      });
    }
  };

  const handleRefreshCaptcha = () => {
    const { username, password, remember } = formik.values;
    const loginData: LoginInput = { username, password, remember };
    dispatch(appActions.loginRequest({ input: loginData }));
  };

  const onChangeRememberMe = (evt: CheckboxChangeEvent) => {
    evt.target.checked ? changeStoreConfig(persistConfigStorage) : changeStoreConfig({});
    formik.handleChange(evt);
  };

  const formWidth = () => {
    if (screens.xxl) {
      return '60%';
    }
    if (screens.xl) {
      return '70%';
    }
    if (screens.lg) {
      return '80%';
    }
    if (screens.md) {
      return '50%';
    }
    if (screens.sm) {
      return '60%';
    }
    if (screens.xs) {
      return '80%';
    }
    return '90%';
  };

  const backgroundWindowSize: React.CSSProperties = {
    backgroundImage: !screens.lg && (screens.sm || screens.xs || screens.md) ? `url(${background})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundBlendMode: 'darken',
  };

  return (
    <Row style={{ margin: 0, width: '100vw', height: '100dvh', ...backgroundWindowSize }}>
      <Col xs={0} sm={0} md={0} lg={14} className={styles.leftColumn}>
        <div style={{ margin: 20 }}>
          <img src={LOGO} alt="logo" width={100} />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} style={{ padding: 20 }}>
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <Form
            layout="vertical"
            name={'login'}
            initialValues={formik.initialValues}
            onFinish={formik.handleSubmit}
            requiredMark={false}
            style={{ minWidth: formWidth() }}
          >
            <Typography.Title level={2} style={{ marginTop: 0, marginBottom: 40 }}>
              {t('Sign in')}
            </Typography.Title>
            <Form.Item
              label={t('Username')}
              required
              help={formik.touched.username && formik.errors.username}
              validateStatus={formik.touched.username && formik.errors.username ? 'error' : ''}
              className={styles.formItem}
            >
              <Input
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="large"
                placeholder={t('Enter your username')}
                className={styles.customInput}
                prefix={<MailOutlined />}
              />
            </Form.Item>
            <Form.Item
              label={t('Password')}
              required
              help={formik.touched.password && formik.errors.password}
              validateStatus={formik.touched.password && formik.errors.password ? 'error' : ''}
              className={styles.formItem}
            >
              <Input.Password
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="large"
                placeholder={t('Enter your password')}
                className={styles.customInput}
                prefix={<LockOutlined />}
              />
            </Form.Item>
            {captcha?.Captcha && (
              <Form.Item
                className={styles.formItem}
                required
                help={formik.touched.captcha && formik.errors.captcha}
                validateStatus={formik.touched.captcha && formik.errors.captcha ? 'error' : ''}
              >
                <Space.Compact style={{ width: '100%' }}>
                  <Form.Item noStyle>
                    <Input
                      name="captcha"
                      value={formik.values.captcha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      size="large"
                      className={styles.customInput}
                      placeholder={t('Require captcha')}
                      style={{ width: 'calc(100% - 165px)' }}
                    />
                  </Form.Item>
                  <img
                    src={`data:image/png;base64,${captcha.Captcha}`}
                    alt="captcha"
                    style={{ width: 112, margin: '0px 5px 0px 5px', border: '1px solid #d9d9d9', objectFit: 'contain' }}
                  />
                  <Tooltip title="Lấy mã xác thực mới">
                    <Button
                      style={{ width: 42 }}
                      size="large"
                      type="text"
                      icon={<SyncOutlined style={{ fontSize: 24 }} />}
                      onClick={handleRefreshCaptcha}
                    />
                  </Tooltip>
                </Space.Compact>
              </Form.Item>
            )}
            <div className={styles.saveAndForgetContainer}>
              {/* <Form.Item>
                <Checkbox name="remember" checked={formik.values.remember} onChange={onChangeRememberMe}>
                  <Typography.Text className={styles.textStyle}>{t('Remember')}</Typography.Text>
                </Checkbox>
              </Form.Item> */}
              {/* <Typography.Text
                className={styles.Link}
                onClick={() => {
                  notification.warning({
                    message: t('Forget password Notify'),
                  });
                }}
              >
                {t('Forget password')}
              </Typography.Text> */}
            </div>
            <Form.Item>
              <Button type="primary" htmlType="submit" shape="round" className={styles.loginButton}>
                <Typography.Text className={styles.loginButtonText}>{t('Login')}</Typography.Text>
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};
